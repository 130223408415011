import {PgnShape} from './pgn-shape';


export class PgnPolygonShape extends PgnShape {
    polygon: number[];
    tension;


    public applyDefaults() {
        if(this.tension !== 0 && !this.tension) {
            this.tension = 0.3;
        }
    }

    public initGraphics(_document: Document) {
        this.initFillPolygon();
        this.initStrokePolygon();
    }



    public opaqueFillCvs(): HTMLCanvasElement {
        return this.rawOpaqueFillCvs;
    }

    public opaqueStrokeCvs(): HTMLCanvasElement {
        return this.rawOpaqueStrokeCvs;
    }

    // used to free memory when edit mode is not active (iframes in clients' websites)
    public clearEditModeGraphicsCache() {
        super.clearEditModeGraphicsCache();
        this.rawOpaqueFillCvs = this.rawOpaqueStrokeCvs = null;
        this.rawOpaqueFillCtx = this.rawOpaqueStrokeCtx = null;
    }

    public setEditMode(_isEditMode: boolean) {
        // todo
    }

    public clone(): PgnPolygonShape {
        const clone = new PgnPolygonShape();
        super.cloneInto(clone);
        clone.tension = this.tension;
        if(this.polygon != null) {
            clone.polygon = new Array<number>();
            for(const pt of this.polygon) {
                clone.polygon.push(pt);
            }
        }
        return clone;
    }

    initFillPolygon() {
        this.rawOpaqueFillCvs = this.layerRenderer.createCanvas();
        this.rawOpaqueFillCtx = this.rawOpaqueFillCvs.getContext('2d');
        this.rawOpaqueFillCtx.fillStyle = this.fill;
        this.smoothPoints(this.rawOpaqueFillCtx, this.tension, true, this.polygon);
        this.rawOpaqueFillCtx.fill();
    }

    initStrokePolygon() {
        if(this.layerRenderer.actualStrokeWidth() == 0) {
            this.rawOpaqueStrokeCvs = null;
            this.rawOpaqueStrokeCtx = null;
            return;
        }
        this.rawOpaqueStrokeCvs = this.layerRenderer.createCanvas();
        this.rawOpaqueStrokeCtx = this.rawOpaqueStrokeCvs.getContext('2d');
        this.rawOpaqueStrokeCtx.strokeStyle = this.layerRenderer.actualStrokeColor();
        this.rawOpaqueStrokeCtx.lineWidth = this.layerRenderer.actualStrokeWidth();
        this.smoothPoints(this.rawOpaqueStrokeCtx, this.tension, true, this.polygon);
        this.rawOpaqueStrokeCtx.stroke();
    }

    protected smoothPoints(ctx: CanvasRenderingContext2D, tension: number, closedPath: boolean, pts: number[]) {
        var len = pts.length / 2; // number of points
        if (len < 2) return;
        ctx.beginPath();
        if (len == 2) {
            ctx.moveTo(pts[0], pts[1]);
            ctx.lineTo(pts[2], pts[3]);
            return;
        }
        if(closedPath) {
            this.smoothPolygon(ctx, tension, pts);
        } else {
            this.smoothPath(ctx, tension, pts);
        }

    }

    private smoothPolygon(ctx: CanvasRenderingContext2D, tension: number, pts: number[]) {
        const lastIndex = pts.length - 2;
        ctx.moveTo(pts[0], pts[1]);
        let ctrl0 = this.ctrlPts(pts[lastIndex], pts[lastIndex + 1], pts[0], pts[1], pts[2], pts[3], tension);
        let ctrl = ctrl0;
        let p3Index;
        for(let i = 0; i <= lastIndex - 2; i += 2) {
            p3Index = (i + 4) == pts.length ? 0 : (i + 4);
            ctrl = this.ctrlPts(pts[i], pts[i + 1], pts[i + 2], pts[i + 3], pts[p3Index], pts[p3Index + 1], tension);
            ctx.bezierCurveTo(ctrl0[2], ctrl0[3], ctrl[0], ctrl[1], pts[i + 2], pts[i + 3]);
            ctrl0 = ctrl;
        }

        ctrl = this.ctrlPts(pts[lastIndex], pts[lastIndex + 1], pts[0], pts[1], pts[2], pts[3], tension);
        ctx.bezierCurveTo(ctrl0[2], ctrl0[3], ctrl[0], ctrl[1], pts[0], pts[1]);
        ctx.closePath();
    }

    private smoothPath(ctx: CanvasRenderingContext2D, tension: number, pts: number[]) {
        const lastIndex = pts.length - 2;
        ctx.moveTo(pts[0], pts[1]);

        let ctrl0 = this.ctrlPts(pts[0], pts[1], pts[2], pts[3], pts[4], pts[5], tension);
        ctx.quadraticCurveTo(ctrl0[0], ctrl0[1], pts[2], pts[3]);
        let ctrl = ctrl0;
        for(let i = 2; i <= lastIndex - 4; i +=2) {
            ctrl = this.ctrlPts(pts[i], pts[i + 1], pts[i + 2], pts[i + 3], pts[i + 4], pts[i + 5], tension);
            ctx.bezierCurveTo(ctrl0[2], ctrl0[3], ctrl[0], ctrl[1], pts[i + 2], pts[i + 3]);
            ctrl0 = ctrl;
        }
        ctx.quadraticCurveTo(ctrl0[2], ctrl0[3], pts[lastIndex], pts[lastIndex + 1]);
    }



    private ctrlPts(x1: number,
                    y1: number,
                    x2: number,
                    y2: number,
                    x3: number,
                    y3: number,
                    t: number): number[] {
        var vx = x3 - x1;
        var vy = y3 - y1;
        var d01 = this.distance(x1, y1, x2, y2);
        var d12 = this.distance(x2, y2, x3, y3);
        var dSum = d01 + d12;
        return [x2 - vx * t * d01 / dSum, y2 - vy * t * d01 / dSum,
            x2 + vx * t * d12 / dSum, y2 + vy * t * d12 / dSum ];
    }

    private distance(x1: number, y1: number, x2: number, y2: number) {
        const dx = x2 - x1;
        const dy = y2 - y1;
        return Math.sqrt(dx * dx + dy * dy);
    }
}
