import {animate, keyframes, style, transition, trigger} from '@angular/animations';

export const EDT_PNL_OUT_TS = 0.3;
const EDT_PNL_IN_TS = 0.3;

export const CVS_ROT_IN_TS = 0.3;
export const CVS_ROT_OUT_TS = 0.4;

const TOT_EDT_OUT_TS = CVS_ROT_OUT_TS + EDT_PNL_OUT_TS;

export const bottomToolbarToggle = trigger('btmTlbrTgl', [
    transition(':enter', [
        style({marginTop: '-10px', transform: 'translateY(-100%)'}),
        animate(EDT_PNL_IN_TS + 's ' + (CVS_ROT_IN_TS + CVS_ROT_OUT_TS) + 's ease-in',
            keyframes([
                style({marginTop: '20px', transform: 'translateY(0)'})
            ])
        )
    ]),
    transition(':leave', [
        style({marginTop: '20px', transform: 'translateY(0)'}),
        // edit panels should stick around until the whole transition is over to prevent angular bug from abruptly stopping parent rotation animations
        // add a dummy hidden animation at the end as a workaround
        animate((TOT_EDT_OUT_TS) + 's ease-out',
            keyframes([
                style({marginTop: '-10px', transform: 'translateY(-100%)', offset: (EDT_PNL_OUT_TS / TOT_EDT_OUT_TS)}),
                // below a dummy hidden animation as a workaround to keep the parallel parent rotation animation from abruptly stopping
                // see here https://github.com/angular/angular/issues/15798#issuecomment-305934289
                style({marginTop: '-15px', transform: 'translateY(-100%)', offset: 1})
            ])
        )
    ])
]);

export const editCvsToggle = trigger('edtCvsTgl', [
    transition(':enter', [
        style({opacity: 0, transform: 'rotateX(-90deg)', transformOrigin: '0 0 0'}),
        animate(CVS_ROT_IN_TS + 's ' + CVS_ROT_OUT_TS + 's ease-in',
            style({opacity: 1, transform: 'rotateX(0)', transformOrigin: '0 0 0'})
        )
    ]),
    transition(':leave', [
        style({opacity: 1, transform: 'rotateX(0)', transformOrigin: '0 100% 0'}),
        animate(CVS_ROT_OUT_TS + 's ' + EDT_PNL_OUT_TS + 's ease-out',
            style({opacity: 0, transform: 'rotateX(90deg)', transformOrigin: '0 100% 0'})
        )
    ])
]);

export const previewCvsToggle = trigger('prvCvsTgl', [
    transition(':enter', [
        style({opacity: 0, transform: 'rotateX(-90deg)', transformOrigin: '0 0 0'}),
        animate(CVS_ROT_IN_TS + 's ' + (CVS_ROT_OUT_TS + EDT_PNL_OUT_TS) + 's ease-in',// wait for  edit panels + edit cvs to leave then rotate in
            style({opacity: 1, transform: 'rotateX(0)', transformOrigin: '0 0 0'})
        )
    ]),
    transition(':leave', [
        style({opacity: 1, transform: 'rotateX(0)', transformOrigin: '0 100% 0'}),
        animate(CVS_ROT_OUT_TS + 's ease-out',
            style({opacity: 0, transform: 'rotateX(90deg)', transformOrigin: '0 100% 0'})
        )
    ])
]);
