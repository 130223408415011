import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NtEmailResponse} from './nt-email-response';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NtEmailRegisterService {

    constructor(private http: HttpClient) { }

    registerEmail(email: string): Observable<NtEmailResponse> {
        return this.http.put<NtEmailResponse>('/mailSubmit', email);
    }
}
