<section>
    <div class="{{responsiveCls}}" [ngClass]="{'nt-mbl': isHandset, 'nt-scr': !isHandset}">
        <div class="content">
            <h1>{{productPage.sectionTitle}}</h1>
            <div class="vid-wrp">
                <div class="video">
                    <div style="padding:62.5% 0 0 0;position:relative;">
                        <iframe src="https://player.vimeo.com/video/702238018?title=0&byline=0&portrait=0"
                                style="position:absolute;top:0;left:0;width:100%;height:100%;"
                                frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
            <p>
                Fed up with clunky math plotting software? Math-Glide is a unique plotting tool designed to render
                and animate sophisticated math curves on the go with outstanding performance and special emphasis on a fun,
                top-notch user-experience.
            </p>

            <p class="pre-lst">
                Key features include:
            </p>
            <ul>
                <li>Curve animation in real-time</li>
                <li>Extensive math libraries bundled out of the box</li>
                <li>Seamless integration of custom code and third-party libraries</li>
                <li>Multiple view-ports</li>
                <li>Precise approximation and rendering of curve intersections</li>
                <li>... and much more</li>
            </ul>

            <p>
                The video above is a proof of concept, Math-Glide is still under development. Are you a math specialist,
                researcher or maybe even an amateur looking for the right instrument to put your sophisticated equations
                in motion and unlock invaluable insight? Math-Glide is your best bet, subscribe and be the first to learn
                about the release!
            </p>

            <nt-email-prompt class="ml"></nt-email-prompt>
        </div>
    </div>
</section>
