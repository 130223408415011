import { Component, OnInit } from '@angular/core';
import {AbstractComponent} from '../../abstract-component';
import {ProductPage} from '../../product-page';
import {BreakpointObserver} from '@angular/cdk/layout';

@Component({
  selector: 'nt-lifeguard',
  templateUrl: './lifeguard.component.html',
  styleUrls: ['./lifeguard.component.scss', '../content-container.component.scss']
})
export class LifeguardComponent extends AbstractComponent implements OnInit {
    static readonly PRODUCT = new ProductPage('/lifeguard', 'Lifeguard');

    constructor(public breakpointObserver: BreakpointObserver) {
        super(breakpointObserver);
        LifeguardComponent.PRODUCT.pageComp = this;
    }

    ngOnInit() {
        super.ngOnInit();
    }

}
