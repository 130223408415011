import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AbstractComponent} from '../../abstract-component';
import {Subject} from 'rxjs';
import {PgnSelectionEvent} from './core/highlight/mouse-highlight/pgn-selection-event';
import {ProductPage} from '../../product-page';
import {BreakpointObserver, MediaMatcher} from '@angular/cdk/layout';
import {PgnEditContainerComponent} from './core/edit/edit-container/pgn-edit-container.component';
import {PgnPreviewComponent} from './core/highlight/mouse-highlight/pgn-preview.component';
import {PgnGraphFactory} from './core/core-canvas/graph/pgn-graph-factory';
import {PgnGraphDataService} from './core/core-canvas/graph/pgn-graph-data.service';
import {PgnGraphRenderer} from './core/core-canvas/graph/pgn-graph-renderer';
import {ToolbarButtonDescriptor} from '../../utils/toolbar-btn/toolbar-button-descriptor';
import {editCvsToggle, EDT_PNL_OUT_TS, previewCvsToggle} from './pgn-cvs-animations';
import {animateChild, query, transition, trigger} from '@angular/animations';

@Component({
    selector: 'nt-polygon',
    animations: [
        trigger('animateChildren', [
            transition(':enter, :leave', [
                query('@*', animateChild())
            ])
        ]),
        editCvsToggle,
        previewCvsToggle
    ],
    templateUrl: './polygon.component.html',
    styleUrls: ['./polygon.component.scss', '../content-container.component.scss']
})
export class PolygonComponent extends AbstractComponent implements OnInit {
    static readonly PRODUCT = new ProductPage('/polygon', 'Polygon');

    @ViewChild('edtCmp') editContainerComp: PgnEditContainerComponent;
    @ViewChild('prvCmp') previewComp: PgnPreviewComponent;

    polygonId = "xUtG1xIL";

    selectionSubject = new Subject<PgnSelectionEvent>();

    interactionModeButtons = new Array<ToolbarButtonDescriptor>();

    editModeBtn: ToolbarButtonDescriptor;
    previewModeBtn: ToolbarButtonDescriptor;

    selectedInteractionMode: ToolbarButtonDescriptor;

    graphRenderer: PgnGraphRenderer;

    constructor(protected graphDataService: PgnGraphDataService,
                public breakpointObserver: BreakpointObserver,
                _mediaMatcher: MediaMatcher) {
        super(breakpointObserver);
        PolygonComponent.PRODUCT.pageComp = this;
        this.interactionModeButtons.push(this.editModeBtn = new ToolbarButtonDescriptor("bg-edit-32", "Edit Mode", true, 'medium', 'no-bg'));
        this.interactionModeButtons.push(this.previewModeBtn = new ToolbarButtonDescriptor("bg-preview-32", "Preview Mode", true, 'medium', 'no-bg'));
        this.selectedInteractionMode = this.previewModeBtn;
        this.selectedInteractionMode.pressed = true;

        // console.log('pointer-fine = ' + mediaMatcher.matchMedia('(pointer: fine)').matches);
        //
        // console.log('hover = ' + mediaMatcher.matchMedia('(hover: hover)').matches);
    }

    onInteractionModePress(btn: ToolbarButtonDescriptor) {
        if(this.selectedInteractionMode === btn) return;
        this.selectedInteractionMode.pressed = false;
        btn.pressed = true;
        this.selectedInteractionMode = btn;
        this.graphRenderer.isEditMode = this.editModeBtn.pressed;
        this.graphRenderer.fireRepaintAction(false);
    }

    displayEditView(): boolean {
        return (this.selectedInteractionMode === this.editModeBtn);
    }

    ngOnInit() {
        super.ngOnInit();
        const self = this;
        this.graphDataService.loadGraphData(this.polygonId).subscribe((graph) => {
            const graphRenderer = PgnGraphFactory.toGraphRenderer(graph, false);
            self.graphRenderer = graphRenderer;
            graphRenderer.initGraphics(document);
            if(self.editContainerComp) {
                self.editContainerComp.graphRendererInitialized(graphRenderer);
            }
            if(self.previewComp) {
                self.previewComp.graphRendererInitialized(graphRenderer);
            }
        });
    }

    pageResizeReflected() {
        if(this.previewComp) {
            this.previewComp.pageResizeReflected();
        }
        if(this.editContainerComp) {
            this.editContainerComp.pageResizeReflected();
        }
    }

    isPageResizeReflected(): boolean {
        return this.previewComp && this.previewComp.isPageResizeReflected() &&
            (!this.editContainerComp || this.editContainerComp.isPageResizeReflected());
    }

    pageTransitionStart() {
        if(this.previewComp) {
            this.previewComp.pageTransitionStart();
        }
        if(this.editContainerComp) {
            this.editContainerComp.pageTransitionStart();
        }
    }

    pageTransitionEnd() {
        if(this.previewComp) {
            this.previewComp.pageTransitionEnd();
        }
        if(this.editContainerComp) {
            this.editContainerComp.pageTransitionEnd();
        }
    }
}
