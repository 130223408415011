<div class="nt-container-div">
    <nt-mouse-edit [polygonId]="polygonId"
                   [graphRenderer]="graphRenderer"
                   class="nt-comp"></nt-mouse-edit>
</div>

<div class="btm-edt-tbr" @btmTlbrTgl>
    <nt-pgn-graph-edit-toolbar [graphRenderer]="graphRenderer"></nt-pgn-graph-edit-toolbar>
</div>

<!--<div class="toolbar">-->
    <!--<ul>-->
        <!--<li *ngFor="let editBtn of editButtons">-->
            <!--<nt-toolbar-btn [btnDescr]="editBtn"-->
                            <!--(ntclick)="onToolbarBtnPress(editBtn)"></nt-toolbar-btn>-->
        <!--</li>-->
    <!--</ul>-->
<!--</div>-->

<!--<div *ngIf="mouseEditComp && mouseEditComp.graphRenderer" class="edit-controls-div">-->
    <!--<div class="pgn-layer">-->
        <!--<h3>Stroke</h3>-->
        <!--<div class="row">-->
            <!--<label for="strokeWidth">Width</label>-->
            <!--<input type="number"-->
                   <!--id="strokeWidth"-->
                   <!--[(ngModel)]="mouseEditComp.graphRenderer.strokeWidth"-->
                   <!--(keydown)="strokeWidthChanged($event)">-->
        <!--</div>-->

        <!--<div class="row">-->
            <!--<label for="stroke">Selection</label>-->
            <!--<input id="stroke"-->
                   <!--class="clr-pick"-->
                   <!--readonly-->
                   <!--[(colorPicker)]="selectionStrokeColor"-->
                   <!--[style.background]="selectionStrokeColor"-->
                   <!--(colorPickerSelect)="selectionStrokeColorChange($event)"-->
                   <!--[cpOKButton]="true"-->
                   <!--[cpCancelButton]="true"-->
                   <!--[cpPosition]="'bottom'"-->
                   <!--[cpPositionOffset]="'-72%'"-->
                   <!--[cpPositionRelativeToArrow]="true"/>-->
        <!--</div>-->

        <!--<div class="row">-->
            <!--<label for="hover-stroke">Hover</label>-->
            <!--<input id="hover-stroke"-->
                   <!--class="clr-pick"-->
                   <!--readonly-->
                   <!--[(colorPicker)]="hoverStrokeColor"-->
                   <!--[style.background]="hoverStrokeColor"-->
                   <!--(colorPickerSelect)="hoverStrokeColorChange($event)"-->
                   <!--[cpOKButton]="true"-->
                   <!--[cpCancelButton]="true"-->
                   <!--[cpPosition]="'bottom'"-->
                   <!--[cpPositionOffset]="'-72%'"-->
                   <!--[cpPositionRelativeToArrow]="true">-->
        <!--</div>-->
    <!--</div>-->

    <!--<div *ngFor="let layer of mouseEditComp.layers"-->
         <!--[ngClass]="{'pgn-layer': true,-->
       <!--'layer-editing': layer.isEditing,-->
       <!--'layer-no-edit':!layer.isEditing}">-->
        <!--<nt-layer-text-edit [canvasEditObservable]="canvasEditObservable"-->
                           <!--[layerRenderer]="layer"-->
                           <!--[parentComp]="mouseEditComp"-->
                           <!--[editContainerComp]="this">-->

        <!--</nt-layer-text-edit>-->
    <!--</div>-->
<!--</div>-->
