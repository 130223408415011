import {AfterViewInit, Component, ElementRef, Input, OnInit} from '@angular/core';
import {PgnGraphRenderer} from '../../core-canvas/graph/pgn-graph-renderer';
import {PgnGraphFactory} from '../../core-canvas/graph/pgn-graph-factory';
import {NtAbstractCompComponent} from '../../../../../utils/nt-abstract-comp/nt-abstract-comp.component';

@Component({
    selector: 'nt-pgn-edit-panel',
    templateUrl: './pgn-edit-panel.component.html',
    styleUrls: ['./pgn-edit-panel.component.scss']
})
export class PgnEditPanelComponent extends NtAbstractCompComponent implements AfterViewInit {


    private _graphRenderer: PgnGraphRenderer = null;
    protected isSizeCacheInitialized = false;

    // to pass through to nt-input components
    inputWidth = '60px';


    public get graphRenderer(): PgnGraphRenderer {
        return this._graphRenderer ? this._graphRenderer : PgnGraphFactory.emptyGraphRenderer;
    }

    @Input() public set graphRenderer(graphRenderer: PgnGraphRenderer) {
        this._graphRenderer = graphRenderer;
    }

    constructor(protected hostEl:ElementRef) {
        super();
    }

    public applyGraphChange(potentialSizeChange: boolean) {
        this.graphRenderer.fireRepaintAction(potentialSizeChange);
    }

    ngAfterViewInit(): void {
        const width = this.hostEl.nativeElement.getBoundingClientRect().width;
        const height = this.hostEl.nativeElement.getBoundingClientRect().height;
        // should not reflect size change at afterViewInit hook => postpone to next frame to avoid angular error
        const self = this;
        this.tick_then(() => {
            self.sizeInitialized(width, height);
            self.isSizeCacheInitialized = true;
        })
    }

    protected sizeInitialized(_width: number, _height: number) {
        // optionally to implement by sub classes when needed
    }

}
