<section id="ansi-highlight-sec" >
    <div class="{{responsiveCls}}" [ngClass]="{'nt-mbl': isHandset, 'nt-scr': !isHandset}">
        <div *ngIf="isViewLoaded" class="ansi-banner-div"
             [style]="{
             width: bannerImgWidth + 'px',
             height: bannerImgHeight + 'px'
             }">
            <div class="sprite bg-ansi_highlight_banner"
                 [style]="{
                 width: bannerImgStaticWidth + 'px',
                 height: bannerImgStaticHeight + 'px',
                 transform: 'scale(' + bannerImgScale + ')'
                 }"></div>
        </div>
        <div class="content">
            <div class="cnt">
                <div class="ansi-descr-div">
                    <h1>{{productPage.sectionTitle}}</h1>
                    <p>
                        In the total absence of text editors capable of interpreting ANSI sequences,
                        <a target="_blank" rel="noopener noreferrer" href="https://plugins.jetbrains.com/plugin/9707-ansi-highlighter-premium">
                            ANSI Highlighter Premium</a>
                        harnesses the power of the Intellij platform to fulfill the
                        need of rendering ANSI escape sequences in log files from within the convenience
                        of the IDE editor, all while delivering outstanding performance when dealing with large logs.
                    </p>

                    <p>
                        Boost your development environment and join over a million developers from all backgrounds who
                        have chosen
                        <a target="_blank" rel="noopener noreferrer" href="https://plugins.jetbrains.com/plugin/9707-ansi-highlighter-premium">
                            ANSI Highlighter Premium</a>.
                        Plugin available on the JetBrains Marketplace, compatible with
                        all Jetbrains products.
                    </p>
                </div>
                <div *ngIf="isViewLoaded" class="ansi-ss-div shadow"
                     [style]="{
                     width: ssImgWidth + 'px',
                     height: ssImgHeight + 'px'
                     }">
                    <!--<img class="ansi-ss-img" src="https://res.cloudinary.com/nifty-thoughts/image/upload/v1653803380/ansi-highlight-ss_uuaqmx.png" -->
                         <!--[style]="{-->
                         <!--width: ssImgStaticWidth + 'px',-->
                         <!--height: ssImgStaticHeight + 'px',-->
                         <!--transform: 'scale(' + ssImgScale + ')',-->
                         <!--transformOrigin: 'top left'-->
                     <!--}"-->
                    <!--/>-->

                    <img class="ansi-ss-img" src="https://res.cloudinary.com/nifty-thoughts/image/upload/v1653803380/ansi-highlight-ss_uuaqmx.png"/>

                    <!--<div class="sprite bg-ansi_highlight_ss"-->
                         <!--[style]="{-->
                         <!--width: ssImgStaticWidth + 'px',-->
                         <!--height: ssImgStaticHeight + 'px',-->
                         <!--transform: 'scale(' + ssImgScale + ')'-->
                         <!--}"></div>-->
                </div>
                <div class="line"></div>
                <h1 class="hdr2">Supported IDE's</h1>
                <div class="prd-div">
                    <div class="prd-icn-dv">
                        <div *ngFor="let imgCls of productSpriteClass" class="prd-icn-wrp">
                            <div class="sprite prd-icn {{imgCls}}"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
