import {PgnLayerRenderer} from './pgn-layer-renderer';
import {PgnLayerGroup} from './pgn-layer-group';

export class PgnRawLayerGroup<T extends string | PgnLayerRenderer> {
    name: string;

    // when retrieved from the server, layers are identified via their name => list of strings
    // upon layer deserialization the list is mapped to Array<PgnLayerRenderer>
    layers: Array<T>;

}
