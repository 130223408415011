<!-- invoking color picker briefly just as a workaround to initialize picker size cache at app start -->
<nt-color-picker [style]="{visibility: 'hidden', position: 'fixed'}"
                 *ngIf="!isColorPickerSizeCached()">
</nt-color-picker>
<nt-pgn-padding-edit [style]="{visibility: 'hidden', position: 'fixed'}"
                     *ngIf="!isGraphPaddingPanelSizeCached()">

</nt-pgn-padding-edit>
<nt-pgn-stroke-edit-popup [style]="{visibility: 'hidden', position: 'fixed'}"
                          *ngIf="!isGraphStrokePanelSizeCached()">
</nt-pgn-stroke-edit-popup>

<nt-navbar [animationEndEventSubject]="animationEndEventSubject"
           [productPages]="productPages"
           [ngClass]="{'navbar': !isHandset, 'navbar-mbl': isHandset}"></nt-navbar>

<nt-content-container [animationEndEventSubject]="animationEndEventSubject"
                      [productPages]="productPages"
                      [ngClass]="{'ctn-root': !isHandset, 'ctn-root-mbl': isHandset}"></nt-content-container>

