import {PgnLayer} from '../layer/pgn-layer';
import {PgnLayerRenderer} from '../layer/pgn-layer-renderer';
import {PgnRawLayerGroup} from '../layer/pgn-raw-layer-group';
import {PgnLayerGroup} from '../layer/pgn-layer-group';


const REGULAR_SCALE = 0.7;

export class PgnGraph <U extends PgnLayer<any> | PgnLayerRenderer, V extends PgnRawLayerGroup<string> | PgnLayerGroup> {

    name: string;

    scaleX = REGULAR_SCALE;
    scaleY = REGULAR_SCALE;

    // graph level stroke config is forced on all layers that are 'isClipping' to prevent flaws in drawing
    // per layer stroke config is still possible on layers that are not 'isClipping'
    strokeWidth: number;
    strokeColor: string;
    hStrokeColor: string;
    strokeOpacity: number;
    hStrokeOpacity: number;

    leftPad = 0;
    topPad = 0;
    rightPad = 0;
    bottomPad = 0;


    // loaded separately from json
    img: HTMLImageElement;

    // calculated after image loaded (not loaded from server json response)
    width: number;
    height: number;

    // When the json array is queried from the server it can carry a mixture of polymorphic shape sub-elements (example: rectangles,
    // circles, polygons etc...)) => The only way to receive the raw json array is to use Array<PgnLayer<any>>.
    // As soon as the raw data is received PgnGraphFactory takes care of de-serializing the raw data properly into PgnLayerRenderers
    // (carrying de-serialized PgnShapes (see PgnLayer.shapes docs for more details)).
    // U can either be;
    // - "PgnLayer<any>" (when caught raw from server response / short lived instance)
    // - "PgnLayerRenderer" as soon as data gets de-serialized (see PgnLayer.shapes docs for more details)
    // U can take no other form
    layers: Array<U>;

    // layer groups are used to define what subset of layers should respond to user interaction (hover/select) as a whole
    groups: Array<V>;

    public refreshSize() {
        this.width = this.img.width + this.leftPad + this.rightPad;
        this.height = this.img.height + this.topPad + this.bottomPad;
    }

    public imageLoaded(img: HTMLImageElement) {
        this.img = img;
        this.refreshSize();
    }
}
