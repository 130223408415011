<section id="home-sec" class="{{responsiveCls}} sec" [ngClass]="{'nt-mbl': isHandset, 'nt-scr': !isHandset}">
    <div class="ctn">
        <img src="https://res.cloudinary.com/nifty-thoughts/image/upload/v1653803380/logo_dro407.png"/>
        <div class="txt-wrp">
            <h1>We Bring Nifty Ideas To Life</h1>
            <span>We stand for exceptional quality and customer delight,
            check out what we're building!</span>
        </div>
        <div class="btn-wrp">
            <button (click)="polygonClick()"
            >{{polygonPage().navTitle}}</button>
            <button (click)="mathGlideClick()"
            >{{mathGlidePage().navTitle}}</button>
            <button (click)="ansiHighlightClick()"
            >{{ansiHighlighterPage().navTitle}}</button>
        </div>

    </div>

    <div class="banner-dark"></div>
    <div class="banner-light" #bnrLt
         [ngClass]="{'nvb-shft': !isPosFixedRelativeHeight, 'pg-anim': isPageAnimating}"
         [ngStyle]="{'height': ltBannerHeight}"></div>
</section>
