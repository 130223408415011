export class KeyEventUtils {

    public static isDownKey(event): boolean {
        return this.matchesOneOfKeys(this.getKey(event),  'Down', // IE / EDGE
            'ArrowDown'); // All the rest
    }

    public static isUpKey(event): boolean {
        return this.matchesOneOfKeys(this.getKey(event),  'Up', // IE / EDGE
            'ArrowUp'); // All the rest
    }

    public static isLeftKey(event): boolean {
        return this.matchesOneOfKeys(this.getKey(event),  'Left', // IE / EDGE
            'ArrowLeft'); // All the rest
    }

    public static isRightKey(event): boolean {
        return this.matchesOneOfKeys(this.getKey(event),  'Right', // IE / EDGE
            'ArrowRight'); // All the rest
    }

    public static isEnterKey(event): boolean {
        return this.matchesOneOfKeys(this.getKey(event),  'Enter');
    }

    public static isEscapeKey(event): boolean {
        return this.matchesOneOfKeys(this.getKey(event),  'Esc', // IE / EDGE
            'Escape'); // All the rest
    }

    public static isAlphaCharIgnoreCase(event, char: string): boolean {
        const key = this.getKey(event);
        return key && key.toUpperCase() === char.toUpperCase();
    }

    public static isAlphaChar(event, char: string): boolean {
        const key = this.getKey(event);
        return key && key === char;
    }


    private static matchesOneOfKeys(key: string, ...keys: string[]): boolean {
        if(!key) {
            return false;
        }
        const upper = key.toUpperCase();
        for(const k of keys) {
            if(k.toUpperCase() === upper)
                return true;
        }
        return false;
    }

    private static getKey(event): string {
        if(!event) {
            return null;
        }
        return event.key;
    }
}
