import {PgnLayerRenderer} from '../../core-canvas/layer/pgn-layer-renderer';
import {PgnCoreCanvasComponent} from '../../core-canvas/pgn-core-canvas.component';

export enum PgnSelectionMode {
  UNDEFINED,
  ADD_TO_SELECTION,
  REMOVE_FROM_SELECTION,
}

export class PgnSelectionEvent {
  selectedlayers: PgnLayerRenderer[];
  selectionMode: PgnSelectionMode;
  comp: PgnCoreCanvasComponent;

  constructor(selectedLayers: PgnLayerRenderer[],
              selectionMode: PgnSelectionMode,
              ntComp: PgnCoreCanvasComponent,) {
    this.selectedlayers = selectedLayers;
    this.selectionMode = selectionMode;
    this.comp = ntComp;
  }
}
