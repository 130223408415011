import {Component, ElementRef, Input, QueryList} from '@angular/core';
import {NtDraggableComponent} from '../nt-draggable/nt-draggable.component';
import {NtPoint} from '../nt-point';
import {NtUtils} from '../nt-utils';
import {Observable} from 'rxjs';
import {NtDndContext} from './nt-dnd-context';
import {NtDndRemovalContext} from './nt-dnd-removal-context';
import {NtDndRelayoutContext} from './nt-dnd-relayout-context';
import {NtDragHoverEvent} from './nt-drag-hover-event';
import {NtDragHoverType} from './nt-drag-hover-type.enum';
import {first, map} from 'rxjs/operators';
import {NtDndActionType} from './nt-dnd-action-type';
import {NtDndTriggerTag} from './nt-dnd-trigger-tag';
import {NtDndRemovalStrategy} from './nt-dnd-removal-strategy';
import {NtDomUtils} from '../nt-dom-utils';


@Component({
    selector: 'nt-drag-drop-handler',
    templateUrl: './nt-drag-drop-handler.component.html',
    styleUrls: ['./nt-drag-drop-handler.component.scss']
})
// P for parent generic data type / C for children generic data type
export abstract class NtDragDropHandlerComponent<P, C> extends NtDraggableComponent<P> {

    @Input() public handlerName: string;

    currentDraggable: NtDraggableComponent<any> = null;
    private draggablePt0 = new NtPoint();
    draggablePt = new NtPoint();

    animateDropPlaceHolder = false;
    isDropPlaceHolderExpanded = false;
    // @ts-ignore
    lastDropHandler: NtDragDropHandlerComponent = null;
    dropAnimationTop = '0';
    // tracks mouse location inside the handler (as opposed to draggable location)
    dropPt0 = new NtPoint();
    dropPt = new NtPoint();
    scrollableDraggablesContainerOffset = new NtPoint();
    actualDropPt = new NtPoint();

    dndEndAnimSizeDelta = 0;

    // @ts-ignore
    private lastHoveredDraggable: NtDraggableComponent = null;

    dndCtx: NtDndContext;

    hostElWidth: number;
    hostElHeight: number;

    constructor(public hostEl: ElementRef) {
        super(hostEl);
    }




    // ###############################################################################
    // ################# Utility Methods Exposed to External Calls ###################
    // ###############################################################################

    public reverseReorder(draggableIndex: number,
                          dropIndex: number,
                          dndTriggerTag: any = NtDndTriggerTag.REORDER_REVERSAL_FN) {
        const reversalDragIndex = dropIndex;
        const reversalDropIndex = draggableIndex;
        this.moveDraggableToIndex(reversalDragIndex, reversalDropIndex, dndTriggerTag);
    }

    public moveDraggableToIndex(draggableIndex: number,
                                targetIndex: number,
                                dndTriggerTag: any = NtDndTriggerTag.REORDER_FN ) {
        if(draggableIndex === targetIndex) {
            return;
        }
        const draggable = this.draggableList().get(draggableIndex);
        const dndType = NtDndActionType.REORDER_SAME_HANDLER;
        this.dndCtx = new NtDndContext(draggable,
            <NtDraggableComponent<C>>(<any>draggable),
            draggableIndex,
            targetIndex,
            true,
            dndType,
            dndTriggerTag, null,
            NtDndRemovalStrategy.KEEP,
            -1);
        draggable.beforeDnDReorder(this.dndCtx);
        this.beforeDnDReorder(this.dndCtx);
        const self = this;
        this.reorderDraggableList(draggableIndex, targetIndex).subscribe((_r) => {
            // after reorder is reflected on UI all items are refreshed => update dndCtx
            self.dndCtx.draggable = self.dndCtx.droppedEl = self.draggableList().get(targetIndex);
            self.elementDropped2(draggable, <NtDraggableComponent<C>>(<any>draggable), draggableIndex, targetIndex);
        });
    }

    // @ts-ignore
    public dndDuplicate(draggableToDuplicate: NtDraggableComponent,
                        // @ts-ignore
                        targetDropHandler: NtDragDropHandlerComponent,
                        dropIndex: number,
                        dndTriggerTag: any = NtDndTriggerTag.DUPLICATE_FN) {
        const removalStrategy = NtDndRemovalStrategy.KEEP;
        const dragIndex = draggableToDuplicate.indexUnderParentHandler();
        const dndType = draggableToDuplicate.parentDnDHandler === targetDropHandler ?
            NtDndActionType.DUPLICATE_SAME_HANDLER : NtDndActionType.DUPLICATE_ACROSS_HANDLERS;
        const duplicateData = targetDropHandler.createDataFromDuplicatedDroppedEl(draggableToDuplicate);
        targetDropHandler.fnTriggeredDnd(draggableToDuplicate, duplicateData, dragIndex, dropIndex, -1,
            removalStrategy, dndTriggerTag, dndType);
    }

    // @ts-ignore
    public reverseDnDDuplicate(draggableDuplicate: NtDraggableComponent,
                               dndTriggerTag: any = NtDndTriggerTag.DUPLICATE_REVERSAL_FN): boolean {
        const draggableIndex = draggableDuplicate.indexUnderParentHandler();
        return this.reverseInsert(draggableIndex, dndTriggerTag);
    }

    // @ts-ignore
    public dndMoveAcrossHandlers(draggable: NtDraggableComponent,
                                 // @ts-ignore
                                 dropHandler: NtDragDropHandlerComponent,
                                 dropIndex: number,

                                 // must be in (REMOVE_AND_NO_BUBBLE_REMOVAL, REMOVE_AND_BUBBLE_REMOVAL_CUSTOM, REMOVE_AND_BUBBLE_REMOVAL_TILL_PRESET_DEPTH)
                                 // otherwise the call will be canceled with no action
                                 draggableRemovalStrategy = NtDndRemovalStrategy.REMOVE_AND_NO_BUBBLE_REMOVAL,

                                 // removalBubbleEndDepth is considered only when draggableRemovalStrategy = REMOVE_AND_BUBBLE_REMOVAL_TILL_PRESET_DEPTH
                                 // otherwise the supplied value will be ignored and the relevant value will be auto-inferred
                                 removalBubbleEndDepth: number = -1,

                                 dndTriggerTag: any = NtDndTriggerTag.DND_ACROSS_HANDLERS_FN): boolean {
        const dragIndex = draggable.indexUnderParentHandler();
        const dndType = NtDndActionType.MOVE_ACROSS_HANDLERS;
        const dndCtx = new NtDndContext(draggable, null, dragIndex, dropIndex, false, dndTriggerTag,
            dndType, null, draggableRemovalStrategy, removalBubbleEndDepth);
        dndCtx.removalBubbleEndDepth = removalBubbleEndDepth = this.removalBubbleDepth(dndCtx);
        if(removalBubbleEndDepth === -2) {// non sense removal bubble strategy
            return false;
        }
        const droppedData = dropHandler.adaptMovedDataFromDroppedEl(draggable);

        dropHandler.fnTriggeredDnd(draggable, droppedData, dragIndex, dropIndex, removalBubbleEndDepth,
            draggableRemovalStrategy, dndTriggerTag, dndType, dndCtx);

        return true;
    }

    /**
     * If the DnD event to reverse is a reorder within the same handler, reverseReorder() should be used
     * If the DnD event to reverse is a duplicate event reverseDnDDuplicate() should be used (even if cross-handler duplication)
     * @param removedDraggableParentResolveKey removal reversal requires access to parent handler from which draggable has been removed.
     *                                          key should have minimum memory foot print
     *                                          => cannot be null
     *                                          => rootHandler.resolveHandler() custom implementation should be able to resolve the key
     *                                          => if draggable removal was bubbled to an ancestor (ancestor child list emptied), removedDraggableParentResolveKey should
     *                                          resolve to the ancestor's parent
     * @param restorableRemovedData a compressed version of the removed data where possible to reduce memory footprint.
     *                                => cannot be null
     *                                => originalDragHandler.restoreData() custom implementation should be able to restored the compressed data
     *                                => if removed data was bubbled to an ancestor, restorableRemovedData should restore the ancestor data, which
     *                                in turn should carry all the necessary context to reconstruct the ancestor wrapping draggable component and
     *                                the subsequent removed branches
     * @param removalIndex if removed data was bubbled to an ancestor, removalIndex should indicate the ancestor's index under its parent. Otherwise the removed item index.
     * @param droppedEl is already in memory before the call, should be passed as is without compression (memory optimization not relevant)
     *                   could be null if original dropHandler.createDataFromDuplicatedDroppedEl() call returned null
     *                   => if null drop effect won't be reversed (drag effect will)
     * @param dropIndex
     * @param dndTriggerTag optional, to use if additional context needs to be injected into DnD callbacks for custom implementation use where needed
     */
    public reverseDnDMoveAcrossHandlers(removedDraggableParentResolveKey: any,
                                        restorableRemovedData: any,
                                        removalIndex: number,
                                        // @ts-ignore
                                        droppedEl: NtDraggableComponent,
                                        dropIndex: number,
                                        dndTriggerTag: any = NtDndTriggerTag.DND_ACROSS_HANDLERS_REVERSAL_FN) {
        const rootHandler = this.rootHandler();
        const reversalDropHandler = rootHandler.resolveHandler(removedDraggableParentResolveKey);
        const restoredData = reversalDropHandler.restoreData(restorableRemovedData);
        const reversalDraggable = droppedEl;
        const reversalDragIndex = dropIndex;
        const reversalDropIndex = removalIndex;
        const removalBubbleEndDepth = droppedEl ? droppedEl.draggableDepth : -1;
        const removalStrategy = NtDndRemovalStrategy.REMOVE_AND_NO_BUBBLE_REMOVAL;
        reversalDropHandler.fnTriggeredDnd(reversalDraggable, restoredData,
            reversalDragIndex, reversalDropIndex, removalBubbleEndDepth, removalStrategy, dndTriggerTag);
    }

    public insertNewData(newData: C,
                         insertIndex: number,
                         dndTriggerTag = NtDndTriggerTag.INSERT_FN) {
        const removalStrategy = NtDndRemovalStrategy.KEEP;
        const dndType = NtDndActionType.DUPLICATE_SAME_HANDLER;
        this.fnTriggeredDnd(null, newData, -1, insertIndex, -1,
            removalStrategy, dndTriggerTag, dndType);
    }

    public reverseInsert(insertIndex: number,
                         dndTriggerTag = NtDndTriggerTag.INSERT_REVERSAL_FN): boolean {
        const draggableRemovalStrategy = NtDndRemovalStrategy.REMOVE_AND_NO_BUBBLE_REMOVAL;

        return this.removeDraggableAt(insertIndex, draggableRemovalStrategy, -1, dndTriggerTag);
    }

    // @ts-ignore
    public removeDraggable(draggable: NtDraggableComponent,

                           // see removeDraggableAt() docs
                           draggableRemovalStrategy = NtDndRemovalStrategy.REMOVE_AND_NO_BUBBLE_REMOVAL,

                           // see removeDraggableAt() docs
                           removalBubbleEndDepth: number = -1,

                           dndTriggerTag = NtDndTriggerTag.REMOVE_FN): boolean {
        const draggableIndex = draggable.indexUnderParentHandler();
        return this.removeDraggableAt(draggableIndex, draggableRemovalStrategy, removalBubbleEndDepth, dndTriggerTag);
    }

    public removeDraggableAt(draggableIndex: number,

                             // must be in (REMOVE_AND_NO_BUBBLE_REMOVAL, REMOVE_AND_BUBBLE_REMOVAL_CUSTOM, REMOVE_AND_BUBBLE_REMOVAL_TILL_PRESET_DEPTH)
                             // otherwise the call will be canceled with no action
                             draggableRemovalStrategy = NtDndRemovalStrategy.REMOVE_AND_NO_BUBBLE_REMOVAL,

                             // removalBubbleEndDepth is considered only when draggableRemovalStrategy = REMOVE_AND_BUBBLE_REMOVAL_TILL_PRESET_DEPTH
                             // otherwise teh supplied value will be ignored and the relevant value will be auto-inferred
                             removalBubbleEndDepth: number = -1,

                             dndTriggerTag = NtDndTriggerTag.REMOVE_FN): boolean {
        const draggable = this.draggableList().get(draggableIndex);
        const dndType = NtDndActionType.MOVE_ACROSS_HANDLERS;
        const dndCtx = new NtDndContext(draggable, null, draggableIndex, -1, true, dndTriggerTag,
            dndType, null, draggableRemovalStrategy, removalBubbleEndDepth);
        dndCtx.removalBubbleEndDepth = removalBubbleEndDepth = this.removalBubbleDepth(dndCtx);
        if(removalBubbleEndDepth === -2) {
            return false;
        }
        this.fnTriggeredDnd(draggable, null, draggableIndex, -1,
            removalBubbleEndDepth, draggableRemovalStrategy, dndTriggerTag, dndType, dndCtx);
        return true;
    }

    public reverseDraggableRemoval(removedDraggableParentResolveKey: any,
                                   restorableRemovedData: any,
                                   removalIndex: number) {
        const dndTriggerTag = NtDndTriggerTag.REMOVE_REVERSAL_FN;
        const rootHandler = this.rootHandler();
        const reversalDropHandler = rootHandler.resolveHandler(removedDraggableParentResolveKey);
        const restoredData = reversalDropHandler.restoreData(restorableRemovedData);
        reversalDropHandler.insertNewData(restoredData, removalIndex, dndTriggerTag);
    }

    // ###############################################################################
    // ########################### End Utility Methods ###############################
    // ###############################################################################





    // @ts-ignore
    private fnTriggeredDnd(draggable: NtDraggableComponent,
                           droppedData: C,
                           dragIndex: number,
                           dropIndex: number,
                           removalBubbleEndDepth: number,
                           draggableRemovalStrategy = NtDndRemovalStrategy.KEEP_CUSTOM_AND_BUBBLE_REMOVAL_CUSTOM,
                           dndTriggerTag: any = NtDndTriggerTag.DND_ACROSS_HANDLERS_FN,
                           dndType = NtDndActionType.MOVE_ACROSS_HANDLERS,
                           dndCtx: NtDndContext = null) {
        this.dndCtx = dndCtx ? dndCtx :
            new NtDndContext(draggable, null, dragIndex, dropIndex, false, dndTriggerTag, dndType, null,
                draggableRemovalStrategy, removalBubbleEndDepth);
        if(droppedData) {
            if(draggable) {
                draggable.parentDnDHandler.dragHandlerBeforeNewElementDropped(this.dndCtx);
            }
            this.dropHandlerBeforeNewElementDropped(this.dndCtx);
            const self = this;
            this.insertNewDraggableFromData(droppedData, dropIndex)
                .subscribe(droppedEl => {
                    if(dndType === NtDndActionType.DUPLICATE_SAME_HANDLER && dragIndex >= 0) {
                        let newDragIndex = dragIndex;
                        if(dropIndex <= dragIndex) {
                            newDragIndex ++;
                        }
                        self.dndCtx.draggable = self.draggableList().get(newDragIndex);
                        self.dndCtx.dragIndex = newDragIndex;
                    }
                    self.dndCtx.droppedEl = droppedEl;
                    self.elementDropped2(draggable, droppedEl, dragIndex, dropIndex);
                });
        } else {// triggered by removal function or adaptMovedDataFromDroppedEl() user custom implementation returned null
            this.elementDropped2(draggable, null, dragIndex, dropIndex);
        }
    }


    /**
     * Private method called only by dndMoveAcrossHandlers() and removeDraggableAt()
     * => in both cases the draggable is necessarily removed
     * => only below removal strategies are supported:
     * - NtDndRemovalStrategy.REMOVE_AND_NO_BUBBLE_REMOVAL => returns the draggable depth
     * - NtDndRemovalStrategy.REMOVE_AND_BUBBLE_REMOVAL_CUSTOM => see NtDndRemovalStrategy docs for depth calculation details
     * - NtDndRemovalStrategy.REMOVE_AND_BUBBLE_REMOVAL_TILL_PRESET_DEPTH => returns dndCtx.removalBubbleEndDepth if positive, otherwise the draggable depth
     *
     * returns -2 if another removal strategy is supplied => may be evolved to support more strategies if needed by new use cases
     * @param dndCtx
     */
    private removalBubbleDepth(dndCtx: NtDndContext): number {
        if(dndCtx.draggableRemovalStrategy === NtDndRemovalStrategy.REMOVE_AND_NO_BUBBLE_REMOVAL) {
            return dndCtx.draggable.draggableDepth;
        } else if (dndCtx.draggableRemovalStrategy === NtDndRemovalStrategy.REMOVE_AND_BUBBLE_REMOVAL_CUSTOM) {
            return this.draggableRemovalBubbleDeepestAncestor(dndCtx.draggable, dndCtx).draggableDepth;
        } else if(dndCtx.draggableRemovalStrategy === NtDndRemovalStrategy.REMOVE_AND_BUBBLE_REMOVAL_TILL_PRESET_DEPTH) {
            if(dndCtx.removalBubbleEndDepth < 0) {
                return dndCtx.draggable.draggableDepth;
            }
            return dndCtx.removalBubbleEndDepth;
        } else {
            return -2;
        }
    }



    public rootHandler() {
        let root = this;
        while (root.parentDnDHandler) {
            root = root.parentDnDHandler;
        }
        return root;
    }

    public dragEventStarted<T, U extends NtDraggableComponent<T>>(draggable: U, e: MouseEvent) {
        this.initDraggableSimulationInRootHandler(draggable, e);
        if(!this.initResponsiveDropAnimation(draggable, e)) {
            this.initExternalDropHandlerAnimation(draggable, e);
        }
    }

    protected initResponsiveDropAnimation<T, U extends NtDraggableComponent<T>>(draggable: U, e: MouseEvent): boolean {
        const sourceHandler = draggable.parentDnDHandler;
        if(this.isDropPossibleForDraggable(draggable)) {
            NtDomUtils.convertMouseEventCoordinates(e, this.hostEl, this.dropPt);
            NtDomUtils.convertMouseEventCoordinates(e, this.draggablesDirectContainer(), this.scrollableDraggablesContainerOffset);
            this.scrollableDraggablesContainerOffset.x = this.dropPt.x - this.scrollableDraggablesContainerOffset.x;
            this.scrollableDraggablesContainerOffset.y = this.dropPt.y - this.scrollableDraggablesContainerOffset.y;
            this.hostElWidth = this.hostEl.nativeElement.offsetWidth;
            this.hostElHeight = this.hostEl.nativeElement.offsetHeight;
            if(this.containsDropLocation()) {
                this.dropPt0.x = this.dropPt.x - draggable.dragDx;
                this.dropPt0.y = this.dropPt.y - draggable.dragDy;
                this.actualDropPt.x = this.dropPt.x - this.scrollableDraggablesContainerOffset.x;
                this.actualDropPt.y = this.dropPt.y - this.scrollableDraggablesContainerOffset.y;
                this.animateDropPlaceHolder = true;
                sourceHandler.lastDropHandler = this;
                this.updateResponsiveDropAnimationCtx();
                this.updateDragHoverCallbacks(draggable, e);
                return true;
            }
        }
        return false;
    }

    protected initExternalDropHandlerAnimation<T, U extends NtDraggableComponent<T>>(draggable: U, e: MouseEvent): boolean {
        const sourceDragHandler = draggable.parentDnDHandler;
        const allDropHandlers = sourceDragHandler.allDropHandlers();
        const lastDropHandler = sourceDragHandler.lastDropHandler;
        if(!allDropHandlers) {
            sourceDragHandler.lastDropHandler = null;
            return false;
        }
        for(let handlers of allDropHandlers) {
            for(let handler of handlers) {
                if(handler !== this &&
                    handler !== lastDropHandler && // useful when called from dragEventMoved() to avoid an extra unnecessary check
                    handler.initResponsiveDropAnimation(draggable, e)) {
                    sourceDragHandler.lastDropHandler = handler;
                    return true;
                }
            }
        }
        sourceDragHandler.lastDropHandler = null;
        return false;
    }

    protected refreshDropResponsiveAnimation<T, U extends NtDraggableComponent<T>>(draggable: U, e: MouseEvent): boolean {
        if(this.isDropPossibleForDraggable(draggable)) {
            this.updateDropCoordinates(draggable);
            if(this.containsDropLocation()) {
                this.updateResponsiveDropAnimationCtx();
                this.updateDragHoverCallbacks(draggable, e);
                return true;
            }
        }
        const sourceHandler = draggable.parentDnDHandler;
        if(sourceHandler.lastDropHandler === this) {
            this.endDropPlaceHolderAnimation(draggable, e);
        }
        return false;
    }

    protected endDropPlaceHolderAnimation<T, U extends NtDraggableComponent<T>>(_draggable: U, _e: MouseEvent = null) {
        this.animateDropPlaceHolder = this.isDropPlaceHolderExpanded = false;
        // should not set lastDropHandler to null otherwise calling context will bug
    }

    protected containsDropLocation(): boolean {
        return this.dropPt.x < this.hostElWidth && this.dropPt.x >= 0 &&
            this.dropPt.y < this.hostElHeight && this.dropPt.y >= 0;
    }

    protected updateResponsiveDropAnimationCtx() {
        const draggables = this.draggableList();
        if(draggables.length == 0) {
            this.dropAnimationTop = this.dropAnimationAtIndex0TopMargin() + 'px';
            return;
        }
        const dropIndex = this.findDropIndex();
        const top1 = draggables.get(dropIndex).hostEl.nativeElement.offsetTop;
        if(dropIndex == 0) {
            this.dropAnimationTop = this.dropAnimationAtIndex0TopMargin() + 'px';
        } else {
            const top0 = draggables.get(dropIndex - 1).hostEl.nativeElement.offsetTop;
            const height0 = draggables.get(dropIndex - 1).hostEl.nativeElement.offsetHeight;
            const mid = (top0 + height0 + top1 - this.dropAnimationLineThickness()) / 2;
            this.dropAnimationTop = mid + 'px';
        }
    }

    // @ts-ignore
    protected updateDragHoverCallbacks(draggedEl: NtDraggableComponent, e: MouseEvent) {
        const hoveredEl = this.findHoveredEl();
        const sourceHandler = draggedEl.parentDnDHandler;
        const lastHovered = sourceHandler.lastHoveredDraggable;
        if(hoveredEl !== lastHovered && lastHovered) {// hover ended callbacks
            const targetHandler = lastHovered.parentDnDHandler;
            const dragHoverType = sourceHandler.keepAfterDnDUnder(targetHandler, e) ? NtDragHoverType.DUPLICATE : NtDragHoverType.MOVE;
            const evt = new NtDragHoverEvent(draggedEl, lastHovered, hoveredEl, dragHoverType, e);
            lastHovered.dragHoverEnded(evt);
            targetHandler.targetHandlerDragHoverEnded(evt);
            sourceHandler.sourceHandlerDragHoverEnded(evt);
        }
        if(hoveredEl !== lastHovered && hoveredEl) {// hover started callbacks
            const targetHandler = this;
            const dragHoverType = sourceHandler.keepAfterDnDUnder(targetHandler, e) ?
                NtDragHoverType.DUPLICATE : NtDragHoverType.MOVE;
            const evt = new NtDragHoverEvent(draggedEl, lastHovered, hoveredEl, dragHoverType, e);
            targetHandler.targetHandlerDragHoverStarted(evt);
            sourceHandler.sourceHandlerDragHoverStarted(evt);
            hoveredEl.dragHoverStarted(evt);
        }
        sourceHandler.lastHoveredDraggable = hoveredEl;
    }

    private findDropIndex(): number {
        const self = this;
        // this.cmpDraggableToDropPt() returns either -1 or 1 (never 0 on purpose) => binary search call will return shifted negative insert position
        const dropIndex = -NtUtils.binarySearch(this.draggableList(), this.actualDropPt,
            // @ts-ignore
            (d: NtDraggableComponent, pt: NtPoint) => {
                return self.cmpDraggableToDropPt(d, pt);
            }) - 1;
        return dropIndex;
    }

    // @ts-ignore
    private findHoveredEl(): NtDraggableComponent {
        if(this.lastHoveredDraggable &&
            this.cmpHoveredDraggableToDropPt(this.lastHoveredDraggable, this.actualDropPt) === 0) {
            return this.lastHoveredDraggable;
        }
        const self = this;
        const draggables = this.draggableList();
        const hoveredIndex = NtUtils.binarySearch(draggables, this.actualDropPt,
            // @ts-ignore
            (d: NtDraggableComponent, pt: NtPoint) => {
                return self.cmpHoveredDraggableToDropPt(d, pt);
            });
        return hoveredIndex < 0 ? null : draggables.get(hoveredIndex);
    }

    // @ts-ignore
    private cmpDraggableToDropPt(draggable: NtDraggableComponent, dropPt: NtPoint): number {
        const top = draggable.hostEl.nativeElement.offsetTop;
        const height = draggable.hostEl.nativeElement.offsetHeight;
        return (top + height / 2) <= dropPt.y ? -1 : 1;
    }

    // @ts-ignore
    private cmpHoveredDraggableToDropPt(draggable: NtDraggableComponent, dropPt: NtPoint): number {
        const top = draggable.hostEl.nativeElement.offsetTop;
        const height = draggable.hostEl.nativeElement.offsetHeight;
        return dropPt.y < top ? 1 : ((dropPt.y > top + height) ? -1 : 0);
    }


    public initDraggableSimulationInRootHandler<T, U extends NtDraggableComponent<T>>(draggable: U, e: MouseEvent) {
        if(this.parentDnDHandler) {// not root handler
            this.parentDnDHandler.initDraggableSimulationInRootHandler(draggable, e);
            return;
        }
        //root element => update draggable coordinates
        this.currentDraggable = draggable;
        NtDomUtils.convertElementCoordinates(draggable.hostEl, this.hostEl, this.draggablePt0);
        this.updateDraggableCoordinates(draggable);
    }

    public dragEventMoved<T, U extends NtDraggableComponent<T>>(draggable: U, e: MouseEvent) {
        this.updateDraggableSimulationInRootHandler(draggable, e);
        if(this.lastDropHandler
            && this.lastDropHandler.refreshDropResponsiveAnimation(draggable, e)) {
            // lastDropHandler remains the same
            return;
        }
        if(this.lastDropHandler !== this &&
            this.initResponsiveDropAnimation(draggable, e)) {
            // initResponsiveDropAnimation() will update lastDropHandler
            return;
        }
        this.initExternalDropHandlerAnimation(draggable, e);
        // initExternalDropHandlerAnimation() will update lastDropHandler
    }

    public dragEventEnded<T, U extends NtDraggableComponent<T>>(draggable: U, e: MouseEvent) {
        this.endDragSimulation(draggable, e);
        if(!this.lastDropHandler) {
            return;
        }
        this.lastDropHandler.endDropPlaceHolderAnimation(draggable, e);
        const keepAfterDnDUnder = draggable.keepAfterDnDUnder(this, e);
        this.endHoveredCallbacksIfApplicable(draggable, e, keepAfterDnDUnder);

        // dragEventEnded() can only be called by the end draggable
        // => under dragEventEnded() draggable is always a direct child of this handler
        let isDraggableDirectChild = (this.lastDropHandler === this);

        const dragIndex = draggable.indexUnderParentHandler();
        const dropIndex = this.lastDropHandler.findDropIndex();
        if(isDraggableDirectChild && dragIndex === dropIndex && !keepAfterDnDUnder) {
            // Dnd event under the same handler without duplication = reorder event with no drag index change
            // => nothing to do cancel dnd event
            this.lastDropHandler = null;
            return;
        }
        this.lastDropHandler.mouseTriggeredElementDropped(draggable, dragIndex, dropIndex, isDraggableDirectChild,
            e, keepAfterDnDUnder);
    }

    private mouseTriggeredDnDActionType(isDraggableDirectChild: boolean, duplicate: boolean) {
        if(duplicate) {
            return isDraggableDirectChild ? NtDndActionType.DUPLICATE_SAME_HANDLER : NtDndActionType.DUPLICATE_ACROSS_HANDLERS;
        } else {
            return isDraggableDirectChild ? NtDndActionType.REORDER_SAME_HANDLER : NtDndActionType.MOVE_ACROSS_HANDLERS;
        }
    }

    private mouseTriggeredElementDropped<U, V extends NtDraggableComponent<U>>(draggable: V,
                                                                               dragIndex: number,
                                                                               dropIndex: number,
                                                                               e: MouseEvent,
                                                                               // whether draggable is a direct child of this dropHandler
                                                                               isDraggableDirectChild: boolean,
                                                                               duplicate: boolean) {
        const self = this;
        const removalStrategy = NtDndRemovalStrategy.KEEP_CUSTOM_AND_BUBBLE_REMOVAL_CUSTOM;
        const dndTriggerTag = NtDndTriggerTag.MOUSE_USER_INTERACTION;
        const dndType = this.mouseTriggeredDnDActionType(isDraggableDirectChild, duplicate);
        this.dndCtx = new NtDndContext(draggable, null, dragIndex, dropIndex, isDraggableDirectChild,
            dndTriggerTag, dndType, e, removalStrategy, -1);
        let removalBubbleEndDepth = -1;
        if(!duplicate && !isDraggableDirectChild) {
            removalBubbleEndDepth = this.draggableRemovalBubbleDeepestAncestor(draggable, this.dndCtx).draggableDepth;
            this.dndCtx.removalBubbleEndDepth = removalBubbleEndDepth;
        }
        const dragHandler = draggable.parentDnDHandler;
        if(duplicate || !isDraggableDirectChild) {
            dragHandler.dragHandlerBeforeNewElementDropped(this.dndCtx);
            this.dropHandlerBeforeNewElementDropped(this.dndCtx);
            const insertObs = this.insertNewDraggableFromDroppedEl(draggable, dropIndex, duplicate);
            if(insertObs) {
                insertObs.subscribe(droppedEl => {
                    if(isDraggableDirectChild) { // duplicate under same handler
                        // ui changes may change draggable reference => refresh draggable
                        const newDragIndex = dropIndex <= dragIndex ? (dragIndex + 1) : dragIndex;
                        self.dndCtx.draggable = dragHandler.draggableList().get(newDragIndex);
                        self.dndCtx.dragIndex = newDragIndex;
                    }

                    self.dndCtx.droppedEl = droppedEl;
                    self.elementDropped2(draggable, droppedEl, dragIndex, dropIndex);
                });
            } else {
                self.elementDropped2(draggable, null, dragIndex, dropIndex);
            }
        } else {
            self.dndCtx.droppedEl = draggable;
            draggable.beforeDnDReorder(this.dndCtx);
            this.beforeDnDReorder(this.dndCtx);
            this.reorderDraggableList(dragIndex, dropIndex).subscribe((_r) => {
                // after reorder is reflected on UI all items are refreshed => update dndCtx
                self.dndCtx.draggable = self.dndCtx.droppedEl = dragHandler.draggableList().get(dropIndex);
                self.elementDropped2(draggable, <NtDraggableComponent<C>>(<any>draggable), dragIndex, dropIndex);
            });
        }

    }

    private elementDropped2<U, V extends NtDraggableComponent<U>>(draggable: V,
                                                                  droppedEl: NtDraggableComponent<C>,
                                                                  dragIndex: number,
                                                                  dropIndex: number) {
        const dragDelta = this.computeDragDelta(draggable);
        if(droppedEl) {
            droppedEl.activateDropPopInAnim = true;
        }
        const draggables = this.draggableList();
        let dropDelta = this.layoutDroppedEl(droppedEl, dropIndex);
        let draggableRemovalObs: Observable<number> = null;
        if (NtUtils.in(this.dndCtx.dndType, NtDndActionType.DUPLICATE_SAME_HANDLER, NtDndActionType.DUPLICATE_ACROSS_HANDLERS)
            || !draggable) {
            // draggedEl remains unchanged
            if(droppedEl) {
                this.dndEndedAdjustHierarchyLayout(dropDelta, dropIndex + 1, null, true, this.dndCtx);
            } // else neither drag nor drop effects affects layout (if data handling is needed it should be handled by user-custom implementation) => nothing to do
        } else if (this.dndCtx.dndType === NtDndActionType.REORDER_SAME_HANDLER) {// reorder event under same handler => dropEl cannot be null here
            const startIndex = dragIndex < dropIndex ? dragIndex : (dropIndex + 1);
            const endIndex = dragIndex > dropIndex ? dragIndex : dropIndex;
            const delta1 = dragIndex < dropIndex ? dragDelta : dropDelta;
            const delta2 = dragIndex > dropIndex ? dragDelta : dropDelta;
            this.dndEndedAdjustHierarchyLayout(delta1, startIndex, draggables.get(endIndex), false, this.dndCtx);
            this.dndEndedAdjustHierarchyLayout(delta1 + delta2, endIndex + 1, null, true, this.dndCtx);
        } else if (droppedEl) { // Dnd across different handlers / dragged element is to be moved (removed from original container)
            const intervalEnd = this.findClosestCommonAncestorEndIntervalChild(draggable, droppedEl);

            const startIndex2 = (intervalEnd[1] === draggable ? dragIndex : dropIndex) + 1;
            const startIndex1 = (intervalEnd[1] === draggable ? dropIndex : dragIndex) + 1;
            const startIndex3 = intervalEnd[0].indexUnderParentHandler() + 1;
            const delta2 = intervalEnd[1] === draggable ? dragDelta : dropDelta;
            const delta1 = intervalEnd[1] === draggable ? dropDelta : dragDelta;
            const handler1 = intervalEnd[1] === draggable ? this : draggable.parentDnDHandler; // this is the drop handler
            const handler2 = intervalEnd[1] === draggable ? draggable.parentDnDHandler : this; // this is the drop handler
            const commonAncestor = intervalEnd[0].parentDnDHandler;

            const delta1Deviation = handler1.dndEndedAdjustHierarchyLayout(delta1, startIndex1, intervalEnd[0],
                true, this.dndCtx);

            const delta2Deviation = handler2.dndEndedAdjustHierarchyLayout(delta2, startIndex2, intervalEnd[0],
                true, this.dndCtx);

            const delta = delta1Deviation + delta2Deviation;

            // Drag and Drop effect happened inside commonAncestor children
            // => at this point drag removal chain is necessarily met by a drop insertion
            // => starting common ancestor, it doesn't make sense to keep chaining dragged
            // element removal any further => pass null as last arg
            commonAncestor.dndEndedAdjustHierarchyLayout(delta, startIndex3, null,
                true, this.dndCtx);

            draggableRemovalObs = draggable.chainDraggableRemoval(this.dndCtx);
        } else {// drop effect is disabled by concrete implementation or call is function triggered
            const dragHandler = draggable.parentDnDHandler;
            dragHandler.dndEndedAdjustHierarchyLayout(dragDelta, dragIndex + 1, null,
                true, this.dndCtx);
            draggableRemovalObs = draggable.chainDraggableRemoval(this.dndCtx);
        }
        this.beforeDnDLayoutAnimationStart(draggableRemovalObs);
    }

    // @ts-ignore
    private computeDragDelta(draggable: NtDraggableComponent): number {
        if(!draggable ||
            this.dndCtx.dndType === NtDndActionType.DUPLICATE_ACROSS_HANDLERS ||
            this.dndCtx.dndType === NtDndActionType.DUPLICATE_SAME_HANDLER) {
            return 0;
        }
        const dragHandler = draggable.parentDnDHandler;
        let dragDelta = -draggable.hostEl.nativeElement.offsetHeight;
        if(dragHandler.draggableList().length > 1) {
            dragDelta -= dragHandler.draggablesMargin();
        }
        return dragDelta;
    }

    beforeDnDLayoutAnimationStart(draggableRemovalObs: Observable<number>) {
        const ctx = this.dndCtx;
        // draggable can be null in case of DnD reversal (see reverseDnDMoveAcrossHandlers())
        // where the original droppedEl, which becomes draggable in the reversal process, was
        // null (meaning original dropHandler uses custom drop handling)
        const dragHandler = ctx.draggable ? ctx.draggable.parentDnDHandler : null;
        if(ctx.dndType === NtDndActionType.REORDER_SAME_HANDLER) {
            // a reorder cannot have a null draggable => safe to use ctx.draggable
            ctx.draggable.afterDndReorderPreAnim(ctx);
            this.afterDndReorderPreAnim(ctx);
        } else {
            if(dragHandler) {
                dragHandler.dragHandlerAfterNewElementDroppedPreAnim(ctx);
            }
            this.dropHandlerAfterNewElementDroppedPreAnim(ctx);
        }
        for(let removalCtx of ctx.dndRemovalCtxs) {
            removalCtx.removedDraggable.afterDndRemovalPreAnimPreUIUpdate(removalCtx);
            removalCtx.removedDraggable.parentDnDHandler.afterChildDraggableRemovalPreAnimPreUIUpdate(removalCtx);
        }
        if(draggableRemovalObs) {
            draggableRemovalObs.subscribe(_i => {
                for(let removalCtx of ctx.dndRemovalCtxs) {
                    removalCtx.removedDraggable.afterDndRemovalPreAnimPostUIUpdate(removalCtx);
                    removalCtx.removedDraggable.parentDnDHandler.afterChildDraggableRemovalPreAnimPostUIUpdate(removalCtx);
                }
            });
        }
        if(!this.activateDnDLayoutAnimation()) {
            ctx.resetDndAnimations();
            this.dndCtx = null;
            if(dragHandler) {
                dragHandler.lastDropHandler = null;
            }
        }
    }


    // @ts-ignore
    private findClosestCommonAncestorEndIntervalChild(draggable1: NtDraggableComponent, draggable2: NtDraggableComponent): [NtDragDropHandlerComponent, NtDraggableComponent] {
        let handler1 = draggable1.parentDnDHandler;
        let handler2 = draggable2.parentDnDHandler;
        // @ts-ignore
        let child1: NtDragDropHandlerComponent = null;
        // @ts-ignore
        let child2: NtDragDropHandlerComponent = null;
        do {
            if(handler1.draggableDepth < handler2.draggableDepth) {
                child2 = handler2;
                handler2 = handler2.parentDnDHandler;
            } else if(handler1.draggableDepth > handler2.draggableDepth) {
                child1 = handler1;
                handler1 = handler1.parentDnDHandler;
            } else {
                child1 = handler1;
                handler1 = handler1.parentDnDHandler;
                child2 = handler2;
                handler2 = handler2.parentDnDHandler;
            }
        } while(handler1 !== handler2);
        const index1 = child1.indexUnderParentHandler();
        const index2 = child2.indexUnderParentHandler();
        return index1 < index2 ? [child2, draggable2] : [child1, draggable1];
    }

    private reorderDraggableList(dragIndex: number, dropIndex: number): Observable<any> {
        const draggables = this.draggableDataArray();
        const step = dragIndex < dropIndex ? 1 : -1;
        const dragged = draggables[dragIndex];
        for(let i = dragIndex; i != dropIndex; i += step) {
            draggables[i] = draggables[i + step];
        }
        draggables[dropIndex] = dragged;
        return this.draggableList().changes.pipe(first());
    }

    private layoutDroppedEl(droppedEl: NtDraggableComponent<C>, dropIndex: number): number {
        if(!droppedEl) {
            return 0;
        }
        const draggables = this.draggableList();
        if(dropIndex < draggables.length - 1) {
            droppedEl.y = draggables.get(dropIndex + 1).y;
        } else if(draggables.length === 1) { // dropped el is the only element in the list
            droppedEl.y = 0;
        } else {// droppedEl is the last element in the list with at least 1 element behind
            const beforeEl = draggables.get(dropIndex - 1);
            droppedEl.y = beforeEl.y + beforeEl.hostEl.nativeElement.offsetHeight + this.draggablesMargin();
        }
        let dropDelta = droppedEl.hostEl.nativeElement.offsetHeight;
        if(draggables.length > 1) {
            dropDelta += this.draggablesMargin();
        }
        return dropDelta;
    }

    afterDnDAnimationDone() {
        const ctx = this.dndCtx;
        const dragHandler = ctx.draggable ? ctx.draggable.parentDnDHandler : null;
        ctx.resetDndAnimations();

        if(ctx.dndType === NtDndActionType.REORDER_SAME_HANDLER) {
            // ctx.draggable cannot be null under a re-order event
            ctx.draggable.afterDndReorderPostAnim(ctx);
            this.afterDndReorderPostAnim(ctx);
        } else {
            if(dragHandler) {
                dragHandler.dragHandlerAfterNewElementDroppedPostAnim(ctx);
            }
            this.dropHandlerAfterNewElementDroppedPostAnim(ctx);
        }
        for(let removalCtx of ctx.dndRemovalCtxs) {
            removalCtx.removedDraggable.afterDndRemovalPostAnim(removalCtx);
            removalCtx.removedDraggable.parentDnDHandler.afterChildDraggableRemovalPostAnim(removalCtx);
        }
        this.dndCtx = null;
        if(dragHandler) {
            dragHandler.lastDropHandler = null;
        }
    }

    protected endDragSimulation<T, U extends NtDraggableComponent<T>>(draggable: U, e: MouseEvent) {
        if(this.parentDnDHandler) {// not root handler
            this.parentDnDHandler.endDragSimulation(draggable, e);
            return;
        }
        //root element
        this.currentDraggable = null;
    }

    public dragEventCancelled<T, U extends NtDraggableComponent<T>>(draggable: U) {
        this.currentDraggable = null;
        this.endDropPlaceHolderAnimation(draggable);
        const isDuplicateDraggable = draggable.keepAfterDnDUnder(this, null);
        this.endHoveredCallbacksIfApplicable(draggable, null, isDuplicateDraggable);
        this.lastDropHandler = null;
    }

    private endHoveredCallbacksIfApplicable<T, U extends NtDraggableComponent<T>>(draggable: U,
                                                                                  e: MouseEvent,
                                                                                  duplicate: boolean) {
        if(this.lastHoveredDraggable) {
            const lastHovered = this.lastHoveredDraggable;
            const sourceHandler = this;
            const targetHandler = lastHovered.parentDnDHandler;
            const dragHoverType = duplicate ? NtDragHoverType.DUPLICATE : NtDragHoverType.MOVE;
            const evt = new NtDragHoverEvent(draggable, lastHovered, lastHovered, dragHoverType, e);
            lastHovered.dragHoverEnded(evt);
            targetHandler.targetHandlerDragHoverEnded(evt);
            sourceHandler.sourceHandlerDragHoverEnded(evt);
            this.lastHoveredDraggable = null;
        }
    }

    private updateDraggableSimulationInRootHandler<T, U extends NtDraggableComponent<T>>(draggable: U, e: MouseEvent) {
        if(this.parentDnDHandler) {// not root handler
            this.parentDnDHandler.updateDraggableSimulationInRootHandler(draggable, e);
            return;
        }
        //root element => update draggable coordinates
        if(this.currentDraggable !== draggable) {
            this.currentDraggable = draggable;
            NtDomUtils.convertElementCoordinates(draggable.hostEl, this.hostEl, this.draggablePt0);
        }
        this.updateDraggableCoordinates(draggable);
    }

    protected currentDraggedData(type: string): any {
        if(!this.currentDraggable || type !== this.currentDraggable.type) {
            return null;
        }
        return this.currentDraggable.data;

    }



    updateDraggableCoordinates<T, U extends NtDraggableComponent<T>>(draggable: U) {
        this.draggablePt.x = this.draggablePt0.x + draggable.dragDx;
        this.draggablePt.y = this.draggablePt0.y + draggable.dragDy;
    }

    updateDropCoordinates<T, U extends NtDraggableComponent<T>>(draggable: U) {
        this.dropPt.x = this.dropPt0.x + draggable.dragDx;
        this.dropPt.y = this.dropPt0.y + draggable.dragDy;
        this.actualDropPt.x = this.dropPt.x - this.scrollableDraggablesContainerOffset.x;
        this.actualDropPt.y = this.dropPt.y - this.scrollableDraggablesContainerOffset.y;
    }

    dropPlaceHolderAnimationLoop() {
        const self = this;
        this.tick_then(() => {
            self.isDropPlaceHolderExpanded = false;
            self.tick_then(() => {
                self.isDropPlaceHolderExpanded = true;
            })
        });
    }

    protected isDropPossibleForDraggable<T, U extends NtDraggableComponent<T>>(draggable: U): boolean {
        return this.isDropSupportedForDraggable(draggable);
    }

    // @ts-ignore
    private draggableRemovalBubbleDeepestAncestor(removedDraggable: NtDraggableComponent, dndCtx: NtDndContext): NtDraggableComponent {
        let child = removedDraggable;
        let ancestor = removedDraggable.parentDnDHandler;
        while(ancestor != null) {
            if(!ancestor.draggableList() ||
                ancestor.draggableList().length > 1 ||
                !ancestor.shouldDismissIfEmptyAfterDnD(dndCtx)) {
                return child;
            }
            child = ancestor;
            ancestor = ancestor.parentDnDHandler;
        }
        return child;
    }

    protected dndEndedAdjustHierarchyLayout(delta: number,
                                            intervalStartIndex: number,
                                            intervalEnd: any,
                                            bubble: boolean,
                                            dndCtx: NtDndContext): number {
        if(delta === 0 || !this.draggableList()) {
            // draggables inside parent do not affect external layout
            return 0;
        }
        const draggables = this.draggableList();
        if(dndCtx.removalBubbleEndDepth >= 0 &&
            this.draggableDepth >= dndCtx.removalBubbleEndDepth) {
            delta = -this.hostEl.nativeElement.offsetHeight;
            let i = this.indexUnderParentHandler();
            if(i < 0) {
                // no link between handler & draggableList => removal bubble is not relevant
                // + handler is unaffected by what happens to its children
                return 0;
            }
            if (this.parentDnDHandler.draggableList().length > 1) {
                delta -= this.parentDnDHandler.draggablesMargin();
            }
            if(this === intervalEnd || !bubble) {
                return delta;
            }
            return this.parentDnDHandler.dndEndedAdjustHierarchyLayout(delta, i + 1, intervalEnd, bubble, dndCtx);
        }
        let i = intervalStartIndex;

        // @ts-ignore
        let draggable: NtDraggableComponent = null;
        while (i < draggables.length) {
            draggable = draggables.get(i ++);
            draggable.dndEndAnimPositionDelta = delta;
        } while (draggable !== intervalEnd);

        if(intervalStartIndex < draggables.length) {
            dndCtx.dndRelayoutCtxs.push(new NtDndRelayoutContext(this, intervalStartIndex, i - 1));
        }

        if(draggable === intervalEnd && draggable) {
            return delta;
        }
        let innerSize = 0;
        if(draggables.length > 0) {
            innerSize = this.draggablesDirectContainer().nativeElement.offsetHeight + delta;
        }
        delta = this.requestNewInnerLength(innerSize);
        this.dndEndAnimSizeDelta = delta;
        if(this === intervalEnd || !bubble || !this.parentDnDHandler) {
            return delta;
        }
        i = this.indexUnderParentHandler();
        if(i < 0) {// means parentHandler.draggableList() is null => current handler layout is independent of parent's
            return 0;
        }
        // removalBubbleEndDepth is no longer relevant after getting past removalBubbleEndDepth check (see above)
        // => -1 as last argument to prevent any further removal bubbling (not to confuse with re-layout bubbling)
        return this.parentDnDHandler.dndEndedAdjustHierarchyLayout(delta, i, intervalEnd, bubble, dndCtx, -1);
    }

    public layoutDraggableChildren() {
        const draggables = this.draggableList();
        if(!draggables || draggables.length === 0) {
            return;
        }
        let x = 0, y = 0, vMargin = this.draggablesMargin();
        for(let draggable of draggables) {
            draggable.x = x;
            draggable.y = y;
            y += (draggable.hostEl.nativeElement.offsetHeight + vMargin);
        }
    }

    // @ts-ignore
    private insertNewDraggableFromDroppedEl(draggable: NtDraggableComponent, insertIndex: number, duplicate: boolean): Observable<NtDraggableComponent<C>> {
        let dataToInsert: C;
        if(duplicate) {
            dataToInsert = this.createDataFromDuplicatedDroppedEl(draggable);
        } else {
            dataToInsert = this.adaptMovedDataFromDroppedEl(draggable);
        }
        if(dataToInsert == null) {
            return null;
        }
        return this.insertNewDraggableFromData(dataToInsert, insertIndex);
    }

    private insertNewDraggableFromData(data: C, insertIndex: number): Observable<NtDraggableComponent<C>> {
        this.draggableDataArray().splice(insertIndex, 0, data);
        const draggables = this.draggableList();
        return draggables.changes.pipe(first(), map(_e => draggables.get(insertIndex)));
    }





    // ################################################################################
    // ######################### To Implement by Sub-classes ##########################
    // ################################################################################

    /**
     * Implementation cannot return null if the current handler is expected to handle DnD.
     * => Use an empty array if draggableList() is empty
     */
    // @ts-ignore
    public abstract draggableList(): QueryList<NtDraggableComponent<C>>;

    public abstract draggableDataArray(): Array<C>;

    protected abstract isDropSupportedForDraggable<T, U extends NtDraggableComponent<T>>(draggable: U): boolean;

    // @ts-ignore
    protected abstract allDropHandlers(): Array<QueryList<NtDragDropHandlerComponent>>;

    protected abstract draggablesMargin(): number;

    // @ts-ignore
    protected abstract shouldDismissIfEmptyAfterDnD(dndCtx: NtDndContext): boolean;

    public abstract draggablesDirectContainer(): ElementRef;

    public abstract activateDnDLayoutAnimation(): boolean;

    /**
     * newInnerLength is either width or height depending on draggables orientation (vertical/horizontal).
     * => at this stage only vertical orientation is supported, to evolve in the future to support horizontal as well
     * newInnerLength covers only draggablesDirectContainer(), it does not include any margins/paddings applied to the conatiner handler's css
     * returns amount of change (delta) possible to apply to the current container's height/width (container can be a scrollable with min/max length).
     * delta can be positive, negative, or if the size change is completely rejected => 0
     *
     * In all cases newInnerLength should be applied to draggablesDirectContainer() (even if hostEl (the container)
     * has a min/max size constraint and handles draggablesDirectContainer() as a scrollable).
     *
     * Implementation may refer hostEl as draggablesDirectContainer() if there's no size restriction / no scrolling
     * @param newInnerLength
     */
    protected abstract requestNewInnerLength(newInnerLength: number): number;

    /**
     * called by generic DnD mechanism for DnD move across handler events to adapt the original data to its new handler parent
     * => does not apply to moving within the same handle (re-ordering), in which case the re-arranged data is kept unmodified
     * return null if concrete implementation does not want to delegate drop data handling &
     * layout effect to generic dnd behavior
     * otherwise return the desired adapted value which will get auto-inserted into draggableDataArray()
     * followed by an auto-ui draggableList() update, and auto re-layout animation.
     * @param draggable
     */
    // @ts-ignore
    protected abstract adaptMovedDataFromDroppedEl(draggable: NtDraggableComponent): C;

    /**
     * called by generic DnD mechanism for DnD duplicate events to duplicate original data (applies to both same-handler or cross-handler duplication)
     * return null if concrete implementation does not want to delegate drop data handling &
     * layout effect to generic dnd behavior
     * otherwise return the desired adapted value which will get auto-inserted into draggableDataArray()
     * followed by an auto-ui draggableList() update, and auto re-layout animation.
     * @param draggable
     */
    // @ts-ignore
    protected abstract createDataFromDuplicatedDroppedEl(draggable: NtDraggableComponent): C;

    /**
     * to implement by root handler => called by DnD generic mechanism when reversing removal or DnD move across handler actions
     * => see reverseDnDMoveAcrossHandlers() and reverseDraggableRemoval()
     * non root handlers may return rootHandler().resolveHandler(handlerKey) as implementation
     * @param handlerKey
     */
    // @ts-ignore
    public abstract resolveHandler(handlerKey: any): NtDragDropHandlerComponent;

    /**
     * To keep history of interaction with DnD components, user should compress removed/moved-across-handler data as possible to reduce
     * memory footprint while allowing reversal.
     * => data compression is not invoked by DnD generic mechanism => custom implementation may use DnD callbacks to compress removed data
     * where needed
     * => DnD mechanism will auto invoke restoreData() when reverseDnDMoveAcrossHandlers() and reverseDraggableRemoval() are triggered
     * => Each handler should be able to restore its direct children compressed data (and their sub-sequent descendents where applicable)
     * @param restorableData
     */
    // @ts-ignore
    public abstract restoreData(restorableData: any): C;

    /**
     * Top margin for the drop 'blink' animation in real time as user moves the cursor
     */
    protected dropAnimationAtIndex0TopMargin(): number {
        return 2;
    }

    /**
     * Line thickness for the drop 'blink' animation in real time as user moves the cursor
     */
    protected dropAnimationLineThickness(): number {
        return 2;
    }

    // ################################################################################
    // ######################### End Custom Implementations ##########################
    // ################################################################################





    // ################################################################################
    // ########################### Drag Handler Callbacks #############################
    // ################################################################################

    public dragHandlerBeforeNewElementDropped(_dndCtx: NtDndContext) {
        // to implement by sub-classes if needed
    }

    public dragHandlerAfterNewElementDroppedPostAnim(_dndCtx: NtDndContext) {
        // to implement by sub-classes if needed
    }

    public dragHandlerAfterNewElementDroppedPreAnim(_dndCtx: NtDndContext) {
        // to implement by sub-classes if needed
    }

    public dropHandlerBeforeNewElementDropped(_dndCtx: NtDndContext) {
        // to implement by sub-classes if needed
    }

    public dropHandlerAfterNewElementDroppedPostAnim(_dndCtx: NtDndContext) {
        // to implement by sub-classes if needed
    }

    public dropHandlerAfterNewElementDroppedPreAnim(_dndCtx: NtDndContext) {
        // to implement by sub-classes if needed
    }

    public beforeChildDraggableRemoval(_dndRemovalCtx: NtDndRemovalContext) {
        // to implement by sub-classes if needed
    }

    public afterChildDraggableRemovalPreAnimPreUIUpdate(_dndRemovalCtx: NtDndRemovalContext) {
        // to implement by sub-classes if needed
    }

    public afterChildDraggableRemovalPreAnimPostUIUpdate(_dndRemovalCtx: NtDndRemovalContext) {
        // to implement by sub-classes if needed
    }

    public afterChildDraggableRemovalPostAnim(_dndRemovalCtx: NtDndRemovalContext) {
        // to implement by sub-classes if needed
    }


    public sourceHandlerDragHoverStarted(_e: NtDragHoverEvent) {
        // to implement by sub-classes if needed
    }

    public sourceHandlerDragHoverEnded(_e: NtDragHoverEvent) {
        // to implement by sub-classes if needed
    }

    public targetHandlerDragHoverStarted(_e: NtDragHoverEvent) {
        // to implement by sub-classes if needed
    }

    public targetHandlerDragHoverEnded(_e: NtDragHoverEvent) {
        // to implement by sub-classes if needed
    }

    // ################################################################################
    // ######################### End Drag Handler Callbacks ###########################
    // ################################################################################
}



