import {Component, ElementRef, Input} from '@angular/core';
import {PgnSelectionEvent, PgnSelectionMode} from './pgn-selection-event';
import {PgnCoreCanvasComponent} from '../../core-canvas/pgn-core-canvas.component';
import {PgnLayerRenderer} from '../../core-canvas/layer/pgn-layer-renderer';
import {Subject} from 'rxjs';
import {PgnGraphRenderer} from '../../core-canvas/graph/pgn-graph-renderer';

@Component({
  selector: 'pgn-preview',
  templateUrl: './pgn-preview.component.html',
  styleUrls: ['./pgn-preview.component.scss']
})
export class PgnPreviewComponent extends PgnCoreCanvasComponent {

    private selectedLayers: PgnLayerRenderer[];
    @Input() selectionSubject: Subject<PgnSelectionEvent>;
    private selectionMode = PgnSelectionMode.UNDEFINED;

    constructor (protected hostEl:ElementRef) {
        super(hostEl);
    }


    public graphRendererInitialized(graphRenderer: PgnGraphRenderer) {
        graphRenderer.setPreviewCvs(this.canvasRef.nativeElement);
        super.graphRendererInitialized(graphRenderer);
    }

    onMouseOut(e: MouseEvent) {
        super.onMouseOut(e);
        if(this.isMousePressed) {
            this.selectionEnded();
        }
        this.repaint();
    }

    onMouseMove(e: MouseEvent) {
        if(!super.processHoverEvent(e)) {
            this.mouseDragged(e);
        }
    }

    onMousePress(e: MouseEvent) {
        super.onMousePress(e);
        this.selectedLayers = [];
        this.mouseDragged(e);
    }

    onMouseRelease(e: MouseEvent) {
        const wasMousePressed = this.isMousePressed;
        super.onMouseRelease(e);
        if(wasMousePressed) {
            this.selectionEnded();
        }
    }

    private mouseDragged(e: MouseEvent) {
        const x = this.getNormalizedX(e);
        const y = this.getNormalizedY(e);
        const layerIndex = this.getLayerIndexAt(x, y);
        if(layerIndex === -1) {
            return;
        }
        const layerRenderer = this.graphRenderer.layers[layerIndex];
        if(this.selectionMode === PgnSelectionMode.UNDEFINED) {
            this.selectionMode = layerRenderer.isSelected ? PgnSelectionMode.REMOVE_FROM_SELECTION : PgnSelectionMode.ADD_TO_SELECTION;
        }
        if(layerRenderer.isSelected === (this.selectionMode === PgnSelectionMode.ADD_TO_SELECTION)) {
            return;
        }
        layerRenderer.isSelected = !layerRenderer.isSelected;
        this.repaint();
        // there's no analytics logging abuse here , we won't reach here every time the mouse cursor changes its position
        // this piece of code is only reached when there's a change in selection state
        // Analytics.logGA(PolygonAnalytics.CATEGORY, PolygonAnalytics.PREVIEW_ACTION, null,
        //     layerRenderer.isSelected ? PolygonAnalytics.PREVIEW_SELECT : PolygonAnalytics.PREVIEW_DESELECT);
    }

    private selectionEnded() {
        if(this.selectedLayers.length > 0) {
            this.selectionSubject.next(new PgnSelectionEvent(this.selectedLayers, this.selectionMode, this));
        }
        this.selectedLayers = null;
        this.selectionMode = PgnSelectionMode.UNDEFINED;
    }

}
