import {PgnShapeType} from './pgn-shape-type.enum';
import {PgnShapeRenderMode} from './pgn-shape-render-mode.enum';
import {PgnLayerRenderer} from '../layer/pgn-layer-renderer';


export abstract class PgnShape {
    // not necessarily loaded from json
    id: number;
    //_______________________________


    shapeType: PgnShapeType;
    name: string;

    renderMode: PgnShapeRenderMode;

    // fill configurations are at shape level (as opposed to stroke configs which are at layer level) because:
    // 1- from a semantic perspective the decomposition of layer into sub-shapes is meant to create one complex (composed)
    // shape which should be looked at as one whole => it is more natural for a complex shape to have one (as apposed to multiple)
    // stroke... if for nothing then from a taste perspective
    // 2- keeping different sub-shape fill colors under a composed shape (i.e. a layer) may have uses such as different opacity
    // or darkness levels per sub-area
    // => what may be looked at as a the double standard for defining stroke configs at layer level vs fills at shape level
    // is an opinionated decision made to best combine flexibility and coherence for end users
    // => strokes and fills are not the same thing, designing their configurations at 2 different levels should not be perceived odd
    fill: string;
    hoverFill: string;
    fillOpacity: number;
    hFillOpacity: number;

    // 'enabled' property for shapes is not the same as 'visible' in layers.
    // 'enabled' for shapes is taken into consideration at pre-processing phase to add-up and/or subtract into a single layer
    // multiple shapes per layer is meant to allow for the composition of complex shapes (i.e. layers) which are looked at as a single/whole
    // unit when drawing => 'enabled' property at shape level is not considered when drawing
    // in contrast 'visible' property at Layer level has no effect whatsoever at pre-processing (clipping is not affected), it is only
    // taken into consideration when drawing/interacting with pointing device
    enabled = true;


    // ---------------------- working context (not retrieved from server) -------------------------------------

    isEditing = false;

    layerRenderer: PgnLayerRenderer;

    rawOpaqueFillCvs: HTMLCanvasElement;
    rawOpaqueFillCtx: CanvasRenderingContext2D;

    rawOpaqueStrokeCvs: HTMLCanvasElement;
    rawOpaqueStrokeCtx: CanvasRenderingContext2D;
    // --------------------------------------------------------------------------------------------------------

    constructor() {
    }

    public getFillOpacity(isEditMode: boolean): number {
        if (this.layerRenderer.isSelected || (isEditMode && !this.layerRenderer.isHovered)) {
            return this.fillOpacity;
        }

        if (this.layerRenderer.isHovered) {
            return this.hFillOpacity;
        }
        return 0;
    }

    public abstract applyDefaults();

    public abstract initGraphics(document: Document);

    public abstract setEditMode(isEditMode: boolean);

    public abstract opaqueFillCvs(): HTMLCanvasElement;

    public opaqueFillCtx(): CanvasRenderingContext2D {
        if(this.rawOpaqueFillCtx == null && this.opaqueFillCvs() != null) {
            this.rawOpaqueFillCtx = this.opaqueFillCvs().getContext('2d');
        }
        return this.rawOpaqueFillCtx;
    }

    public abstract opaqueStrokeCvs(): HTMLCanvasElement;

    public abstract clone(): PgnShape;

    public cloneInto(clone: PgnShape) {
        clone.shapeType = this.shapeType;
        clone.renderMode = this.renderMode;
        clone.fill = this.fill;
        clone.hoverFill = this.hoverFill;
        clone.fillOpacity = this.fillOpacity;
        clone.hFillOpacity = this.hFillOpacity;
        clone.name = this.name + '-copy';
        clone.enabled = this.enabled;
        clone.isEditing = this.isEditing;
        clone.layerRenderer = this.layerRenderer;

        if(this.rawOpaqueFillCvs != null) {
            clone.initializedRawOpaqueFillCtx().drawImage(this.rawOpaqueFillCvs, 0, 0);
        }

        if(this.rawOpaqueStrokeCvs != null) {
            clone.initializedRawOpaqueStrokeCtx().drawImage(this.rawOpaqueStrokeCvs, 0, 0);
        }
    }

    public opaqueStrokeCtx(): CanvasRenderingContext2D {
        if(this.opaqueStrokeCvs() == null) {
            return null;
        }
        if(this.rawOpaqueStrokeCtx == null) {
            this.rawOpaqueStrokeCtx = this.opaqueStrokeCvs().getContext('2d');
        }
        return this.rawOpaqueStrokeCtx;
    }

    public clearEditModeGraphicsCache() {
        this.rawOpaqueFillCvs = this.rawOpaqueStrokeCvs = null;
        this.rawOpaqueFillCtx = this.rawOpaqueStrokeCtx = null;
    }

    public isFillAlphaRenderingResuableInResponsiveBitmap(opacity: number) {
        return this.renderMode == PgnShapeRenderMode.SUBTRACT || this.opaqueFillCvs() == null || opacity > 0;
    }

    public isEmpty(): boolean {
        return this.opaqueFillCvs() == null && this.opaqueStrokeCvs() == null;
    }

    public hasGraphEffect(): boolean {
        return this.enabled && !this.isEditing && !this.isEmpty();
    }

    protected initializedRawOpaqueFillCvs(): HTMLCanvasElement {
        if(this.rawOpaqueFillCvs == null) {
            this.rawOpaqueFillCvs = this.createCanvas();
        }
        return this.rawOpaqueFillCvs;
    }

    protected initializedRawOpaqueFillCtx(): CanvasRenderingContext2D {
        if(this.rawOpaqueFillCtx == null) {
            this.rawOpaqueFillCtx = this.initializedRawOpaqueFillCvs().getContext('2d');
        }
        return this.rawOpaqueFillCtx;
    }

    protected initializedRawOpaqueStrokeCvs(): HTMLCanvasElement {
        if(this.rawOpaqueStrokeCvs == null) {
            this.rawOpaqueStrokeCvs = this.createCanvas();
        }
        return this.rawOpaqueStrokeCvs;
    }

    protected initializedRawOpaqueStrokeCtx(): CanvasRenderingContext2D {
        if(this.rawOpaqueStrokeCtx == null) {
            this.rawOpaqueStrokeCtx = this.initializedRawOpaqueStrokeCvs().getContext('2d');
        }
        return this.rawOpaqueStrokeCtx;
    }


    // ########################################### Edit Actions #####################################

    // should be called when color or opacity changes => even when opacity = 0, add/subtract and clipping behavior is maintained
    public textureChanged() {
        if(this.enabled && !this.isEditing && this.renderMode == PgnShapeRenderMode.ADD) {
            this.layerRenderer.subshapesTextureChanged();
        }
    }

    public formChanged() {
        if(this.enabled && !this.isEditing) {
            this.layerRenderer.subshapesFormChanged();
        }
    }

    public renderModeChanged() {
        if(this.enabled && !this.isEditing && !this.isEmpty()) {
            this.layerRenderer.subshapesFormChanged();
        }
    }

    public enabledStateChanged() {
        if(!this.isEditing && !this.isEmpty()) {
            this.layerRenderer.subshapesFormChanged();
        }
    }

    public editModeChanged() {
        if(this.enabled && !this.isEmpty()) {
            this.layerRenderer.subshapesFormChanged();
        }
    }

    // ######################################## End Edit Actions ####################################

    public createCanvas(): HTMLCanvasElement {
        return this.layerRenderer.createCanvas();
    }
}
