import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavbarComponent } from './navbar/navbar.component';
import { ContentContainerComponent } from './content-container/content-container.component';
import { LifeguardComponent } from './content-container/lifeguard/lifeguard.component';
import { PageComponent } from './content-container/page-component/page.component';
import { AnsiHighlighterComponent } from './content-container/ansi-highlighter/ansi-highlighter.component';
import {LayoutModule} from '@angular/cdk/layout';
import { MathGlideComponent } from './content-container/math-glide/math-glide.component';
import { PolygonComponent } from './content-container/polygon/polygon.component';
import { PgnCoreCanvasComponent } from './content-container/polygon/core/core-canvas/pgn-core-canvas.component';
import { PgnEditContainerComponent } from './content-container/polygon/core/edit/edit-container/pgn-edit-container.component';
import { PgnMouseEditComponent } from './content-container/polygon/core/edit/mouse-edit/pgn-mouse-edit.component';
import { PgnPreviewComponent } from './content-container/polygon/core/highlight/mouse-highlight/pgn-preview.component';
import {PgnGraphDataService} from './content-container/polygon/core/core-canvas/graph/pgn-graph-data.service';
import {HttpClientModule} from '@angular/common/http';
import { LayerPanelComponent } from './content-container/polygon/core/core-canvas/layer/layer-panel/layer-panel.component';
import { PgnGraphEditToolbarComponent } from './content-container/polygon/core/edit/graph-edit-toolbar/pgn-graph-edit-toolbar.component';
import { ToolbarBtnComponent } from './utils/toolbar-btn/toolbar-btn.component';
import { PgnStrokeEditPopupComponent } from './content-container/polygon/core/edit/pgn-stroke-edit-popup/pgn-stroke-edit-popup.component';
import { PopupWrapperComponent } from './utils/popup-wrapper/popup-wrapper.component';
import { NtColorPickerComponent } from './utils/nt-color-picker/nt-color-picker.component';
import { NtInputComponent } from './utils/nt-input/nt-input.component';
import { PgnPaddingEditComponent } from './content-container/polygon/core/edit/pgn-padding-edit/pgn-padding-edit.component';
import { PgnEditPanelComponent } from './content-container/polygon/core/edit/pgn-edit-panel/pgn-edit-panel.component';
import { PgnLayerManagerPanelComponent } from './content-container/polygon/core/edit/pgn-layer-manager-panel/pgn-layer-manager-panel.component';
import { PgnLayerEditComponent } from './content-container/polygon/core/edit/pgn-layer-edit/pgn-layer-edit.component';
import { PgnShapeEditComponent } from './content-container/polygon/core/edit/pgn-shape-edit/pgn-shape-edit.component';
import { NtEmailPromptComponent } from './utils/nt-email-prompt/nt-email-prompt.component';
import { NtHomeComponent } from './content-container/nt-home/nt-home.component';
import { AnsiHighlighterEulaComponent } from './content-container/ansi-highlighter/ansi-highlighter-eula/ansi-highlighter-eula.component';
import { AnsiHighlighterLicenseRegisterComponent } from './content-container/ansi-highlighter/ansi-highlighter-license-register/ansi-highlighter-license-register.component';
import { NtTabsComponent } from './utils/nt-tabs/nt-tabs.component';
import { NtTabComponent } from './utils/nt-tab/nt-tab.component';

@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent,
        ContentContainerComponent,
        LifeguardComponent,
        PageComponent,
        AnsiHighlighterComponent,
        MathGlideComponent,
        PolygonComponent,
        PgnCoreCanvasComponent,
        PgnEditContainerComponent,
        PgnMouseEditComponent,
        PgnPreviewComponent,
        LayerPanelComponent,
        PgnGraphEditToolbarComponent,
        ToolbarBtnComponent,
        PgnStrokeEditPopupComponent,
        PopupWrapperComponent,
        NtColorPickerComponent,
        NtInputComponent,
        PgnPaddingEditComponent,
        PgnEditPanelComponent,
        PgnLayerManagerPanelComponent,
        PgnLayerEditComponent,
        PgnShapeEditComponent,
        NtEmailPromptComponent,
        NtHomeComponent,
        AnsiHighlighterEulaComponent,
        AnsiHighlighterLicenseRegisterComponent,
        NtTabsComponent,
        NtTabComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        LayoutModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        ColorPickerModule
    ],
    providers: [PgnGraphDataService],
    bootstrap: [AppComponent]
})
export class AppModule { }
