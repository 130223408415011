<div class="pop-rt">
    <h3>Padding</h3>
    <div class="ipt-rw">
        <span class="ipt-lbl pad-lbl">Left: </span>
        <nt-input [(ngModel)]="graphRenderer.leftPad"
                  [type]="'number'"
                  [width]="inputWidth"
                  class="pop-ipt"
                  (ntapplychange)="applyGraphChange(true)"></nt-input>
    </div>
    <div class="ipt-rw">
        <span class="ipt-lbl pad-lbl">Right: </span>
        <nt-input [(ngModel)]="graphRenderer.rightPad"
                  [type]="'number'"
                  [width]="inputWidth"
                  class="pop-ipt"
                  (ntapplychange)="applyGraphChange(true)"></nt-input>
    </div>
    <div class="ipt-rw">
        <span class="ipt-lbl pad-lbl">Top: </span>
        <nt-input [(ngModel)]="graphRenderer.topPad"
                  [type]="'number'"
                  [width]="inputWidth"
                  class="pop-ipt"
                  (ntapplychange)="applyGraphChange(true)"></nt-input>
    </div>
    <div class="ipt-rw">
        <span class="ipt-lbl pad-lbl">Bottom: </span>
        <nt-input [(ngModel)]="graphRenderer.bottomPad"
                  [type]="'number'"
                  [width]="inputWidth"
                  class="pop-ipt"
                  (ntapplychange)="applyGraphChange(true)"></nt-input>
    </div>
</div>

