import {PgnGraph} from './pgn-graph';
import {PgnLayer} from '../layer/pgn-layer';
import {PgnGraphRenderer} from './pgn-graph-renderer';
import {NtUtils} from '../../../../../utils/nt-utils';
import {PgnLayerRenderer} from '../layer/pgn-layer-renderer';
import {PgnLayerFactory} from '../layer/pgn-layer-factory';
import {PgnRawLayerGroup} from '../layer/pgn-raw-layer-group';
import {PgnLayerGroupFactory} from '../layer/pgn-layer-group-factory';

export class PgnGraphFactory {

    private static nextId = 1;

    public static emptyGraphRenderer = new PgnGraphRenderer(false);

    public static toGraphRenderer(graph: PgnGraph<PgnLayer<any>, PgnRawLayerGroup<string>>, isEditModeActive: boolean): PgnGraphRenderer {
        let graphRenderer = new PgnGraphRenderer(isEditModeActive);
        NtUtils.assign(graphRenderer, graph, "layers", "groups");
        PgnGraphFactory.applyDefaults(graphRenderer);

        const layerMap: { [layerName: string] : PgnLayerRenderer; } = {};
        for (const layer of graph.layers) {
            const layerRenderer = PgnLayerFactory.toLayerRenderer(graphRenderer, layer);
            graphRenderer.layers.push(layerRenderer)
            layerMap[layerRenderer.name] = layerRenderer;
        }

        if(graph.groups) {
            for(const rawGroup of graph.groups) {
                const group = PgnLayerGroupFactory.toLayerGroup(rawGroup, layerMap);
                graphRenderer.groups.push(group);
            }
        }

        graphRenderer.initData();

        return graphRenderer;
    }

    private static applyDefaults(graph: PgnGraphRenderer) {
        if(graph.id !== 0 && !graph.id) {
            graph.id = PgnGraphFactory.nextId ++;
        }
        if(!graph.name) {
            graph.name = 'Graph-' + graph.id;
        }
        if(!graph.strokeWidth && graph.strokeWidth !== 0) {
            graph.strokeWidth = 2;
        }
        if(!graph.strokeColor) {
            graph.strokeColor = 'gray';
        }
        if(!graph.hStrokeColor) {
            graph.hStrokeColor = 'gray';
        }
        if (!graph.strokeOpacity && graph.strokeOpacity !== 0) {
            graph.strokeOpacity = .3;
        }
        if (!graph.hStrokeOpacity && graph.hStrokeOpacity !== 0) {
            graph.hStrokeOpacity = .3;
        }
        if(graph.leftPad !== 0 && !graph.leftPad) {
            graph.leftPad = 0;
        }
        if(graph.topPad !== 0 && !graph.topPad) {
            graph.topPad = 0;
        }
        if(graph.rightPad !== 0 && !graph.rightPad) {
            graph.rightPad = 0;
        }
        if(graph.bottomPad !== 0 && !graph.bottomPad) {
            graph.bottomPad = 0;
        }
    }
}
