<section>
    <div class="{{responsiveCls}}" [ngClass]="{'nt-mbl': isHandset, 'nt-scr': !isHandset}">
        <div class="content">
            <h1>{{productPage.sectionTitle}}</h1>

            <div class="vid-wrp">
                <div class="video" *ngIf="graphRenderer">
                    <!--<div class="pgn-root" style="position: relative"-->
                         <!--*ngIf="graphRenderer"-->
                         <!--[style]="{-->
                         <!--width: graphRenderer.viewPortWidth() + 'px',-->
                         <!--height: graphRenderer.viewPortHeight() + 'px'}">-->
                        <!--<nt-pgn-edit-container #edtCmp-->
                                               <!--class="cvs-ctn"-->
                                               <!--[polygonId]="polygonId"-->
                                               <!--[graphRenderer]="graphRenderer"-->
                                               <!--*ngIf="displayEditView()"-->
                                               <!--@edtCvsTgl-->
                                               <!--@animateChildren></nt-pgn-edit-container>-->

                        <pgn-preview #prvCmp
                                     class="cvs-ctn"
                                     [polygonId]="polygonId"
                                     [graphRenderer]="graphRenderer"
                                     [selectionSubject]="selectionSubject"
                                     *ngIf="!displayEditView()"
                                     @prvCvsTgl></pgn-preview>

                        <!--<div class="itrct-md">-->
                            <!--<ul>-->
                                <!--<li *ngFor="let btn of interactionModeButtons">-->
                                    <!--<nt-toolbar-btn [btnDescr]="btn"-->
                                                    <!--(ntclick)="onInteractionModePress(btn)">-->

                                    <!--</nt-toolbar-btn>-->
                                <!--</li>-->
                            <!--</ul>-->
                        <!--</div>-->
                    <!--</div>-->
                </div>
            </div>

            <p>
                Until we release Polygon, a novel cloud platform introducing an extra dimension to a huge industry
                sector, we are only teasing the abstract graphic above and leaving the rest to the public's imagination...
            </p>

            <p>
                Polygon is under active development with nothing similar in the market just yet.
                Do you keep your finger on the pulse of tech trends listening for the next big innovation that'll
                thrust your brand ahead of the competition? You don't want to miss on Polygon, subscribe and we'll be happy
                to notify you as soon as the platform is unveiled.
            </p>

            <nt-email-prompt class="ml"></nt-email-prompt>
        </div>

    </div>
</section>
