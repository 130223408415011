import {Component, ElementRef, HostListener, Input} from '@angular/core';
import {NtAbstractCompComponent} from '../nt-abstract-comp/nt-abstract-comp.component';
import {NtDragDropHandlerComponent} from '../nt-drag-drop-handler/nt-drag-drop-handler.component';
import {KeyEventUtils} from '../key-event-utils';
import {NtDndContext} from '../nt-drag-drop-handler/nt-dnd-context';
import {NtDndRemovalContext} from '../nt-drag-drop-handler/nt-dnd-removal-context';
import {NtDragHoverEvent} from '../nt-drag-drop-handler/nt-drag-hover-event';
import {from, Observable} from 'rxjs';
import {first, map} from 'rxjs/operators';

@Component({
    selector: 'nt-draggable',
    templateUrl: './nt-draggable.component.html',
    styleUrls: ['./nt-draggable.component.scss']
})
export abstract class NtDraggableComponent<T> extends NtAbstractCompComponent {

    @Input() public data: T;
    @Input() public type: string;


    /**
     * Each parent handler is expected to render the drop place holder animation on the go as user drags the cursor under its own view
     * when appropriate, as opposed to the drag animation which can only be rendered by the root handler view to allow for proper z-index
     * setting (the drag simulation should have the highest z-index throughout the entire DnD handler hierarchy).
     */
    // @ts-ignore
    @Input() parentDnDHandler: NtDragDropHandlerComponent;

    public x: number;
    public y: number;

    public dragDx: number;
    public dragDy: number;

    mouseX0 = -1;
    mouseY0 = -1;

    isDragged = false;

    dndEndAnimPositionDelta = 0;
    activateDropPopInAnim = false;

    draggableDepth;


    constructor(public hostEl: ElementRef) {
        super();
    }

    ngOnInit(): void {
        if(this.parentDnDHandler) {
            this.draggableDepth = this.parentDnDHandler.draggableDepth + 1;
        } else {
            this.draggableDepth = 0;
        }
        super.ngOnInit();
    }

    protected isValidPress(e: MouseEvent): boolean {
        // override to adjust implementation as needed
        return this.hostEl.nativeElement.contains(e.target) && e.button === 0;
    }


    @HostListener('document:mousedown', ['$event'])
    mouseDown(e: MouseEvent) {
        if(this.allowDrag(e) && !this.isValidPress(e)) {
            return;
        }
        this.mouseX0 = e.clientX;
        this.mouseY0 = e.clientY;
    }

    @HostListener('document:mousemove', ['$event'])
    mouseMove(e: MouseEvent) {
        if(this.allowDrag(e) && this.mouseX0 != -1) {
            this.updateDragCoordinates(e);
            if(this.isDragged) {
                this.parentDnDHandler.dragEventMoved(this, e);
            } else {
                this.parentDnDHandler.dragEventStarted(this, e);
                this.isDragged = true;
            }
        }
    }

    @HostListener('document:mouseup', ['$event'])
    mouseUp(e: MouseEvent) {
        if(this.isDragged) {
            this.updateDragCoordinates(e);
            this.parentDnDHandler.dragEventEnded(this, e);
            this.mouseX0 = this.mouseY0 = -1;
            this.isDragged = false;
        }
    }

    @HostListener('document:keyup', ['$event'])
    onKeyUp(e: KeyboardEvent) {
        if(KeyEventUtils.isEscapeKey(e) &&
            this.isDragged) {
            this.mouseX0 = this.mouseY0 = -1;
            this.isDragged = false;
            this.parentDnDHandler.dragEventCancelled(this);
        }

    }

    // @ts-ignore
    public chainDraggableRemoval(dndCtx: NtDndContext) : Observable<number> {
        const i = this.indexUnderParentHandler();
        const removalCtx = new NtDndRemovalContext(dndCtx, this, dndCtx.removalBubbleEndDepth, i);
        dndCtx.dndRemovalCtxs.push(removalCtx);
        this.parentDnDHandler.beforeChildDraggableRemoval(removalCtx);
        this.beforeDndRemoval(removalCtx);
        if(this.draggableDepth === dndCtx.removalBubbleEndDepth) {
            this.parentDnDHandler.draggableDataArray().splice(i, 1);
            return this.parentDnDHandler.draggableList().changes
                .pipe(first(), map((_r) => i));
        } else {
            this.parentDnDHandler.chainDraggableRemoval(dndCtx);
        }

    }

    public indexUnderParentHandler(): number {
        if(!this.parentDnDHandler || !this.parentDnDHandler.draggableList()) {
            return -1;
        }
        const draggables = this.parentDnDHandler.draggableList();
        let i = 0;
        while(true) {
            //if i goes beyond draggables range then there's a problem somewhere => allow an index out of bound here to fail fast
            if(draggables.get(i ++) === this) {
                return i - 1;
            }
        }
    }

    updateDragCoordinates(e: MouseEvent) {
        this.dragDx = (e.clientX - this.mouseX0);
        this.dragDy = (e.clientY - this.mouseY0);
    }


    // ################################################################################
    // ######################### To Implement by Sub-classes ##########################
    // ################################################################################

    // @ts-ignore
    public keepAfterDnDUnder(_dropTargetHandler: NtDragDropHandlerComponent, e: MouseEvent): boolean {
        return e.shiftKey;
    }

    public abstract allowDrag(e: MouseEvent): boolean;

    // ################################################################################
    // ######################### End Custom Implementations ##########################
    // ################################################################################




    // ################################################################################
    // ############################## Draggable Callbacks #############################
    // ################################################################################


    public beforeDnDReorder(_dndCtx: NtDndContext) {
        // to implement by sub-classes if needed
    }

    public afterDndReorderPreAnim(_dndCtx: NtDndContext) {
        // to implement by sub-classes if needed
    }

    public afterDndReorderPostAnim(_dndCtx: NtDndContext) {
        // to implement by sub-classes if needed
    }

    public beforeDndRemoval(_dndRemovalCtx: NtDndRemovalContext) {
        // to implement by sub-classes if needed
    }

    public afterDndRemovalPreAnimPreUIUpdate(_dndRemovalCtx: NtDndRemovalContext) {
        // to implement by sub-classes if needed
    }

    public afterDndRemovalPreAnimPostUIUpdate(_dndRemovalCtx: NtDndRemovalContext) {
        // to implement by sub-classes if needed
    }

    public afterDndRemovalPostAnim(_dndRemovalCtx: NtDndRemovalContext) {
        // to implement by sub-classes if needed
    }

    public dragHoverStarted(_e: NtDragHoverEvent) {
        // to implement by sub-classes if needed
    }

    public dragHoverEnded(_e: NtDragHoverEvent) {
        // to implement by sub-classes if needed
    }

    // ################################################################################
    // ############################# End Draggable Callbacks ##########################
    // ################################################################################

}
