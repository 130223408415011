<ng-template #popupContent><ng-content></ng-content></ng-template>

<div *ngIf="btnDescr.isTopPopup() && btnDescr.pressed"
     [ngClass]="overflowCls"
     [@tpopin]="{value: ':enter', params: {popupHeight: btnDescr.fullPopupHeight()}}"
     [@tpopout]="{value: ':leave', params: {popupHeight: btnDescr.fullPopupHeight()}}"
     (@tpopin.done)="popInAnimationEnd($event)"
     (@tpopout.done)="popOutAnimationEnd($event)"
     class="popup-rt pop-top">
    <div class="popup-wrp"
         [style.width]="btnDescr.popupWidth + 'px'"
         [style.height]="btnDescr.popupHeight + 'px'">
        <ng-container *ngTemplateOutlet="popupContent"></ng-container>
    </div>
    <div class="popup-btm-trgl"></div>
</div>

<div *ngIf="btnDescr.showLeftPopup()"
     class="popup-rt pop-left">
    <!--
    Applying 2 animations to separate wrappers for both the triangle and main content instead of a single
    animation on the root wrapper (as done with top/bottom popups) because of the following constraints:
    - Applying padding to the root wrapper div to prevent the trimming of the main content div shadow,
    messes the layout up for left/right popups (though not for top/bottom popups)

    - The outer css shadow should appear only on the main content (not the triangle since the triangle div is actually rectangular,
    the triangle display is just css manipulation, it is not a real triangle on which the shadow can shape)

    - The pop in/out animation requires an overflow hidden on the wrapper to mask the
    inner main content as the popup (wrapper div) shrinks/expands

    - Using an outer shadow on the main content div gets cut by overflow:hidden on the root wrapper

    - Applying css changes on :enter :leave animation callbacks won't be reflected while animating (elements
    enter the dom only after the animation completes)

    - Manipulating overflow:hidden/visible directly from the animation definition doesn't work under safari (property overflow
    doesn't seem to be supported)

    -->
    <div class="popup-wrp"
         [ngClass]="overflowCls"
         *ngIf="btnDescr.pressed"
         [style.width]="btnDescr.popupWidth + 'px'"
         [style.height]="btnDescr.popupHeight + 'px'"
         [@hpopin]="{value: ':enter', params: {popupHeight: btnDescr.fullPopupHeight()}}"
         [@hpopout]="{value: ':leave', params: {popupHeight: btnDescr.fullPopupHeight()}}"
         (@hpopin.done)="popInAnimationEnd($event)"
         (@hpopout.done)="popOutAnimationEnd($event)">
        <ng-container *ngTemplateOutlet="popupContent"></ng-container>
    </div>
    <div class="h-trgl-wrp r-trgl-wrp"
         *ngIf="btnDescr.pressed"
         [@hpopin]="{value: ':enter', params: {popupHeight: btnDescr.fullPopupHeight()}}"
         [@hpopout]="{value: ':leave', params: {popupHeight: btnDescr.fullPopupHeight()}}">
        <div style="margin-top:{{(triggerHeight / 2) + 'px'}}"
             class="popup-rgt-trgl"></div>
    </div>
</div>


<div *ngIf="btnDescr.isBottomPopup() && btnDescr.pressed"
     [ngClass]="overflowCls"
     class="popup-rt pop-btm"
     [@bpopin]="{value: ':enter', params: {popupHeight: btnDescr.fullPopupHeight()}}"
     [@bpopout]="{value: ':leave', params: {popupHeight: btnDescr.fullPopupHeight()}}"
     (@bpopin.done)="popInAnimationEnd($event)"
     (@bpopout.done)="popOutAnimationEnd($event)">
    <div class="popup-top-trgl"></div>
    <div class="popup-wrp"
         [style.width]="btnDescr.popupWidth + 'px'"
         [style.height]="btnDescr.popupHeight + 'px'">
        <ng-container *ngTemplateOutlet="popupContent"></ng-container>
    </div>
</div>

<div *ngIf="btnDescr.showRightPopup()"
     class="popup-rt pop-right">
    <!--
    Applying 2 animations to separate wrappers for both the triangle and main content instead of a single
    animation on the root wrapper (as done with top/bottom popups) because of the following constraints:
    - Applying padding to the root wrapper div to prevent the trimming of the main content div shadow,
    messes the layout up for left/right popups (though not for top/bottom popups)

    - The outer css shadow should appear only on the main content (not the triangle since the triangle div is actually rectangular,
    the triangle display is just css manipulation, it is not a real triangle on which the shadow can shape)

    - The pop in/out animation requires an overflow hidden on the wrapper to mask the
    inner main content as the popup (wrapper div) shrinks/expands

    - Using an outer shadow on the main content div gets cut by overflow:hidden on the root wrapper

    - Applying css changes on :enter :leave animation callbacks won't be reflected while animating (elements
    enter the dom only after the animation completes)

    - Manipulating overflow:hidden/visible directly from the animation definition doesn't work under safari (property overflow
    doesn't seem to be supported)

    -->
    <div class="popup-wrp"
         [ngClass]="overflowCls"
         *ngIf="btnDescr.pressed"
         [style.width]="btnDescr.popupWidth + 'px'"
         [style.height]="btnDescr.popupHeight + 'px'"
         [@hpopin]="{value: ':enter', params: {popupHeight: btnDescr.fullPopupHeight()}}"
         [@hpopout]="{value: ':leave', params: {popupHeight: btnDescr.fullPopupHeight()}}"
         (@hpopin.done)="popInAnimationEnd($event)"
         (@hpopout.done)="popOutAnimationEnd($event)">
        <ng-container *ngTemplateOutlet="popupContent"></ng-container>
    </div>
    <div class="h-trgl-wrp"
         *ngIf="btnDescr.pressed"
         [@hpopin]="{value: ':enter', params: {popupHeight: btnDescr.fullPopupHeight()}}"
         [@hpopout]="{value: ':leave', params: {popupHeight: btnDescr.fullPopupHeight()}}">
        <div style="margin-top:{{(triggerHeight / 2) + 'px'}}"
             class="popup-left-trgl"></div>
    </div>
</div>
