import {PgnLayer} from './pgn-layer';
import {PgnGraphRenderer} from '../graph/pgn-graph-renderer';
import {PgnLayerRenderer} from './pgn-layer-renderer';
import {NtUtils} from '../../../../../utils/nt-utils';
import {PgnShapeFactory} from '../shape/pgn-shape-factory';
import {PgnHoverResponsiveArea} from '../graph/pgn-hover-responsive-area.enum';

export class PgnLayerFactory {
    private static nextId = 1;

    public static toLayerRenderer(graphRenderer: PgnGraphRenderer, layer: PgnLayer<any>): PgnLayerRenderer {
        const layerRenderer = PgnLayerFactory.newLayer(graphRenderer);

        NtUtils.assign(layerRenderer, layer, "shapes");
        for(const rawShape of layer.shapes) {
            const shape = PgnShapeFactory.toShape(rawShape, layerRenderer);
            layerRenderer.addShape(shape);
        }
        PgnLayerFactory.applyDefaults(layerRenderer);
        return layerRenderer;
    }

    private static applyDefaults(layer: PgnLayerRenderer) {
        if(layer.id !== 0 && !layer.id) {
            layer.id = PgnLayerFactory.nextId ++;
        }
        if(!layer.name) {
            layer.name = 'Layer-' + layer.id;
        }
        if(layer.visible !== false && !layer.visible) {
            layer.visible = true;
        }
        if(layer.isClippable !== false && !layer.isClippable) {
            layer.isClippable = true;
        }
        if(layer.isClipping !== false && !layer.isClipping) {
            layer.isClipping = true;
        }
        if(!layer.hoverResponsiveArea) {
            layer.hoverResponsiveArea = PgnHoverResponsiveArea.FILL;
        }
        if(layer.strokeWidth !== 0 && !layer.strokeWidth) {
            layer.strokeWidth = 2;
        }
        if(!layer.strokeColor) {
            layer.strokeColor = 'gray';
        }
        if(!layer.hStrokeColor) {
            layer.hStrokeColor = 'gray';
        }
        if(layer.strokeOpacity !== 0 && !layer.strokeOpacity) {
            layer.strokeOpacity = .3;
        }
        if(layer.hStrokeOpacity !== 0 && !layer.hStrokeOpacity) {
            layer.hStrokeOpacity = .3;
        }

    }

    public static newLayer(graphRenderer: PgnGraphRenderer): PgnLayerRenderer {
        const layerRenderer = new PgnLayerRenderer();
        layerRenderer.graphRenderer = graphRenderer;
        layerRenderer.name =  'Layer ' + (layerRenderer.id);
        return layerRenderer;
    }

}
