<div class="zm-ipt-ct">
    <span *ngIf="prefix" class="lbl" #lbl>{{prefix}}</span>
    <input type="{{type}}"
           class="zm-ipt"
           [style]="{
           width: width,
           paddingLeft: lblWidth + 'px'}"
           [(ngModel)]="ntvalue"
           (keydown)="keyDown($event)"
           (blur)="blur()">
</div>
