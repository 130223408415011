<nav *ngIf="!isHandset" class="navbar">
    <div class="nav-container {{responsiveCls}}">
        <div class="logo-wrp">
            <div class="bg-logo_text_64 sprite"></div>
        </div>
        <div class="nav-items">
            <ul >
                <li *ngFor="let productPage of mainProductPages" [ngClass]="{'active': productPage.pageSelected}">
                    <a href="#{{productPage.path}}" (click)="onNavItemClick($event, productPage)">{{productPage.navTitle}}</a>
                </li>
            </ul>
        </div>
    </div>
    <div class="nav-shd"></div>
</nav>

<nav *ngIf="isHandset" class="navbar-mbl">
    <div class="nav-container {{responsiveCls}}">
        <div class="menu-btn-dv">
            <svg (click)="onResponsiveBtnClick()">
                <path d="M0 0h36v36H0z" fill="none"></path>
                <circle *ngIf="showMenuItemsInHandsetMode" cx="18" cy="18" r="18" fill="#DDDDDD"></circle>
                <path d="M10 12h16v2H10zM10 17h16v2H10zM10 22h16v2H10z"></path>
            </svg>
        </div>
        <span [@navTitleTr]="isTitleAnimating ? 'scaleOut' : 'idle'"
              (@navTitleTr.done)="titleNavTransitionStepEnd($event)">{{mobileSectionTitle}}</span>
        <div class="logo-wrp">
            <div class="bg-logo_64 sprite"></div>
        </div>
        <div [@navItemsMblTr]="showMenuItemsInHandsetMode ? 'itemsIn' : 'itemsOut'" class="nav-items">
            <ul >
                <li *ngFor="let productPage of mainProductPages" [ngClass]="{'active': productPage.pageSelected}">
                    <a href="#{{productPage.path}}" (click)="onNavItemClick2($event, productPage)">{{productPage.navTitle}}</a>
                </li>
            </ul>
        </div>
        <div [@navOverlayMblTr]="showMenuItemsInHandsetMode ? 'fadeIn' : 'fadeOut'"
             class="nav-itms-mbl-overlay"
             (click)="onOverlayClick($event)">
        </div>
    </div>
    <div class="nav-shd-mbl"></div>
</nav>

