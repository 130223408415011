import {ElementRef} from '@angular/core';
import {NtSizeObservableContext} from './nt-abstract-comp/nt-size-observable-context';
import {NtSizeChangeCompContext} from './nt-abstract-comp/nt-size-change-comp-context';
import {NtPoint} from './nt-point';

export class NtDomUtils {

    private static sizeObserver: any;
    private static sizeObserverContexts: { [key: number]: NtSizeChangeCompContext };

    public static observeSizeChange(ctx: NtSizeObservableContext) {
        if(!this.sizeObserver) {
            const self = this;
            this.sizeObserver = new (window as any).ResizeObserver(_entries => {
                let ctx: NtSizeChangeCompContext;
                for(const key in self.sizeObserverContexts) {
                    ctx = self.sizeObserverContexts[key];
                    ctx.notify();
                }
            });
            this.sizeObserverContexts = {};
        }
        this.sizeObserver.observe(ctx.el.nativeElement);
        const compCtx = ctx.compCtx;
        if(!(compCtx.id in this.sizeObserverContexts)) {
            this.sizeObserverContexts[compCtx.id] = compCtx;
        }
    }

    public static unregisterSizeChangeObservers(ctx: NtSizeChangeCompContext) {
        ctx.unobserve(this.sizeObserver);
        delete this.sizeObserverContexts[ctx.id];
    }

    public static convertMouseEventCoordinates(e: MouseEvent, el: ElementRef, pt: NtPoint) {
        const containerRect = el.nativeElement.getBoundingClientRect();
        const leftBorder = this.getElComputedDimensionInPixels(el, 'border-left-width');
        const topBorder = this.getElComputedDimensionInPixels(el, 'border-top-width');
        pt.x = (e.clientX - containerRect.left - leftBorder);
        pt.y = (e.clientY - containerRect.top - topBorder);
    }

    public static convertElementCoordinates(el: ElementRef, targetContainer: ElementRef, pt: NtPoint) {
        const containerRect = targetContainer.nativeElement.getBoundingClientRect();
        const elRect = el.nativeElement.getBoundingClientRect();
        const ctnLeftBorder = this.getElComputedDimensionInPixels(targetContainer, 'border-left-width');
        const ctnTopBorder = this.getElComputedDimensionInPixels(targetContainer, 'border-top-width');
        pt.x = (elRect.left - containerRect.left - ctnLeftBorder);
        pt.y = (elRect.top - containerRect.top - ctnTopBorder);
    }

    public static convertElementInnerCoordinates(el: ElementRef, targetContainer: ElementRef, pt: NtPoint) {
        NtDomUtils.convertElementCoordinates(el, targetContainer, pt);
        const leftBorder = this.getElComputedDimensionInPixels(el, 'border-left-width');
        const topBorder = this.getElComputedDimensionInPixels(el, 'border-top-width');
        pt.x += leftBorder;
        pt.y += topBorder;
    }

    public static getElComputedDimensionInPixels(el: ElementRef, pseudoElt: string): number {
        return this.getHtmlElComputedDimensionInPixels(el.nativeElement, pseudoElt);
    }

    public static getHtmlElComputedDimensionInPixels(el: Element, pseudoElt: string): number {
        const dim = window.getComputedStyle(el);
        let v = dim[pseudoElt];
        if(v.endsWith('px')) {
            v = v.substring(0, v.length - 2);
        }
        return parseFloat(v);
    }
}
