import {PgnShape} from './pgn-shape';
import {PgnLayerRenderer} from '../layer/pgn-layer-renderer';
import {PgnShapeType} from './pgn-shape-type.enum';
import {PgnPolygonShape} from './pgn-polygon-shape';
import {PgnLineShape} from './pgn-line-shape';
import {PgnCircleShape} from './pgn-circle-shape';
import {PgnSquareShape} from './pgn-square-shape';
import {PgnTextShape} from './pgn-text-shape';
import {PgnShapeRenderMode} from './pgn-shape-render-mode.enum';


export class PgnShapeFactory {

    private static nextId = 1;

    public static toShape(rawShape: any, layerRenderer: PgnLayerRenderer): PgnShape {
        const shape = PgnShapeFactory.newShape(rawShape.shapeType, layerRenderer);
        if(shape != null) {
            Object.assign(shape, rawShape);
        }
        PgnShapeFactory.applyDefaults(shape);
        return shape;
    }

    private static applyDefaults(shape: PgnShape) {
        shape.applyDefaults();

        if(shape.id !== 0 && !shape.id) {
            shape.id = PgnShapeFactory.nextId ++;
        }
        if(!shape.name) {
            shape.name = 'Shape-' + shape.id;
        }
        if(!shape.renderMode) {
            shape.renderMode = PgnShapeRenderMode.ADD;
        }
        if(!shape.fill) {
            shape.fill = 'red';
        }
        if(!shape.hoverFill) {
            shape.hoverFill = 'cyan';
        }
        if(shape.fillOpacity !== 0 && !shape.fillOpacity) {
            shape.fillOpacity = .3;
        }
        if(shape.hFillOpacity !== 0 && !shape.hFillOpacity) {
            shape.hFillOpacity = .3;
        }
        if(shape.enabled !== false && !shape.enabled) {
            shape.enabled = true;
        }
    }

    public static newShape(shapeType: PgnShapeType, layerRenderer: PgnLayerRenderer): PgnShape {
        let shape: PgnShape = null;
        switch (shapeType) {
            case PgnShapeType.Polygon: {
                shape = new PgnPolygonShape();
                break;
            }
            case PgnShapeType.Line: {
                shape = new PgnLineShape();
                break;
            }
            case PgnShapeType.Circle: {
                shape = new PgnCircleShape();
                break;
            }
            case PgnShapeType.Square: {
                shape = new PgnSquareShape();
                break;
            }
            case PgnShapeType.Text: {
                shape = new PgnTextShape();
                break;
            }
        }
        if(shape != null) {
            shape.layerRenderer = layerRenderer;
        }
        return shape;
    }

}
