import {PgnShape} from '../shape/pgn-shape';
import {PgnHoverResponsiveArea} from '../graph/pgn-hover-responsive-area.enum';

export class PgnLayer<T extends any | PgnShape> {
    name: string;

    // 'enabled' property for shapes is not the same as 'visible' in layers.
    // 'enabled' for shapes is taken into consideration at pre-processing phase to add-up and/or subtract into a single layer
    // multiple shapes per layer is meant to allow for the composition of complex shapes (i.e. layers) which are looked at as a single/whole
    // when drawing => 'enabled' property at shape level is not considered when drawing
    // in contrast 'visible' property at Layer level has no effect whatsoever at pre-processing (clipping is not affected), it is only
    // taken into consideration when drawing/interacting with pointing device
    visible = true;

    // self is affected by other layers
    isClippable = true;

    // affects other layers
    isClipping = true;

    hoverResponsiveArea = PgnHoverResponsiveArea.FILL;

    // 1- Used only when isClipping = false (when this layer affects the clipping of other layers, otherwise stroke config should be unified)
    // 2- isClippable only affects the drawing of the current layer => only isClipping enables/disables per layer stroke config (isClippable doesn't)
    // 3- Stroke configs are treated at layer level (instead of shape level) so that when merging sub-shapes by union or by subtraction
    // there is no stroke discrepancy.
    // 4- The flexibility of multiple shape strokes is still possible across layers under the condition parentLayer.isClipping = false
    strokeWidth: number;
    strokeColor: string;
    hStrokeColor: string;
    strokeOpacity: number;
    hStrokeOpacity: number;
    // _____________________________

    // When the json array is queried from the server it can carry a mixture of polymorphic elements (example: rectangles,
    // circles, polygons etc...)) => The only way to receive the raw json array is to use Array<any>.
    // As soon as the raw data is received PgnGraphFactory takes care of de-serializing the raw data properly into layers/shapes.
    // T can either be;
    // - "any" (when caught raw from server response / short lived instance)
    // - "PgnShape" as soon as data gets de-serialized => PgnShape (abstract) is actually de-serialized into concrete Shape sub-types
    //      => this remains transparent to the rest of code which uses the PgnShape interface to interact with the concrete sub-types
    // T can take no other form
    shapes: Array<T>;

}
