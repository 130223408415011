import {NtDragDropHandlerComponent} from './nt-drag-drop-handler.component';

// not meant for external use => not to be relied upon by DnD custom end implementations
export class NtDndRelayoutContext {
    // @ts-ignore
    constructor(public handler: NtDragDropHandlerComponent,
                public startIndex: number,
                public endIndex: number = -1) {

    }

    public resetDndAnimations() {
        this.handler.dndEndAnimSizeDelta = 0;
        const draggables = this.handler.draggableList();
        const end = this.endIndex === -1 ? (draggables.length - 1) : this.endIndex;
        for(let i = this.startIndex; i <= end; i ++) {
            draggables.get(i).y += draggables.get(i).dndEndAnimPositionDelta;
            draggables.get(i).dndEndAnimPositionDelta = 0;
        }
    }
}
