import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NtEmailRegisterService} from './nt-email-register.service';
import {AbstractComponent} from '../../abstract-component';
import {BreakpointObserver} from '@angular/cdk/layout';


const MAIL_REGEX = /^\w(\w|\.|_)*@\w(\w|\.|_)*\.\w(\w|_)*$/

@Component({
    selector: 'nt-email-prompt',
    templateUrl: './nt-email-prompt.component.html',
    styleUrls: ['./nt-email-prompt.component.scss']
})
export class NtEmailPromptComponent extends AbstractComponent implements OnInit {

    @ViewChild('submt') submitBtn: ElementRef;
    @ViewChild('ipt') input: ElementRef;

    email: string;
    errMsg : string;
    submitSuccess = false;
    inputHasFocus = false;

    clearResultId = 0;

    constructor(public breakpointObserver: BreakpointObserver, protected emailService: NtEmailRegisterService) {
        super(breakpointObserver);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    onSubmit(_event: MouseEvent) {
        if(!this.email || this.email.trim().length === 0) {
            this.mailSubmitError("Empty email");
        } else if(!MAIL_REGEX.test(this.email.trim())) {
            this.mailSubmitError("Bad email format");
        } else {
            this.errMsg = null;
            this.submitSuccess = false;
            const self = this;
            this.emailService.registerEmail(this.email.trim()).subscribe({
                next: (response) => {
                    if(response.success) {
                        self.mailSubmitSuccess();
                    } else {
                        self.mailSubmitError(response.errMsg);
                    }
                },
                error: (e) => {
                    self.mailSubmitError('Unexpected error, we apologize for the inconvenience. For support, please reach out to contact@niftythoughts.com');
                    console.error(e);
                }
            });
        }
    }

    private mailSubmitSuccess() {
        this.submitSuccess = true;
        const self = this;
        const clearId = ++ this.clearResultId;
        setTimeout(() => self.clearSubmissionResult(clearId), 15000);
    }

    private mailSubmitError(msg: string) {
        this.submitSuccess = false;
        this.errMsg = msg;
        const self = this;
        const clearId = ++ this.clearResultId;
        setTimeout(() => self.clearSubmissionResult(clearId), 15000);
    }

    private clearSubmissionResult(clearId: number) {
        if(clearId === this.clearResultId) {
            this.submitSuccess = false;
            this.errMsg = null;
        }
    }

    fixMobileScrollOutOfViewGlitch(e: FocusEvent) {
        this.inputHasFocus = true;
        this.docDimensionsToPixels();
        setTimeout(() => {
            (e.target as Element).scrollIntoView(true);
        }, 100);

        // document.body.scrollTop = 0;
    }

    reverseScrollOutOfViewGlitchFix(e: FocusEvent) {
        this.inputHasFocus = false;
        this.resetDocDimensions();
        setTimeout(() => {
            (e.target as Element).scrollIntoView(true);
        }, 100);
    }

    responsiveBreakPointChange() {
        if(!this.inputHasFocus) {
            return;
        }
        const self = this;
        setTimeout(() => {
            self.docDimensionsToPixels();
            setTimeout(() => {
                self.input.nativeElement.scrollIntoView(true);
            }, 100);
        }, 100);
    }

}
