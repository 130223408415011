export class NtColor {
    constructor(private ntcolor: any) {}

    toString(): string {
        return this.ntcolor.toString();
    }

    toHex8String() : string {
        const hex8 = this.ntcolor.toHex8String();
        return '#' + hex8.substring(3) + hex8.substring(1, 3);
    }

    toRGBString() : string {
        return this.ntcolor.toRgbString();
    }
}
