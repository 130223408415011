import {AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {combineLatest, Subject} from 'rxjs';
import {PgnLayerRenderer} from './layer/pgn-layer-renderer';
import {map} from 'rxjs/operators';
import {PgnGraphRenderer} from './graph/pgn-graph-renderer';
import {NtAbstractCompComponent} from '../../../../utils/nt-abstract-comp/nt-abstract-comp.component';
import {AbstractComponent} from '../../../../abstract-component';
import {BreakpointObserver} from '@angular/cdk/layout';

@Component({
  selector: 'nt-core-canvas',
  templateUrl: './pgn-core-canvas.component.html',
  styleUrls: ['./pgn-core-canvas.component.scss']
})
export class PgnCoreCanvasComponent implements OnInit, AfterViewInit {

    @ViewChild('ntCnvs') canvasRef: ElementRef;
    rootElement: HTMLElement;
    ctx: CanvasRenderingContext2D;

    @Input() polygonId: string;
    @Input() graphRenderer: PgnGraphRenderer;

    private hoveredLayer: PgnLayerRenderer;
    protected isMousePressed = false;
    protected isEditMode = false;

    private graphRendererInitializedSubject = new Subject<boolean>();
    private afterViewInitSubject = new Subject<boolean>();

    private hostParentElLastWidth = 0;

    constructor(protected hostEl:ElementRef) {}

    ngOnInit() {
        const self = this;
        combineLatest([this.graphRendererInitializedSubject.asObservable(),
            this.afterViewInitSubject.asObservable()])
            .pipe(map(([graphRendererInitialized, viewInitialized]) => {
                return graphRendererInitialized && viewInitialized;
            })).subscribe((_ready) => self.viewReady());
    }

    ngAfterViewInit() {
        this.ctx = this.canvasRef.nativeElement.getContext('2d');
        this.rootElement = this.canvasRef.nativeElement.parentElement;
        this.afterViewInitSubject.next(true);
        this.afterViewInitSubject.complete();
        if(this.graphRenderer) {
            this.graphRendererInitialized(this.graphRenderer);
        }
    }

    public graphRendererInitialized(graphRenderer: PgnGraphRenderer) {
        this.graphRenderer = graphRenderer;
        this.graphRendererInitializedSubject.next(true);
    }

    protected viewReady() {
        this.pageResizeReflected();
    }

    protected onMousePress(_e: MouseEvent) {
        this.isMousePressed = true;
        this.clearHoverLayer();
    }

    protected processHoverEvent(e: MouseEvent): boolean {
        if(this.isMousePressed)
            return false;
        const x = this.getNormalizedX(e);
        const y = this.getNormalizedY(e);
        const layerIndex = this.getLayerIndexAt(x, y);
        const layer = layerIndex === -1 ? null : this.graphRenderer.layers[layerIndex];
        if(layer == this.hoveredLayer) {
            return true;
        }
        this.clearHoverLayer();
        this.hoveredLayer = layer;
        if(layer) {
            layer.isHovered = true;
            // Analytics.logGA(PolygonAnalytics.CATEGORY, PolygonAnalytics.PREVIEW_ACTION, null, PolygonAnalytics.PREVIEW_HOVER);
        }
        this.repaint();
        return true;
    }

    private clearHoverLayer() {
        if(this.hoveredLayer) {
            this.hoveredLayer.isHovered = false;
            this.hoveredLayer = null;
        }
    }

    onMouseOut(_e: MouseEvent) {
        this.clearHoverLayer();
    }

    onMouseRelease(_e: MouseEvent) {
        this.isMousePressed = false;
        this.clearHoverLayer();
    }

    scaleChanged() {
    }

    repaint() {
        this.graphRenderer.repaint(this.ctx);
    }

    protected getLayerIndexAt(x: number, y: number): number {
        let layer;
        for(let i = 0; i < this.graphRenderer.layers.length; i++) {
            layer = this.graphRenderer.layers[i];
            if(layer.isPixelWithinLayer(x, y)) {
                return i;
            }
        }
        return -1;
    }

    // returns pointer position as if the image was not scaled
    getNormalizedX(e: MouseEvent) {
        return Math.round(e.offsetX / this.graphRenderer.scaleX);
    }

    // returns pointer position as if the image was not scaled
    getNormalizedY(e: MouseEvent) {
        return Math.round(e.offsetY / this.graphRenderer.scaleY);
    }

    pageResizeReflected() {
        const ctn = this.hostEl.nativeElement.parentElement;
        const scale = ctn.offsetWidth / this.graphRenderer.width;
        this.graphRenderer.scaleChanged(scale, scale);
    }

    isPageResizeReflected(): boolean {
        const ctn = this.hostEl.nativeElement.parentElement;
        if(ctn.offsetWidth !== this.hostParentElLastWidth) {
            this.hostParentElLastWidth = ctn.offsetWidth;
            return true;
        }
        return false;
    }

    pageTransitionStart() {
    }

    pageTransitionEnd() {
    }
}
