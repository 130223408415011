import {NtDraggableComponent} from '../nt-draggable/nt-draggable.component';
import {NtDndContext} from './nt-dnd-context';

// used by callbacks:
// - beforeChildDraggableRemoval()
// - beforeDndRemoval()
// - afterDndRemovalPreAnimPostUIUpdate()
// - afterChildDraggableRemovalPreAnimPostUIUpdate()
// - afterDndRemovalPostAnim()
// - afterChildDraggableRemovalPostAnim()
export class NtDndRemovalContext {

    constructor(public dndCtx: NtDndContext,
                // @ts-ignore
                public removedDraggable: NtDraggableComponent,
                // @ts-ignore
                public lastRemovedAncestorDepth: number,
                public removalIndex: number) {

    }
}
