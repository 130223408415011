<div class="pop-rt">
    <h3>Default Stroke</h3>
    <div class="ipt-rw">
        <span class="ipt-lbl stk-lbl">Width: </span>
        <nt-input [(ngModel)]="graphRenderer.strokeWidth"
                  [type]="'number'"
                  [width]="inputWidth"
                  class="pop-ipt"
                  (ntapplychange)="applyGraphChange(false)"></nt-input>

    </div>
    <div class="ipt-rw">
        <span class="ipt-lbl stk-lbl">Color: </span>
        <nt-color-picker (ntcolorChange)="applyGraphChange(false)"
                         [btnDescr]="strokeColorBtn"
                         class="pop-ipt pop-clr-pck">
        </nt-color-picker>

    </div>
    <div class="ipt-rw">
        <span class="ipt-lbl stk-lbl">Highlight Color: </span>
        <nt-color-picker (ntcolorChange)="applyGraphChange(false)"
                         [btnDescr]="hStrokeColorBtn"
                         class="pop-ipt">
        </nt-color-picker>

    </div>
</div>
