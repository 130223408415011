import {Component, EventEmitter, forwardRef, Input, Output, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy} from '@angular/core';
import {KeyEventUtils} from '../key-event-utils';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import {NtAbstractCompComponent} from '../nt-abstract-comp/nt-abstract-comp.component';


const noop = () => {
};


export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => NtInputComponent),
    multi: true
};



@Component({
    selector: 'nt-input',
    templateUrl: './nt-input.component.html',
    styleUrls: ['./nt-input.component.scss'],
    providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class NtInputComponent extends NtAbstractCompComponent implements ControlValueAccessor {

    @ViewChild('lbl') lblEl: ElementRef;

    @Input() width: string;

    @Input() prefix: string;

    @Input() type: string;

    @Output() ntapplychange = new EventEmitter<string>();

    lblWidth = 3;// 1 instead of zero to add 3px left-padding to input content when label is empty

    _ntvalue: any;

    public get ntvalue(): any {
        return this._ntvalue;
    }

    public set ntvalue(newVal: any) {
        if(this._ntvalue !== newVal) {
            this._ntvalue = newVal;
            this.propagateChange(newVal);
        }
    }

    lastvalue: string;

    //Placeholders for the callbacks which are later providesd
    //by the Control Value Accessor
    private propagateChange: (_: any) => void = noop;

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.lastvalue = this.ntvalue;
    }

    ngAfterViewInit(): void {
        if(this.lblEl) {
            const self = this;
            // sizeObserver is necessary to update size when input starts under an animated component
            // size is updated properly only after animation ends
            this.observeSizeChange(this.lblEl,
                (newWidth: number, _newHeight: number) => {
                    self.lblWidth = newWidth + 1;
                });
        }
    }

    keyDown(event) {
        if(KeyEventUtils.isEnterKey(event)) {
            this.applyChange();
        }
    }

    blur() {
        this.applyChange();
    }

    applyChange() {
        if(this.lastvalue === this.ntvalue) {
            return;
        }
        this.lastvalue = this.ntvalue;
        this.ntapplychange.emit(this.ntvalue);
    }

    //From ControlValueAccessor interface
    writeValue(value: any) {
        this.ntvalue = value;
    }

    //From ControlValueAccessor interface
    registerOnChange(fn: any) {
        this.propagateChange = fn;
    }

    //From ControlValueAccessor interface
    registerOnTouched(_fn: any) {
    }

    //From ControlValueAccessor interface
    setDisabledState?(_isDisabled: boolean): void {

    }

}
