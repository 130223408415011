import {PgnGraph} from './pgn-graph';
import {PgnLayerRenderer} from '../layer/pgn-layer-renderer';
import {PgnLayerGroup} from '../layer/pgn-layer-group';
import {PgnLayerFactory} from '../layer/pgn-layer-factory';


enum PgnTrimCacheRefreshStrategy {
    NEVER = "NEVER",
    ON_CLIPPABLE = "ON_CLIPPABLE",
    ALWAYS = "ALWAYS"
}

export class PgnGraphRenderer extends PgnGraph<PgnLayerRenderer, PgnLayerGroup> {

    id: number;

    private globalFullCvs: HTMLCanvasElement;
    private globalFullCtx: CanvasRenderingContext2D;

    private globalExternalStrokeCvs: HTMLCanvasElement;
    private globalExternalStrokeCtx: CanvasRenderingContext2D;

    private tmpCvs: HTMLCanvasElement;
    private tmpCtx: CanvasRenderingContext2D;

    private tmpCvs2: HTMLCanvasElement;
    private tmpCtx2: CanvasRenderingContext2D;

    // _______________________________________________________________

    private previewCvs: HTMLCanvasElement;
    private previewCtx: CanvasRenderingContext2D;

    private editCvs: HTMLCanvasElement;
    private editCtx: CanvasRenderingContext2D;

    public isEditMode = false;

    constructor(private isEditModeActive: boolean) {
        super();
        this.layers = new Array<PgnLayerRenderer>();
        this.groups = new Array<PgnLayerGroup>();
    }

    // Trim adjustment here is based on cached trimmed (as opposed to raw) graphic contexts so to optimize by avoiding
    // re-trimming all layers from the ground up in response to layer transition from 'non-clipping' state to 'clipping'
    // => layer transition from 'non-clipping' to 'clipping' or the duplication of an existing layer are the only 'clip-change' scenarios
    // where this optimization can be applied with no side-effects => all other transitions should be processed through processOverlaps()
    // layerStartIndex should be 0 if transition from 'non-clipping' to 'clipping', otherwise see newLayerAtIndex()
    // layerEndIndex = trimming layer index - 1
    // indexes are inclusive
    adjustLayersTrimming(trimmingLayer: PgnLayerRenderer,
                         layerStartIndex: number,
                         layerEndIndex: number) {
        for(let i = layerEndIndex; i >= layerStartIndex; i--) {
            this.adjustLayerTrimming(trimmingLayer, this.layers[i]);
        }
    }

    // supposes trimmingLayer.isClipping = true
    adjustLayerTrimming(trimmingLayer: PgnLayerRenderer, trimmedLayer: PgnLayerRenderer) {
        if(trimmingLayer.rawOpaqueFullCvs == null || !trimmedLayer.isClippable || trimmedLayer.rawOpaqueFullCvs == null) {
            return;
        }

        if (trimmingLayer.rawOpaqueStrokeCvs != null && this.strokeWidth > 0) {
            // __________________________ extract new stroke portion to add _________________________
            this.initializedTmpCtx().globalCompositeOperation = 'source-over';
            this.tmpCtx.drawImage(trimmingLayer.rawOpaqueStrokeCvs, 0, 0);

            const trimmedFull = trimmedLayer.trimmedOpaqueFullCvs == null ? trimmedLayer.rawOpaqueFullCvs : trimmedLayer.trimmedOpaqueFullCvs;
            this.tmpCtx.globalCompositeOperation = 'source-in';
            this.tmpCtx.drawImage(trimmedFull, 0, 0);
            // __________________________________________________________________________________
        }

        if (this.strokeWidth > 0 && trimmedLayer.rawOpaqueStrokeCvs != null) {// stroke adjustment
            if(trimmedLayer.trimmedOpaqueStrokeCvs == null) {
                trimmedLayer.initializedTrimmedOpaqueStrokeCtx().globalCompositeOperation = 'source-over';
                trimmedLayer.trimmedOpaqueStrokeCtx.drawImage(trimmedLayer.rawOpaqueStrokeCvs, 0, 0);
            }
            // take out the trimming layer from trimmed stroke
            trimmedLayer.trimmedOpaqueStrokeCtx.globalCompositeOperation = 'destination-out';
            trimmedLayer.trimmedOpaqueStrokeCtx.drawImage(trimmingLayer.rawOpaqueFullCvs, 0, 0);

            if(trimmingLayer.rawOpaqueStrokeCvs != null) {
                // add new extracted stroke portion
                trimmedLayer.trimmedOpaqueStrokeCtx.globalCompositeOperation = 'source-over';
                trimmedLayer.trimmedOpaqueStrokeCtx.drawImage(this.tmpCvs, 0, 0);
            }
        }

        if(trimmedLayer.trimmedOpaqueFullCvs == null) {
            trimmedLayer.initializedTrimmedOpaqueFullCtx().globalCompositeOperation = 'source-over';
            trimmedLayer.trimmedOpaqueFullCtx.drawImage(trimmedLayer.rawOpaqueFullCvs, 0, 0);
        }

        // take out the trimming layer from trimmed full
        trimmedLayer.trimmedOpaqueFullCtx.globalCompositeOperation = 'destination-out';
        trimmedLayer.trimmedOpaqueFullCtx.drawImage(trimmingLayer.rawOpaqueFullCvs, 0, 0);

        if(trimmingLayer.rawOpaqueStrokeCvs != null && this.strokeWidth > 0) {
            // add new extracted stroke portion
            trimmedLayer.trimmedOpaqueFullCtx.globalCompositeOperation = 'source-over';
            trimmedLayer.trimmedOpaqueFullCtx.drawImage(this.tmpCvs, 0, 0);
        }

        this.tmpCtx = null;
        this.tmpCvs = null;

        trimmedLayer.refreshTrimmedCache();
    }


    // indexes are inclusive
    processOverlaps(layerStartIndex: number,
                    layerEndIndex: number,
                    refreshLayerTrimCache: PgnTrimCacheRefreshStrategy,
                    lowestClippableLayerIndex: number) {
        for(let i = layerEndIndex; i >= layerStartIndex; i--) {
            this.clipLayerOverlaps(this.layers[i], refreshLayerTrimCache, i > lowestClippableLayerIndex);
            this.tmpCtx = this.tmpCtx2 = null;
            this.tmpCvs = this.tmpCvs2 = null;
        }
    }

    private clipLayerOverlaps(layerRenderer: PgnLayerRenderer,
                              refreshLayerTrimCache: PgnTrimCacheRefreshStrategy,
                              buildCumulativeClippingMass: boolean) {
        if(layerRenderer.rawOpaqueFullCvs == null) {
            return;
        }
        const refreshTrimCache = refreshLayerTrimCache == PgnTrimCacheRefreshStrategy.ALWAYS ||
            (layerRenderer.isClippable && refreshLayerTrimCache == PgnTrimCacheRefreshStrategy.ON_CLIPPABLE);
        if(refreshTrimCache) {
            layerRenderer.resetTrimCache();
        }
        let strokeIntersectCvs: HTMLCanvasElement;
        if(refreshTrimCache && this.globalFullCvs != null) {
            strokeIntersectCvs = layerRenderer.clipIfClippable(this.globalFullCvs, this.globalExternalStrokeCvs);
        }

        if(layerRenderer.isClipping && buildCumulativeClippingMass) {
            this.updateGlobalExternalStrokeIfApplicable(layerRenderer, strokeIntersectCvs);

            if(this.globalFullCvs == null) {
                this.initializedGlobalFullCtx().globalCompositeOperation = 'source-over';
            }
            this.globalFullCtx.drawImage(layerRenderer.rawOpaqueFullCvs, 0, 0);

        }

        if(refreshTrimCache) {
            layerRenderer.refreshTrimmedCache();
        }
    }

    // supposes layerRenderer.isClipping = true and this.globalFullCvs does not include layerRenderer yet
    private updateGlobalExternalStrokeIfApplicable(layerRenderer: PgnLayerRenderer, strokeIntersectCvs: HTMLCanvasElement) {
        // code could be simpler but absolute priority has been given to performance by minimizing calls to drawImage() as possible
        if(this.globalExternalStrokeCvs == null) {
            if(layerRenderer.rawOpaqueStrokeCvs == null) {
                return;
            }
            this.initializedGlobalExternalStrokeCtx().globalCompositeOperation = 'source-over';
            if(layerRenderer.trimmedOpaqueStrokeCvs != null) {
                this.globalExternalStrokeCtx.drawImage(layerRenderer.trimmedOpaqueStrokeCvs, 0, 0);
            } else {// trimmedOpaqueStrokeCvs may be null if layer is not clippable
                this.globalExternalStrokeCtx.drawImage(layerRenderer.rawOpaqueStrokeCvs, 0, 0);

                if(this.globalFullCvs != null) {
                    // globalFullCvs has not been updated yet => it cumulates only previous layers (excluding the current)
                    this.globalExternalStrokeCtx.globalCompositeOperation = 'destination-out';
                    this.globalExternalStrokeCtx.drawImage(this.globalFullCvs, 0, 0);
                }
            }
            return;
        }
        // globalExternalStrokeCvs not null => means globalFullCvs != null necessarily
        if(layerRenderer.rawOpaqueStrokeCvs != null) {
            let strokeIntersectCtx : CanvasRenderingContext2D;
            // backup current layer stroke and global stroke intersection  (1)
            if (strokeIntersectCvs == null) {
                strokeIntersectCtx = this.initializedTmpCtx();
                strokeIntersectCvs = this.tmpCvs;
                strokeIntersectCtx.globalCompositeOperation = 'source-over';
                strokeIntersectCtx.drawImage(layerRenderer.rawOpaqueStrokeCvs, 0, 0);
            } else {
                strokeIntersectCtx = strokeIntersectCvs.getContext('2d');
            }

            strokeIntersectCtx.globalCompositeOperation = 'source-in';
            strokeIntersectCtx.drawImage(this.globalExternalStrokeCvs, 0, 0);
            // ---------------------------------------------------------------------

            // ------------------------- take out the global full from current layer stroke (2)------------------------
            this.initializedTmpCtx2().globalCompositeOperation = 'source-over';
            this.tmpCtx2.drawImage(layerRenderer.rawOpaqueStrokeCvs, 0, 0);

            // Note: globalFullCvs has not been updated yet => it cumulates only previous layers (excluding the current)
            // => subtracting it below is ok
            this.tmpCtx2.globalCompositeOperation = 'destination-out';
            this.tmpCtx2.drawImage(this.globalFullCvs, 0, 0);
            //-------------------------------------------------------------------------------------------------------
        }

        // take out the current layer full from global stroke  (3)
        this.globalExternalStrokeCtx.globalCompositeOperation = 'destination-out';
        this.globalExternalStrokeCtx.drawImage(layerRenderer.rawOpaqueFullCvs, 0, 0);


        if(layerRenderer.rawOpaqueStrokeCvs != null) {
            // merge (1) + (2) + (3)
            this.globalExternalStrokeCtx.globalCompositeOperation = 'source-over';
            this.globalExternalStrokeCtx.drawImage(this.tmpCvs2, 0, 0);
            this.globalExternalStrokeCtx.drawImage(strokeIntersectCvs, 0, 0);
        }
    }

    private resetAllGlobalContexts() {
        this.globalFullCvs = this.globalExternalStrokeCvs = null;
        this.globalFullCtx = this.globalExternalStrokeCtx = null;
    }


    repaint(ctx: CanvasRenderingContext2D): HTMLCanvasElement {
        if (!this.width) {
            return null;
        }

        // todo take into consideration zoom limit / cropping / scrolling
        const newWidth = this.scaledWidth();
        const newHeight = this.scaledHeight();
        if(ctx == null) {
            ctx = this.initializedTmpCtx();
        } else {
            ctx.clearRect(0, 0, newWidth, newHeight);
        }
        const cvs = ctx.canvas;
        cvs.width = newWidth;
        cvs.height = newHeight;
        ctx.scale(this.scaleX, this.scaleY);

        ctx.globalCompositeOperation = 'source-over';
        ctx.globalAlpha = 1;
        ctx.drawImage(this.img, 0, 0);
        this.drawLayers(ctx);

        ctx.scale(1 / this.scaleX, 1 / this.scaleY);


        this.tmpCtx = null;
        this.tmpCvs = null;
        return cvs;
    }

    private drawLayers(ctx: CanvasRenderingContext2D): PgnLayerRenderer {
        let cvs;
        let hoveredLayer = null;
        for (const layerRender of this.layers) {
            if (!layerRender.visible) {
                continue;
            }
            cvs = layerRender.getPreviewFullCvs(this.isEditModeActive);
            if (cvs != null) {
                ctx.drawImage(cvs, 0, 0);
            }
        }
        return hoveredLayer;
    }


    public scaledWidth(): number {
        return this.width * this.scaleX;
    }

    public scaledHeight(): number {
        return this.height * this.scaleY;
    }

    public viewPortWidth(): number {
        //todo
        return this.scaledWidth();
    }

    public viewPortHeight(): number {
        //todo
        return this.scaledHeight();
    }

    public initData() {
        this.refreshSize();
    }

    public initGraphics(document: Document) {
        this.initLayerGraphics(document);
    }

    private initLayerGraphics(document: Document) {
        if(this.layers.length === 0) return;
        for (const layer of this.layers) {
            layer.initGraphics(document);
        }
        const lowestClippableIndex = this.lowestClippableLayerIndexStarting(0);
        this.processOverlaps(0, this.layers.length - 1,
            PgnTrimCacheRefreshStrategy.ALWAYS, lowestClippableIndex);
        this.resetAllGlobalContexts();
        if(!this.isEditModeActive) {
            this.clearEditModeGraphicsCache();
        }
    }

    private lowestClippableLayerIndexStarting(startIndex: number) {
        for(let i = startIndex; i < this.layers.length; i ++) {
            const layer = this.layers[i];
            if(layer.isClippable && layer.rawOpaqueFullCvs != null) {
                return i;
            }
        }
        return this.layers.length;
    }

    private clearEditModeGraphicsCache() {
        for(const layer of this.layers) {
            layer.clearEditModeGraphicsCache();
        }
    }

    private initializedGlobalFullCvs(): HTMLCanvasElement {
        if(this.globalFullCvs == null) {
            this.globalFullCvs = this.createCanvas();
        }
        return this.globalFullCvs;
    }

    private initializedGlobalFullCtx(): CanvasRenderingContext2D {
        if(this.globalFullCtx == null) {
            this.globalFullCtx = this.initializedGlobalFullCvs().getContext('2d');
        }
        return this.globalFullCtx;
    }

    private initializedGlobalExternalStrokeCvs(): HTMLCanvasElement {
        if(this.globalExternalStrokeCvs == null) {
            this.globalExternalStrokeCvs = this.createCanvas();
        }
        return this.globalExternalStrokeCvs;
    }

    private initializedGlobalExternalStrokeCtx(): CanvasRenderingContext2D {
        if(this.globalExternalStrokeCtx == null) {
            this.globalExternalStrokeCtx = this.initializedGlobalExternalStrokeCvs().getContext('2d');
        }
        return this.globalExternalStrokeCtx;
    }

    private initializedTmpCvs(): HTMLCanvasElement {
        if(this.tmpCvs == null) {
            this.tmpCvs = this.createCanvas();
        }
        return this.tmpCvs;
    }

    private initializedTmpCtx(): CanvasRenderingContext2D {
        if(this.tmpCtx == null) {
            this.tmpCtx = this.initializedTmpCvs().getContext('2d');
        }
        return this.tmpCtx;
    }

    private initializedTmpCvs2(): HTMLCanvasElement {
        if(this.tmpCvs2 == null) {
            this.tmpCvs2 = this.createCanvas();
        }
        return this.tmpCvs2;
    }

    private initializedTmpCtx2(): CanvasRenderingContext2D {
        if(this.tmpCtx2 == null) {
            this.tmpCtx2 = this.initializedTmpCvs2().getContext('2d');
        }
        return this.tmpCtx2;
    }


    public createCanvas(): HTMLCanvasElement {
        const canvas = document.createElement('canvas') as HTMLCanvasElement;
        canvas.width = this.width;
        canvas.height = this.height;
        return canvas;
    }

    private indexOfLayer(layer: PgnLayerRenderer): number {
        for(let i = 0; i < this.layers.length; i ++) {
            if(this.layers[i] === layer) {
                return i;
            }
        }
        return -1;
    }

    public setPreviewCvs(previewCvs: HTMLCanvasElement): void {
        this.previewCvs = previewCvs;
        this.previewCtx = previewCvs.getContext('2d');
    }

    public setEditCvs(editCvs: HTMLCanvasElement): void {
        this.editCvs = editCvs;
        this.editCtx = editCvs.getContext('2d');
    }


    // ########################################### Edit Actions #####################################
    public layerReordered(reorderedLayer: PgnLayerRenderer, targetIndex: number): boolean {
        const startIndex = this.indexOfLayer(reorderedLayer);
        if(startIndex == -1 || startIndex == targetIndex) {
            return false;
        }
        const step = (startIndex < targetIndex) ? 1 : -1;
        for(let i = startIndex; i != targetIndex; i += step) {
            this.layers[i] = this.layers[i + step];
        }
        this.layers[targetIndex] = reorderedLayer;
        if(reorderedLayer.rawOpaqueFullCvs == null || !reorderedLayer.isClipping) {
            return true;
        }
        const start = (startIndex < targetIndex) ? startIndex : targetIndex;
        const end = (startIndex < targetIndex) ? targetIndex : startIndex;
        const lowestClippableIndex = this.lowestClippableLayerIndexStarting(start);
        this.processOverlaps(end + 1, this.layers.length - 1,
            PgnTrimCacheRefreshStrategy.NEVER, lowestClippableIndex);
        this.processOverlaps(start, end,
            PgnTrimCacheRefreshStrategy.ON_CLIPPABLE, lowestClippableIndex);
        this.resetAllGlobalContexts();

        this.fireRepaintAction(false);
        return true;
    }

    public layerDeleted(deletedLayer: PgnLayerRenderer): boolean {
        const deletedIndex = this.indexOfLayer(deletedLayer);
        if(deletedIndex == -1 ) {
            return false;
        }
        this.layers.splice(deletedIndex, 1);
        if(deletedLayer.rawOpaqueFullCvs == null || !deletedLayer.isClipping) {
            return true;
        }
        const lowestClippableIndex = this.lowestClippableLayerIndexStarting(0);
        this.processOverlaps(deletedIndex, this.layers.length - 1,
            PgnTrimCacheRefreshStrategy.NEVER, lowestClippableIndex);
        this.processOverlaps(0, deletedIndex - 1,
            PgnTrimCacheRefreshStrategy.ON_CLIPPABLE, lowestClippableIndex);
        this.resetAllGlobalContexts();
        this.fireRepaintAction(false);
        return true;
    }

    // if layerToClone == null => create new empty layer, else clone layer
    public newLayerAtIndex(layerToClone: PgnLayerRenderer, index: number) {
        const newLayer = layerToClone == null ? PgnLayerFactory.newLayer(this) : layerToClone.clone();
        this.layers.splice(index, 0, newLayer);
        if(newLayer.rawOpaqueFullCvs == null || !newLayer.isClipping) {
            if(newLayer.rawOpaqueFullCvs != null && newLayer.isClippable) {
                this.processOverlaps(this.layers.length - 1, index + 1,
                    PgnTrimCacheRefreshStrategy.NEVER, index);
                this.processOverlaps(index, index, PgnTrimCacheRefreshStrategy.ON_CLIPPABLE, index);
                this.resetAllGlobalContexts();
            }
            if(newLayer.rawOpaqueFullCvs != null && newLayer.visible) {
                this.fireRepaintAction(false);
            }
            return;
        }
        const clonedLayerIndex = this.indexOfLayer(layerToClone);
        if(clonedLayerIndex == -1) {
            const lowestClippableIndex = this.lowestClippableLayerIndexStarting(0);
            this.processOverlaps(index + 1, this.layers.length - 1,
                PgnTrimCacheRefreshStrategy.NEVER, lowestClippableIndex);
            this.processOverlaps(0, index,
                PgnTrimCacheRefreshStrategy.ON_CLIPPABLE, lowestClippableIndex);
            this.resetAllGlobalContexts();
            this.fireRepaintAction(false);
            return;
        }
        // if reached here means layerToClone was already in the graph && rawOpaqueFullCvs != null
        // => rawOpaqueFullCvs has already clipped all layers below layerToClone
        if(index > clonedLayerIndex) {
            this.adjustLayersTrimming(newLayer, clonedLayerIndex, index - 1);
        } else {
            this.adjustLayerTrimming(layerToClone, newLayer);
        }
        this.fireRepaintAction(false);
    }

    public layerFormChanged(changedLayer: PgnLayerRenderer): boolean {
        const layerIndex = this.indexOfLayer(changedLayer);
        if(layerIndex == -1 ) {
            return false;
        }
        const lowestClippableIndex = this.lowestClippableLayerIndexStarting(changedLayer.isClipping ? 0 : layerIndex);
        if(changedLayer.isClippable || changedLayer.isClipping) {
            this.processOverlaps(layerIndex + 1, this.layers.length - 1,
                PgnTrimCacheRefreshStrategy.NEVER, lowestClippableIndex);
        }
        // regadless of clippable / isClipping state, preview mode cache must be updated => use ALWAYS refresh strategy
        // Note: 'trim cache' and 'preview mode cache' are the same thing => even if layer is not clippable its 'trim cache' gets synthesized
        // from raw (as opposed to trimmed) graphics to optimize in preview mode
        this.processOverlaps(layerIndex, layerIndex, PgnTrimCacheRefreshStrategy.ALWAYS, lowestClippableIndex);
        if(changedLayer.isClipping) {
            this.processOverlaps(0, layerIndex - 1,
                PgnTrimCacheRefreshStrategy.ON_CLIPPABLE, lowestClippableIndex);
        }
        this.resetAllGlobalContexts();
        this.fireRepaintAction(false);
        return true;
    }

    // should be called only if the shape drag changed the form of both layers
    public shapeDraggedAcrossLayers(changedLayer1: PgnLayerRenderer,
                                    changedLayer2: PgnLayerRenderer,
                                    processOnlyChangedInterval: boolean): boolean {
        let index1 = this.indexOfLayer(changedLayer1);
        let index2 = this.indexOfLayer(changedLayer2);
        if(index1 == -1 || index2 == -1 || index1 == index2) {
            return false;
        }
        if(index1 > index2) {
            const tmpIndex = index1;
            index1 = index2;
            index2 = tmpIndex;

            changedLayer1 = this.layers[index1];
            changedLayer2 = this.layers[index2];
        }
        const lowestClippableIndex = this.lowestClippableLayerIndexStarting(processOnlyChangedInterval ? index1 : 0);
        if(changedLayer2.isClippable || changedLayer2.isClipping
            || changedLayer1.isClippable || (changedLayer1.isClipping && !processOnlyChangedInterval)) {
            this.processOverlaps(index2 + 1, this.layers.length - 1,
                PgnTrimCacheRefreshStrategy.NEVER, lowestClippableIndex);
        }
        this.processOverlaps(index2, index2, PgnTrimCacheRefreshStrategy.ALWAYS, lowestClippableIndex);
        if(changedLayer2.isClipping) {
            this.processOverlaps(index1 + 1, index2 - 1,
                PgnTrimCacheRefreshStrategy.ON_CLIPPABLE, lowestClippableIndex);
        } else if(changedLayer1.isClippable || (changedLayer1.isClipping && !processOnlyChangedInterval)) {
            this.processOverlaps(index1 + 1, index2 - 1,
                PgnTrimCacheRefreshStrategy.NEVER, lowestClippableIndex);
        }
        this.processOverlaps(index1, index1, PgnTrimCacheRefreshStrategy.ALWAYS, lowestClippableIndex);
        if(processOnlyChangedInterval) {
            this.resetAllGlobalContexts();
            this.fireRepaintAction(false);
            return true;
        }
        if(changedLayer1.isClipping || changedLayer2.isClipping) {
            this.processOverlaps(0, index1 - 1,
                PgnTrimCacheRefreshStrategy.ON_CLIPPABLE, lowestClippableIndex);
        }
        this.resetAllGlobalContexts();
        this.fireRepaintAction(false);
        return true;
    }

    public toNonClippable(layer: PgnLayerRenderer): boolean {
        let index = this.indexOfLayer(layer);
        if(index == -1 || layer.isClippable) {
            return false;
        }
        if(layer.rawOpaqueFullCvs == null) {
            return true;
        }
        // transition to non-clippable has no effect on the other layers
        this.processOverlaps(index, index, PgnTrimCacheRefreshStrategy.ALWAYS, index);
        this.resetAllGlobalContexts();
        this.fireRepaintAction(false);
        return true;
    }

    public toClippable(layer: PgnLayerRenderer): boolean {
        let index = this.indexOfLayer(layer);
        if(index == -1 || !layer.isClippable) {
            return false;
        }
        if(layer.rawOpaqueFullCvs == null) {
            return true;
        }
        this.processOverlaps(index + 1, this.layers.length - 1, PgnTrimCacheRefreshStrategy.NEVER, index);
        this.processOverlaps(index, index, PgnTrimCacheRefreshStrategy.ALWAYS, index);
        this.resetAllGlobalContexts();
        this.fireRepaintAction(false);
        return true;
    }

    public toNonClipping(layer: PgnLayerRenderer): boolean {
        let index = this.indexOfLayer(layer);
        if(index == -1 || layer.isClipping) {
            return false;
        }
        if(layer.rawOpaqueFullCvs == null) {
            return true;
        }
        const lowestClippableIndex = this.lowestClippableLayerIndexStarting(0);
        this.processOverlaps(index + 1, this.layers.length - 1,
            PgnTrimCacheRefreshStrategy.NEVER, lowestClippableIndex);
        this.processOverlaps(0, index - 1,
            PgnTrimCacheRefreshStrategy.ON_CLIPPABLE, lowestClippableIndex);
        this.resetAllGlobalContexts();
        this.fireRepaintAction(false);
        return true;
    }

    public toClipping(layer: PgnLayerRenderer): boolean {
        let index = this.indexOfLayer(layer);
        if(index == -1 || !layer.isClipping) {
            return false;
        }
        if(layer.rawOpaqueFullCvs == null) {
            return true;
        }
        this.adjustLayersTrimming(layer, 0, index -1);
        this.fireRepaintAction(false);
        return true;
    }

    public paddingChanged(leftPad: number, topPad: number, rightPad: number, bottomPad: number): boolean {
        if(leftPad == this.leftPad && topPad == this.topPad && rightPad == this.rightPad && bottomPad == this.bottomPad) {
            return false;
        }
        const dx = leftPad - this.leftPad;
        const dy = topPad - this.topPad;

        // todo update width and height of graph and all sub-layers and all sub-shapes + translate all (dx, dy)

        const lowestClippableIndex = this.lowestClippableLayerIndexStarting(0);
        this.processOverlaps(0, this.layers.length - 1,
            PgnTrimCacheRefreshStrategy.ALWAYS, lowestClippableIndex);
        this.resetAllGlobalContexts();
        this.fireRepaintAction(true);
        return true;
    }

    public scaleChanged(targetScaleX: number, targetScaleY: number) {
        this.scaleX = targetScaleX;
        this.scaleY = targetScaleY;
        this.fireRepaintAction(true);
    }

    public fireRepaintAction(potentialSizeChange: boolean) {
        // todo take into consideration zoom limit / cropping / scroll
        if(potentialSizeChange) {
            this.updateCvsSize(this.previewCvs);
            this.updateCvsSize(this.editCvs);
        }
        this.fireRepaintAction2(this.isEditMode ? this.editCtx : this.previewCtx);
    }

    private fireRepaintAction2(ctx: CanvasRenderingContext2D) {
        this.repaint(ctx);
    }

    private updateCvsSize(cvs: HTMLCanvasElement) {
        if(!cvs) {
            return;
        }
        cvs.width  = this.scaledWidth();
        cvs.height = this.scaledHeight();
    }
    // ############################################# End Edit #######################################

}
