import {PgnLayer} from './pgn-layer';
import {PgnShape} from '../shape/pgn-shape';
import {PgnGraphRenderer} from '../graph/pgn-graph-renderer';
import {PgnShapeRenderMode} from '../shape/pgn-shape-render-mode.enum';
import {PgnLayerGroup} from './pgn-layer-group';
import {PgnHoverResponsiveArea} from '../graph/pgn-hover-responsive-area.enum';
import {PgnShapeFactory} from '../shape/pgn-shape-factory';
import {PgnShapeType} from '../shape/pgn-shape-type.enum';

export class PgnLayerRenderer extends PgnLayer<PgnShape> {

    // -------------------------------- Transient vars ------------------------------------
    id: number;


    // ####################################### Edit Mode Cache ##################################################
    // ################################## Needed at Pre-processing Phase ########################################
    // ##########################################################################################################

    // a layer is made of multiple shapes with different fill/hoverFill colors and alphas
    // => 3 fill caches are necessary for the composite: 1 opaque for trimming logic + regular alpha + hover alpha
    rawOpaqueFullCvs: HTMLCanvasElement;// needed for trimming
    rawOpaqueFullCtx: CanvasRenderingContext2D;

    rawAlphaFillCvs: HTMLCanvasElement;
    rawAlphaFillCtx: CanvasRenderingContext2D;

    rawAlphaHoverFillCvs: HTMLCanvasElement;
    rawAlphaHoverFillCtx: CanvasRenderingContext2D;

    // stroke is common to all shapes (even across clipping layers) => only one stroke cache is necessary
    rawOpaqueStrokeCvs: HTMLCanvasElement;
    rawOpaqueStrokeCtx: CanvasRenderingContext2D;


    // needed for better performance under edit mode
    trimmedOpaqueFullCtx: CanvasRenderingContext2D;
    trimmedOpaqueFullCvs: HTMLCanvasElement;

    trimmedOpaqueStrokeCvs: HTMLCanvasElement;
    trimmedOpaqueStrokeCtx: CanvasRenderingContext2D;

    // ####################################### End Edit Mode Cache ##############################################
    // ##########################################################################################################


    // ####################################### Preview Mode Cache ###############################################
    // ########################## Used at Drawing/Pointer-interaction Phase #####################################
    // ##########################################################################################################

    trimmedAlphaFullCvs: HTMLCanvasElement;
    trimmedAlphaHoverFullCvs: HTMLCanvasElement;

    // needed to check whether mouse cursor is on top of hover responsive area (see isPixelWithinLayer() and PgnHoverResponsiveArea)
    hoverResponsiveBitmapCtx: CanvasRenderingContext2D;

    // ####################################### End Preview Mode Cache ###########################################
    // ##########################################################################################################


    //----------------------- short lived cache => pre-processing phase only -----------------------------------
    private tmpShapeFillCvs: HTMLCanvasElement;
    private tmpShapeFillCtx: CanvasRenderingContext2D;

    private tmpShapeStrokeCvs: HTMLCanvasElement;
    private tmpShapeStrokeCtx: CanvasRenderingContext2D;

    private tmpStrokeIntersectCvs: HTMLCanvasElement;
    private tmpStrokeIntersectCtx: CanvasRenderingContext2D;
    //------------------------------------------------------------------------------------------------------------


    // todo check usefulness
    isSelected = true;
    isHovered = false;
    isEditing = false;

    graphRenderer: PgnGraphRenderer;

    group: PgnLayerGroup;

    constructor() {
        super();
        this.shapes = new Array<PgnShape>();
    }

    public getPreviewFullCvs(alwaysDrawLayers: boolean): HTMLCanvasElement {
        if (this.isSelected || (alwaysDrawLayers && !this.isHovered)) {
            return this.trimmedAlphaFullCvs;
        }
        if (this.isHovered) {
            return this.trimmedAlphaHoverFullCvs;
        }
        return null;
    }

    public addShape(shape: PgnShape) {
        this.shapes.push(shape);
    }

    initGraphics(document: Document) {
        this.initShapeGraphics(document);
    }

    isPixelWithinLayer(x: number, y: number): boolean {
        if(this.hoverResponsiveBitmapCtx) {
            const alpha = this.hoverResponsiveBitmapCtx.getImageData(x, y, 1, 1).data[3];
            return alpha > 0;
        }
        const shape = this.getShapeIndexAt(x, y);
        return shape !== -1;
    }

    getShapeIndexAt(x: number, y: number): number {
        let shape;
        for(let i = 0; i < this.shapes.length; i ++) {
            shape = this.shapes[i];
            if(shape.contains(x, y)) {
                return i;
            }
        }
        return -1;
    }

    private initShapeGraphics(document: Document) {
        if(this.shapes.length === 0) {
            this.resetAllCache();
            return;
        }
        for (const shape of this.shapes) {
            shape.initGraphics(document);
        }
        this.combineShapes();
    }

    public combineShapes() {
        this.resetAllCache();
        let isFirstNSubtractShapes = true;
        for(const shape of this.shapes) {
            if(!shape.enabled || shape.isEditing || shape.isEmpty()) {
                continue;
            }
            if(shape.renderMode === PgnShapeRenderMode.ADD) {
                isFirstNSubtractShapes = false;
                this.combineShapeStrokeInAddMode(shape);
                this.combineShapeFullInAddMode(shape);
            } else if(!isFirstNSubtractShapes) { //PgnShapeRenderMode.SUBTRACT
                this.combineShapeStrokeInSubtractMode(shape);
                this.combineShapeFullInSubtractMode(shape);
            }
            this.resetTmpContexts();
        }
    }

    public clipIfClippable(clippingFullCvs: HTMLCanvasElement, clippingStrokeCvs: HTMLCanvasElement): HTMLCanvasElement {
        if(this.rawOpaqueFullCvs == null || !this.isClippable) {
            return null;
        }
        this.opaqueDestinationOut(this.initializedTrimmedOpaqueFullCtx(), this.rawOpaqueFullCvs, clippingFullCvs);
        if(this.rawOpaqueStrokeCvs != null) {
            this.opaqueDestinationOut(this.initializedTrimmedOpaqueStrokeCtx(), this.rawOpaqueStrokeCvs, clippingFullCvs);
        }
        if(clippingStrokeCvs != null && this.rawOpaqueStrokeCvs != null) {
            // add clipping stroke to trimmed edge => applies to both full and stroke only when current layer has a stroke

            this.initializedTmpShapeStrokeCtx().globalCompositeOperation = 'source-over';
            this.tmpShapeStrokeCtx.drawImage(clippingStrokeCvs, 0, 0);

            this.tmpShapeStrokeCtx.globalCompositeOperation = 'source-in';
            this.tmpShapeStrokeCtx.drawImage(this.rawOpaqueFullCvs, 0, 0);

            this.trimmedOpaqueFullCtx.globalCompositeOperation = 'source-over';
            this.trimmedOpaqueFullCtx.drawImage(this.tmpShapeStrokeCvs, 0, 0);

            this.trimmedOpaqueStrokeCtx.globalCompositeOperation = 'source-over';
            this.trimmedOpaqueStrokeCtx.drawImage(this.tmpShapeStrokeCvs, 0, 0);

            const tmpCvs = this.tmpShapeStrokeCvs;
            // reset tmp graphic context
            this.tmpShapeStrokeCtx = null;
            this.tmpShapeStrokeCvs = null;

            // just a small optimization to avoid an extra drawImage() call from the caller
            return tmpCvs;
        }
        return null;
    }

    private opaqueDestinationOut(clippableCtx: CanvasRenderingContext2D,
                                 toClipFullCvs: HTMLCanvasElement,
                                 clippingCvs: HTMLCanvasElement) {
        clippableCtx.globalCompositeOperation = 'source-over';
        clippableCtx.drawImage(toClipFullCvs, 0, 0);

        clippableCtx.globalCompositeOperation = 'destination-out';
        clippableCtx.drawImage(clippingCvs, 0, 0);
    }

    private combineShapeStrokeInAddMode(shape: PgnShape) {
        if (this.actualStrokeWidth() == 0) {
            return;
        }

        if (shape.opaqueStrokeCvs() != null) {
            this.initializedTmpShapeStrokeCtx().globalCompositeOperation = 'source-over';
            this.tmpShapeStrokeCtx.drawImage(shape.opaqueStrokeCvs(), 0, 0);

            if(this.rawOpaqueFullCvs != null) {
                // take out the cumulative full cvs from current shape stroke
                this.tmpShapeStrokeCtx.globalCompositeOperation = 'destination-out';
                this.tmpShapeStrokeCtx.drawImage(this.rawOpaqueFullCvs, 0, 0);

                if(this.rawOpaqueStrokeCvs != null) {
                    // __________________________ extract strokes intersection _________________________
                    this.initializedTmpStrokeIntersectCtx().globalCompositeOperation = 'source-over';
                    this.tmpStrokeIntersectCtx.drawImage(this.rawOpaqueStrokeCvs, 0, 0);

                    this.tmpStrokeIntersectCtx.globalCompositeOperation = 'source-in';
                    this.tmpStrokeIntersectCtx.drawImage(shape.opaqueStrokeCvs(), 0, 0);
                    // __________________________________________________________________________________

                    // restore strokes intersection
                    this.tmpShapeStrokeCtx.globalCompositeOperation = 'source-over';
                    this.tmpShapeStrokeCtx.drawImage(this.tmpStrokeIntersectCvs, 0, 0);
                }
            }
        }

        if(shape.opaqueFillCvs() != null && this.rawOpaqueStrokeCvs != null) {
            // trim current shape fill from cumulative stroke
            this.initializedRawOpaqueStrokeCtx().globalCompositeOperation = 'destination-out';
            this.rawOpaqueStrokeCtx.drawImage(shape.opaqueFillCvs(), 0, 0);
        }

        if (shape.opaqueStrokeCvs() != null) {
            // add new shape trimmed stroke
            this.initializedRawOpaqueStrokeCtx().globalCompositeOperation = 'source-over';
            this.rawOpaqueStrokeCtx.drawImage(this.tmpShapeStrokeCvs, 0, 0);
        }
    }

    private combineShapeFullInAddMode(shape: PgnShape) {
        const width = this.graphRenderer.width;
        const height = this.graphRenderer.height;

        // ######################### add up to raw opaque full cvs (fill + stroke) #########################
        if(shape.opaqueFillCvs() != null || shape.opaqueStrokeCvs() != null) {
            this.initializedRawOpaqueFullCtx().globalCompositeOperation = 'source-over';
        }
        if(shape.opaqueFillCvs() != null) {
            this.initializedRawOpaqueFullCtx().drawImage(shape.opaqueFillCvs(), 0, 0);
        }
        if(shape.opaqueStrokeCvs() != null) {
            this.initializedRawOpaqueFullCtx().drawImage(this.tmpShapeStrokeCvs, 0, 0);
        }
        // #################################################################################################



        if(shape.opaqueFillCvs() != null) {
            // ########## add up to non opaque fill (multiple alphas are supported (1 per sub-shape)) #############
            if(this.rawAlphaFillCtx != null) {
                this.rawAlphaFillCtx.globalAlpha = 1;// to reset
                this.rawAlphaFillCtx.globalCompositeOperation = 'destination-out';
                this.rawAlphaFillCtx.drawImage(shape.opaqueFillCvs(), 0, 0);
            }

            if(shape.fillOpacity > 0) {
                this.initializedRawAlphaFillCtx().globalCompositeOperation = 'source-over';
                this.rawAlphaFillCtx.globalAlpha = shape.fillOpacity;
                this.rawAlphaFillCtx.drawImage(shape.opaqueFillCvs(), 0, 0);
            }
            // ###################################################################################################


            // ##### add up to non opaque hover fill (multiple hover alphas are supported (1 per sub-shape)) ######
            if(this.rawAlphaHoverFillCtx != null) {
                this.rawAlphaHoverFillCtx.globalAlpha = 1;// to reset
                this.rawAlphaHoverFillCtx.globalCompositeOperation = 'destination-out';
                this.rawAlphaHoverFillCtx.drawImage(shape.opaqueFillCvs(), 0, 0);
            }

            if(shape.hFillOpacity > 0) {
                //____________________ re-color shape image in hover opaque color _______________
                // below cannot be performed directly on rawAlphaHoverFillCtx for optimization because it is cumulative over multiple shapes
                this.initializedTmpShapeFillCtx().globalCompositeOperation = 'source-over';
                this.tmpShapeFillCtx.drawImage(shape.opaqueFillCvs(), 0, 0);

                this.tmpShapeFillCtx.globalCompositeOperation = 'source-in';
                this.tmpShapeFillCtx.fillStyle = shape.hoverFill;
                this.tmpShapeFillCtx.fillRect(0, 0, width, height);
                //________________________________________________________________________________

                // draw shape in hover color & opacity
                this.initializedRawAlphaHoverFillCtx().globalCompositeOperation = 'source-over';
                this.rawAlphaHoverFillCtx.globalAlpha = shape.hFillOpacity;
                this.rawAlphaHoverFillCtx.drawImage(this.tmpShapeFillCvs, 0, 0);
            }
            // ###################################################################################################
        }
    }

    private combineShapeStrokeInSubtractMode(shape: PgnShape) {
        if (this.actualStrokeWidth() == 0 || this.rawOpaqueFullCvs == null) {
            return;
        }

        if (shape.opaqueStrokeCvs() != null) {
            // __________________________ extract new stroke portion to add _________________________
            this.initializedTmpStrokeIntersectCtx().globalCompositeOperation = 'source-over';
            this.tmpStrokeIntersectCtx.drawImage(shape.opaqueStrokeCvs(), 0, 0);

            this.tmpStrokeIntersectCtx.globalCompositeOperation = 'source-in';
            this.tmpStrokeIntersectCtx.drawImage(this.rawOpaqueFullCvs, 0, 0);
            // __________________________________________________________________________________
        }


        if (shape.opaqueFillCvs() != null && this.rawOpaqueStrokeCtx != null) {
            // take out the current shape fill from cumulative stroke
            this.rawOpaqueStrokeCtx.globalCompositeOperation = 'destination-out';
            this.rawOpaqueStrokeCtx.drawImage(shape.opaqueFillCvs(), 0, 0);
        }

        if(shape.opaqueStrokeCvs() != null) {
            // add new extracted stroke portion
            this.initializedRawOpaqueStrokeCtx().globalCompositeOperation = 'source-over';
            this.rawOpaqueStrokeCtx.drawImage(this.tmpStrokeIntersectCvs, 0, 0);
        }
    }


    private combineShapeFullInSubtractMode(shape: PgnShape) {
        if(shape.opaqueFillCvs() != null && this.rawOpaqueFullCtx != null) {
            this.rawOpaqueFullCtx.globalCompositeOperation = 'destination-out';
            this.rawOpaqueFullCtx.drawImage(shape.opaqueFillCvs(), 0, 0);
        }

        if(this.actualStrokeWidth() > 0 && this.rawOpaqueFullCvs != null && shape.opaqueStrokeCvs() != null) {
            // this.tmpStrokeIntersectCvs is initialized at combineShapeStrokeInSubtractMode() if and only if the the 3 above conditions are met
            this.rawOpaqueFullCtx.globalCompositeOperation = 'source-over';
            this.rawOpaqueFullCtx.drawImage(this.tmpStrokeIntersectCvs, 0, 0);
        }

        if(this.rawAlphaFillCtx != null) {
            this.rawAlphaFillCtx.globalAlpha = 1;// to reset
            this.rawAlphaFillCtx.globalCompositeOperation = 'destination-out';
            if (shape.opaqueFillCvs() != null) {
                this.rawAlphaFillCtx.drawImage(shape.opaqueFillCvs(), 0, 0);
            }
            if (shape.opaqueStrokeCvs() != null) {
                this.rawAlphaFillCtx.drawImage(shape.opaqueStrokeCvs(), 0, 0);
            }
        }


        if(this.rawAlphaHoverFillCtx != null) {
            this.rawAlphaHoverFillCtx.globalAlpha = 1;// to reset
            this.rawAlphaHoverFillCtx.globalCompositeOperation = 'destination-out';
            if (shape.opaqueFillCvs() != null) {
                this.rawAlphaHoverFillCtx.drawImage(shape.opaqueFillCvs(), 0, 0);
            }
            if (shape.opaqueStrokeCvs() != null) {
                this.rawAlphaHoverFillCtx.drawImage(shape.opaqueStrokeCvs(), 0, 0);
            }
        }
    }

    public refreshTrimmedCache() {
        this.resetPreviewModeCache();
        if(!this.isClippable || this.trimmedOpaqueFullCvs == null) {
            this.applyPreviewRawDefaultCache();
            return;
        }

        const tmpTrimmedOpaqueFillCvs = this.synthetizeFillCvs(this.trimmedOpaqueFullCvs, this.trimmedOpaqueStrokeCvs);

        const trimmedAlphaFullCtx = this.initializedTrimmedAlphaFullCtx();
        this.drawPreviewAlphaFill(trimmedAlphaFullCtx, tmpTrimmedOpaqueFillCvs, this.rawAlphaFillCvs);

        const trimmedAlphaHoverFullCtx = this.initializedTrimmedAlphaHoverFullCtx();
        this.drawPreviewAlphaFill(trimmedAlphaHoverFullCtx, tmpTrimmedOpaqueFillCvs, this.rawAlphaHoverFillCvs);

        this.drawPreviewAlphaStrokeIfApplicable(trimmedAlphaFullCtx, this.trimmedOpaqueStrokeCvs, this.actualStrokeColor(), this.actualStrokeOpacity());
        this.drawPreviewAlphaStrokeIfApplicable(trimmedAlphaHoverFullCtx, this.trimmedOpaqueStrokeCvs, this.actualHStrokeColor(), this.actualHStrokeOpacity());

        if(this.hoverResponsiveArea == PgnHoverResponsiveArea.STROKE) {
            this.processResponsiveBitMapForStrokeMode(this.trimmedOpaqueStrokeCvs);
        } else if(this.hoverResponsiveArea == PgnHoverResponsiveArea.FULL) {
            this.processResponsiveBitMapForFullMode(this.trimmedOpaqueFullCvs);
        } else if(this.hoverResponsiveArea == PgnHoverResponsiveArea.FILL) {
            this.processResponsiveBitMapForFillMode(tmpTrimmedOpaqueFillCvs);
        } else {// this.hoverResponsiveArea == PgnHoverResponsiveArea.NONE
            this.hoverResponsiveArea = null;
        }
    }

    private applyPreviewRawDefaultCache() {
        if(this.rawOpaqueFullCvs == null) {
            return;
        }
        this.trimmedAlphaFullCvs = this.synthetizeFillCvs(this.rawAlphaFillCvs, this.rawOpaqueStrokeCvs);
        if(this.trimmedAlphaFullCvs != null) {
            this.drawPreviewAlphaStrokeIfApplicable(this.initializedTrimmedAlphaFullCtx(), this.rawOpaqueStrokeCvs, this.actualStrokeColor(), this.actualStrokeOpacity());
        }

        this.trimmedAlphaHoverFullCvs = this.synthetizeFillCvs(this.rawAlphaHoverFillCvs, this.rawOpaqueStrokeCvs);
        if(this.trimmedAlphaHoverFullCvs != null) {
            this.drawPreviewAlphaStrokeIfApplicable(this.initializedTrimmedAlphaHoverFullCtx(), this.rawOpaqueStrokeCvs, this.actualHStrokeColor(), this.actualHStrokeOpacity());
        }

        if(this.hoverResponsiveArea == PgnHoverResponsiveArea.STROKE) {
            this.processResponsiveBitMapForStrokeMode(this.rawOpaqueStrokeCvs);
        } else if(this.hoverResponsiveArea == PgnHoverResponsiveArea.FULL) {
            this.processResponsiveBitMapForFullMode(this.rawOpaqueFullCvs);
        } else if(this.hoverResponsiveArea == PgnHoverResponsiveArea.FILL) {
            this.processResponsiveBitMapForFillMode(null);
        } else {// this.hoverResponsiveArea == PgnHoverResponsiveArea.NONE
            this.hoverResponsiveArea = null;
        }

    }

    private processResponsiveBitMapForStrokeMode(opaqueStrokeCvs: HTMLCanvasElement) {
        if(opaqueStrokeCvs == null) {
            return;
        }
        //-------------------- try reusing preview contexts if possible to optimize and preserve memory-----------------------
        if(this.trimmedAlphaFullCvs != null &&
            (this.rawOpaqueStrokeCvs == null || this.actualStrokeOpacity() > 0) &&
            this.rawAlphaFillCvs == null) {
            this.hoverResponsiveBitmapCtx = this.trimmedAlphaFullCvs.getContext('2d');
            return;
        }
        if(this.trimmedAlphaHoverFullCvs != null &&
            (this.rawOpaqueStrokeCvs == null || this.actualHStrokeOpacity() > 0) &&
            this.rawAlphaHoverFillCvs == null) {
            this.hoverResponsiveBitmapCtx = this.trimmedAlphaHoverFullCvs.getContext('2d');
            return;
        }
        //---------------------------------------------------- reuse end -----------------------------------------------------
        this.hoverResponsiveBitmapCtx = opaqueStrokeCvs.getContext('2d');
    }

    private processResponsiveBitMapForFullMode(opaqueFullCvs: HTMLCanvasElement) {
        if(opaqueFullCvs == null) {
            return;
        }
        //-------------------- try reusing preview contexts if possible to optimize and preserve memory-----------------------
        if(this.trimmedAlphaFullCvs != null &&
            (this.rawOpaqueStrokeCvs == null || this.actualStrokeOpacity() > 0) &&
            this.forAllRelevantSubshapes(shape => shape.isFillAlphaRenderingResuableInResponsiveBitmap(shape.fillOpacity))) {
            this.hoverResponsiveBitmapCtx = this.trimmedAlphaFullCvs.getContext('2d');
            return;
        }
        if(this.trimmedAlphaHoverFullCvs != null &&
            (this.rawOpaqueStrokeCvs == null || this.actualHStrokeOpacity() > 0) &&
            this.forAllRelevantSubshapes(shape => shape.isFillAlphaRenderingResuableInResponsiveBitmap(shape.hFillOpacity))) {
            this.hoverResponsiveBitmapCtx = this.trimmedAlphaHoverFullCvs.getContext('2d');
            return;
        }
        //---------------------------------------------------- reuse end -----------------------------------------------------
        this.hoverResponsiveBitmapCtx = opaqueFullCvs.getContext('2d');

    }

    // if trimmedOpaqueFillCvs == null and cannot reuse preview mode contexts => synthesize from raw mode
    private processResponsiveBitMapForFillMode(trimmedOpaqueFillCvs: HTMLCanvasElement) {
        //-------------------- try reusing preview contexts if possible to optimize and preserve memory-----------------------
        if(this.trimmedAlphaFullCvs != null &&
            (this.rawOpaqueStrokeCvs == null || this.actualStrokeOpacity() == 0) &&
            this.forAllRelevantSubshapes(shape => shape.isFillAlphaRenderingResuableInResponsiveBitmap(shape.fillOpacity))) {
            this.hoverResponsiveBitmapCtx = this.trimmedAlphaFullCvs.getContext('2d');
            return;
        }
        if(this.trimmedAlphaHoverFullCvs != null &&
            (this.rawOpaqueStrokeCvs == null || this.actualHStrokeOpacity() == 0) &&
            this.forAllRelevantSubshapes(shape => shape.isFillAlphaRenderingResuableInResponsiveBitmap(shape.hFillOpacity))) {
            this.hoverResponsiveBitmapCtx = this.trimmedAlphaHoverFullCvs.getContext('2d');
            return;
        }
        //---------------------------------------------------- reuse end -----------------------------------------------------

        if(trimmedOpaqueFillCvs != null) {
            this.hoverResponsiveBitmapCtx = trimmedOpaqueFillCvs.getContext('2d');
            return;
        }
        this.hoverResponsiveBitmapCtx = this.synthetizeFillCvs(this.rawOpaqueFullCvs, this.rawOpaqueStrokeCvs).getContext('2d');
    }

    private forAllRelevantSubshapes(subShapePredicate: (shape: PgnShape) => boolean): boolean {
        return this.forRelevantSubshapesBetween(0, this.shapes.length - 1, subShapePredicate);
    }

    // indexes are inclusive
    private forRelevantSubshapesBetween(startIndex: number, endIndex: number, subShapePredicate: (shape: PgnShape) => boolean): boolean {
        for(let i = startIndex; i <= endIndex; i ++) {
            const shape = this.shapes[i];
            if(!shape.hasGraphEffect()) {
                continue;
            }
            if(!subShapePredicate(shape)) {
                return false;
            }
        }
        return true;
    }

    private synthetizeFillCvs(fullCvs: HTMLCanvasElement, strokeCvs: HTMLCanvasElement): HTMLCanvasElement {
        if(fullCvs == null) {
            return null;
        }
        this.initializedTmpShapeFillCtx().globalCompositeOperation = 'source-over';
        this.tmpShapeFillCtx.drawImage(fullCvs, 0, 0);
        const tmpOpaqueFillCsv = this.tmpShapeFillCvs;

        if(strokeCvs != null ) {
            this.tmpShapeFillCtx.globalCompositeOperation = 'destination-out';
            this.tmpShapeFillCtx.drawImage(strokeCvs, 0, 0);
        }

        this.tmpShapeFillCvs = null;
        this.tmpShapeFillCtx = null;
        return tmpOpaqueFillCsv;
    }



    private drawPreviewAlphaStrokeIfApplicable(alphaFullCtx: CanvasRenderingContext2D,
                                               opaqueStrokeCvs: HTMLCanvasElement,
                                               strokeColor: string,
                                               strokeOpacity: number) {
        if(strokeOpacity == 0 || opaqueStrokeCvs == null) {
            return;
        }
        //using tmpShapeStroke() just as a tmp context to avoid re-writing instantiation code => the variable is rather used as tmpLayerStroke()
        this.initializedTmpShapeStrokeCtx().globalAlpha = 1;
        this.tmpShapeStrokeCtx.globalCompositeOperation = 'source-over';
        this.tmpShapeStrokeCtx.drawImage(opaqueStrokeCvs, 0, 0);

        this.tmpShapeStrokeCtx.fillStyle = strokeColor;
        this.tmpShapeStrokeCtx.globalAlpha = strokeOpacity;
        this.tmpShapeStrokeCtx.globalCompositeOperation = 'source-in';
        this.tmpShapeStrokeCtx.fillRect(0, 0, this.graphRenderer.width, this.graphRenderer.height);

        alphaFullCtx.globalAlpha = 1;
        alphaFullCtx.globalCompositeOperation = 'source-over';
        alphaFullCtx.drawImage(this.tmpShapeStrokeCvs, 0, 0);

        this.tmpShapeStrokeCtx = null;
        this.tmpShapeStrokeCvs = null;
    }

    private drawPreviewAlphaFill(alphaFullCtx: CanvasRenderingContext2D,
                                 opaqueFillCvs: HTMLCanvasElement,
                                 rawAlphaFillCvs: HTMLCanvasElement) {
        if(rawAlphaFillCvs == null) {
            return;
        }
        alphaFullCtx.globalAlpha = 1;
        alphaFullCtx.globalCompositeOperation = 'source-over';
        alphaFullCtx.drawImage(opaqueFillCvs, 0, 0);


        alphaFullCtx.globalCompositeOperation = 'source-in';
        alphaFullCtx.drawImage(rawAlphaFillCvs, 0, 0);
    }

    public clearEditModeGraphicsCache() {
        for(const shape of this.shapes) {
            shape.clearEditModeGraphicsCache();
        }
        this.resetEditModeCache();
    }

    public actualStrokeWidth(): number {
        return this.isClipping || this.isClippable ? this.graphRenderer.strokeWidth : this.strokeWidth;
    }

    public actualStrokeColor(): string {
        return this.isClipping || this.isClippable ? this.graphRenderer.strokeColor : this.strokeColor;
    }

    public actualHStrokeColor(): string {
        return this.isClipping || this.isClippable ? this.graphRenderer.hStrokeColor : this.hStrokeColor;
    }

    public actualStrokeOpacity(): number {
        return this.isClipping || this.isClippable ? this.graphRenderer.strokeOpacity : this.strokeOpacity;
    }

    public actualHStrokeOpacity(): number {
        return this.isClipping || this.isClippable ? this.graphRenderer.hStrokeOpacity : this.hStrokeOpacity;
    }


    // ####################################### Edit Mode Cache ##################################################
    // ################################## Needed at Pre-processing Phase ########################################
    // ##########################################################################################################

    private initializedRawOpaqueFullCvs(): HTMLCanvasElement {
        if(this.rawOpaqueFullCvs == null) {
            this.rawOpaqueFullCvs = this.createCanvas();
        }
        return this.rawOpaqueFullCvs;
    }

    private initializedRawOpaqueFullCtx(): CanvasRenderingContext2D {
        if(this.rawOpaqueFullCtx == null) {
            this.rawOpaqueFullCtx = this.initializedRawOpaqueFullCvs().getContext('2d');
        }
        return this.rawOpaqueFullCtx;
    }

    private initializedRawOpaqueStrokeCvs(): HTMLCanvasElement {
        if(this.rawOpaqueStrokeCvs == null) {
            this.rawOpaqueStrokeCvs = this.createCanvas();
        }
        return this.rawOpaqueStrokeCvs;
    }

    private initializedRawOpaqueStrokeCtx(): CanvasRenderingContext2D {
        if(this.rawOpaqueStrokeCtx == null) {
            this.rawOpaqueStrokeCtx = this.initializedRawOpaqueStrokeCvs().getContext('2d');
        }
        return this.rawOpaqueStrokeCtx;
    }


    private initializedRawAlphaFillCvs(): HTMLCanvasElement {
        if(this.rawAlphaFillCvs == null) {
            this.rawAlphaFillCvs = this.createCanvas();
        }
        return this.rawAlphaFillCvs;
    }

    private initializedRawAlphaFillCtx(): CanvasRenderingContext2D {
        if(this.rawAlphaFillCtx == null) {
            this.rawAlphaFillCtx = this.initializedRawAlphaFillCvs().getContext('2d');
        }
        return this.rawAlphaFillCtx;
    }


    private initializedRawAlphaHoverFillCvs(): HTMLCanvasElement {
        if(this.rawAlphaHoverFillCvs == null) {
            this.rawAlphaHoverFillCvs = this.createCanvas();
        }
        return this.rawAlphaHoverFillCvs;
    }

    private initializedRawAlphaHoverFillCtx(): CanvasRenderingContext2D {
        if(this.rawAlphaHoverFillCtx == null) {
            this.rawAlphaHoverFillCtx = this.initializedRawAlphaHoverFillCvs().getContext('2d');
        }
        return this.rawAlphaHoverFillCtx;
    }

    public initializedTrimmedOpaqueFullCvs(): HTMLCanvasElement {
        if(this.trimmedOpaqueFullCvs == null) {
            this.trimmedOpaqueFullCvs = this.createCanvas();
        }
        return this.trimmedOpaqueFullCvs;
    }

    public initializedTrimmedOpaqueFullCtx(): CanvasRenderingContext2D {
        if(this.trimmedOpaqueFullCtx == null) {
            this.trimmedOpaqueFullCtx = this.initializedTrimmedOpaqueFullCvs().getContext('2d');
        }
        return this.trimmedOpaqueFullCtx;
    }

    public initializedTrimmedOpaqueStrokeCvs(): HTMLCanvasElement {
        if(this.trimmedOpaqueStrokeCvs == null) {
            this.trimmedOpaqueStrokeCvs = this.createCanvas();
        }
        return this.trimmedOpaqueStrokeCvs;
    }

    public initializedTrimmedOpaqueStrokeCtx(): CanvasRenderingContext2D {
        if(this.trimmedOpaqueStrokeCtx == null) {
            this.trimmedOpaqueStrokeCtx = this.initializedTrimmedOpaqueStrokeCvs().getContext('2d');
        }
        return this.trimmedOpaqueStrokeCtx;
    }

    // ####################################### End Edit Mode Cache ##############################################
    // ##########################################################################################################



    // ####################################### Preview Mode Cache ###############################################
    // ########################## Used at Drawing/Pointer-interaction Phase #####################################
    // ##########################################################################################################

    public initializedTrimmedAlphaFullCvs(): HTMLCanvasElement {
        if(this.trimmedAlphaFullCvs == null) {
            this.trimmedAlphaFullCvs = this.createCanvas();
        }
        return this.trimmedAlphaFullCvs;
    }

    public initializedTrimmedAlphaFullCtx(): CanvasRenderingContext2D {
        return this.initializedTrimmedAlphaFullCvs().getContext('2d');
    }

    public initializedTrimmedAlphaHoverFullCvs(): HTMLCanvasElement {
        if(this.trimmedAlphaHoverFullCvs == null) {
            this.trimmedAlphaHoverFullCvs = this.createCanvas();
        }
        return this.trimmedAlphaHoverFullCvs;
    }

    public initializedTrimmedAlphaHoverFullCtx(): CanvasRenderingContext2D {
        return this.initializedTrimmedAlphaHoverFullCvs().getContext('2d');
    }

    private initializedHoverResponsiveBitmapCtx(): CanvasRenderingContext2D {
        if(this.hoverResponsiveBitmapCtx == null) {
            this.hoverResponsiveBitmapCtx = this.createCanvas().getContext('2d');
        }
        return this.hoverResponsiveBitmapCtx;
    }

    // ####################################### End Preview Mode Cache ###########################################
    // ##########################################################################################################


    // #################### short lived cache => pre-processing phase only ######################################
    // ##########################################################################################################

    private initializedTmpShapeFillCvs(): HTMLCanvasElement {
        if(this.tmpShapeFillCvs == null) {
            this.tmpShapeFillCvs = this.createCanvas();
        }
        return this.tmpShapeFillCvs;
    }

    private initializedTmpShapeFillCtx(): CanvasRenderingContext2D {
        if(this.tmpShapeFillCtx == null) {
            this.tmpShapeFillCtx = this.initializedTmpShapeFillCvs().getContext('2d');
        }
        return this.tmpShapeFillCtx;
    }


    private initializedTmpShapeStrokeCvs(): HTMLCanvasElement {
        if(this.tmpShapeStrokeCvs == null) {
            this.tmpShapeStrokeCvs = this.createCanvas();
        }
        return this.tmpShapeStrokeCvs;
    }

    private initializedTmpShapeStrokeCtx(): CanvasRenderingContext2D {
        if(this.tmpShapeStrokeCtx == null) {
            this.tmpShapeStrokeCtx = this.initializedTmpShapeStrokeCvs().getContext('2d');
        }
        return this.tmpShapeStrokeCtx;
    }


    private initializedTmpStrokeIntersectCvs(): HTMLCanvasElement {
        if(this.tmpStrokeIntersectCvs == null) {
            this.tmpStrokeIntersectCvs = this.createCanvas();
        }
        return this.tmpStrokeIntersectCvs;
    }

    private initializedTmpStrokeIntersectCtx(): CanvasRenderingContext2D {
        if(this.tmpStrokeIntersectCtx == null) {
            this.tmpStrokeIntersectCtx = this.initializedTmpStrokeIntersectCvs().getContext('2d');
        }
        return this.tmpStrokeIntersectCtx;
    }

    // ####################################### End Short Lived Cache ############################################
    // ##########################################################################################################


    private resetTmpContexts() {
        this.tmpShapeStrokeCtx = this.tmpShapeFillCtx = this.tmpStrokeIntersectCtx = null;
        this.tmpShapeStrokeCvs = this.tmpShapeFillCvs = this.tmpStrokeIntersectCvs = null;
    }

    private resetAllCache() {
        this.resetEditModeCache();
        this.resetPreviewModeCache();
    }

    private resetPreviewModeCache() {
        this.trimmedAlphaFullCvs
            = this.trimmedAlphaHoverFullCvs = null;
        this.hoverResponsiveBitmapCtx = null;
    }

    private resetEditModeCache() {
        this.rawOpaqueFullCvs
            = this.rawOpaqueStrokeCvs
            = this.rawAlphaFillCvs
            = this.rawAlphaHoverFillCvs
            = this.trimmedOpaqueFullCvs
            = this.trimmedOpaqueStrokeCvs = null;

        this.rawOpaqueFullCtx
            = this.rawOpaqueStrokeCtx
            = this.rawAlphaFillCtx
            = this.rawAlphaHoverFillCtx
            = this.trimmedOpaqueFullCtx
            = this.trimmedOpaqueStrokeCtx = null;
    }

    public resetTrimCache() {
        this.trimmedOpaqueFullCvs
            = this.trimmedOpaqueStrokeCvs
            = this.trimmedAlphaFullCvs
            = this.trimmedAlphaHoverFullCvs = null;

        this.trimmedOpaqueFullCtx = this.trimmedOpaqueStrokeCtx = this.hoverResponsiveBitmapCtx = null;
    }

    public clone(): PgnLayerRenderer {
        const clone = new PgnLayerRenderer();
        clone.graphRenderer = this.graphRenderer;
        clone.name =  this.name + '-copy';
        if(this.rawOpaqueFullCvs != null) {
            clone.initializedRawOpaqueFullCtx().drawImage(this.rawOpaqueFullCvs, 0, 0);
        }
        if(this.rawAlphaFillCvs != null) {
            clone.initializedRawAlphaFillCtx().drawImage(this.rawAlphaFillCvs, 0, 0);
        }
        if(this.rawAlphaHoverFillCvs != null) {
            clone.initializedRawAlphaHoverFillCtx().drawImage(this.rawAlphaHoverFillCvs, 0, 0);
        }
        if(this.rawOpaqueStrokeCvs != null) {
            clone.initializedRawOpaqueStrokeCtx().drawImage(this.rawOpaqueStrokeCvs, 0, 0);
        }
        if(this.trimmedOpaqueFullCvs != null) {
            clone.initializedTrimmedOpaqueFullCtx().drawImage(this.trimmedOpaqueFullCvs, 0, 0);
        }
        if(this.trimmedOpaqueStrokeCvs != null) {
            clone.initializedTrimmedOpaqueStrokeCtx().drawImage(this.trimmedOpaqueStrokeCvs, 0, 0);
        }
        if(this.trimmedAlphaFullCvs != null) {
            clone.initializedTrimmedAlphaFullCtx().drawImage(this.trimmedAlphaFullCvs, 0, 0);
        }
        if(this.trimmedAlphaHoverFullCvs != null) {
            clone.initializedTrimmedAlphaHoverFullCtx().drawImage(this.trimmedAlphaHoverFullCvs, 0, 0);
        }
        if(this.hoverResponsiveBitmapCtx != null) {
            clone.hoverResponsiveBitmapCtx = this.hoverResponsiveBitmapReusableClone(
                this.ctx(this.trimmedAlphaFullCvs), this.ctx(clone.trimmedAlphaFullCvs),
                this.ctx(this.trimmedAlphaHoverFullCvs), this.ctx(clone.trimmedAlphaHoverFullCvs),
                this.trimmedOpaqueFullCtx, clone.trimmedOpaqueFullCtx,
                this.rawOpaqueFullCtx, clone.rawOpaqueFullCtx,
                this.rawOpaqueStrokeCtx, clone.rawOpaqueStrokeCtx,
                this.trimmedOpaqueStrokeCtx, clone.trimmedOpaqueStrokeCtx);

            if(clone.hoverResponsiveBitmapCtx == null) {
                clone.initializedHoverResponsiveBitmapCtx().drawImage(this.hoverResponsiveBitmapCtx.canvas, 0, 0);
            }
        }
        clone.isSelected = this.isSelected;
        clone.isHovered = this.isHovered;
        clone.isEditing = this.isEditing;
        clone.visible = this.visible;
        clone.isClippable = this.isClippable;
        clone.isClipping = this.isClipping;
        clone.hoverResponsiveArea = this.hoverResponsiveArea;
        clone.strokeWidth = this.strokeWidth;
        clone.strokeColor = this.strokeColor;
        clone.hStrokeColor = this.hStrokeColor;
        clone.strokeOpacity = this.strokeOpacity;
        clone.hStrokeOpacity = this.hStrokeOpacity;
        for(const shape of this.shapes) {
            clone.shapes.push(shape.clone());
        }
        return clone;
    }

    private ctx(cvs: HTMLCanvasElement): CanvasRenderingContext2D {
        if(cvs == null) {
            return null;
        }
        return cvs.getContext('2d');
    }

    // contexts = [originalCtx1, correspondingCloneCtx1, originalCtx2, correspondingCloneCtx2...]
    private hoverResponsiveBitmapReusableClone(...contexts: CanvasRenderingContext2D[]) {
        for(let i = 0; i < contexts.length; i += 2) {
            const originalCtx = contexts[i];
            if(originalCtx == this.hoverResponsiveBitmapCtx) {
                return contexts[i + 1];
            }
        }
        return null;
    }


    // ########################################### Edit Actions #####################################

    public shapeReordered(reorderedShape: PgnShape, targetIndex: number): boolean {
        const startIndex = this.indexOfShape(reorderedShape);
        if(startIndex == -1 || startIndex == targetIndex) {
            return false;
        }
        const step = (startIndex < targetIndex) ? 1 : -1;
        const start = (startIndex < targetIndex) ? (startIndex + 1) : targetIndex;
        const end = (startIndex < targetIndex) ? targetIndex : (startIndex - 1);
        let allReorderIntervalShapesHaveSameRenderMode = this.forRelevantSubshapesBetween(start, end,
                shape => shape.renderMode == reorderedShape.renderMode);
        for(let i = startIndex; i != targetIndex; i += step) {
            this.shapes[i] = this.shapes[i + step];
        }
        this.shapes[targetIndex] = reorderedShape;
        if(!reorderedShape.hasGraphEffect()) {
            return true;
        }
        if(reorderedShape.renderMode == PgnShapeRenderMode.SUBTRACT) {
            if(allReorderIntervalShapesHaveSameRenderMode) {
                return true;
            }
            this.subshapesFormChanged();
        } else if(allReorderIntervalShapesHaveSameRenderMode) { // reorderedShape.renderMode == PgnShapeRenderMode.ADD
            // form has not changed
            this.subshapesTextureChanged();
        } else {// !allReorderIntervalShapesHaveSameRenderMode && reorderedShape.renderMode == PgnShapeRenderMode.ADD => form change
            this.subshapesFormChanged();
        }

        return true;
    }

    public shapeDeleted(deletedShape: PgnShape): boolean {
        const deletedIndex = this.indexOfShape(deletedShape);
        if(deletedIndex == -1 ) {
            return false;
        }
        this.shapes.splice(deletedIndex, 1);
        if(!deletedShape.hasGraphEffect()) {
            return true;
        }
        if(this.forRelevantSubshapesBetween(0, deletedIndex,
                shape => shape.renderMode == PgnShapeRenderMode.SUBTRACT)) {
            return true;
        }
        this.subshapesFormChanged();
        return true;
    }

    // if shapeToClone == null => create new empty shape (shapeType must be != null), else clone shape
    // if shapeToCLone != null, shapeType is ignored
    public newShapeAtIndex(shapeToClone: PgnShape, shapeType: PgnShapeType, index: number) {
        const newShape = shapeToClone == null ? PgnShapeFactory.newShape(shapeType, this) : shapeToClone.clone();
        this.shapes.splice(index, 0, newShape);
        const clonedShapeIndex = this.indexOfShape(shapeToClone);
        const checkStart = clonedShapeIndex == -1 ? 0 : clonedShapeIndex;
        if(!newShape.hasGraphEffect() || this.forRelevantSubshapesBetween(checkStart, index,
            shape => shape.renderMode == PgnShapeRenderMode.SUBTRACT)) {
            return;
        }
        this.subshapesFormChanged();
    }

    public shapeDraggedFrom(shape: PgnShape, sourceLayer: PgnLayerRenderer, targetIndex: number): boolean {
        const sourceIndex = sourceLayer.indexOfShape(shape);
        if(sourceIndex == -1 || !shape.hasGraphEffect()) {
            return false;
        }
        if(shape.renderMode == PgnShapeRenderMode.SUBTRACT) {
            const targetNotAffected = this.forRelevantSubshapesBetween(0, targetIndex - 1,
                shape => shape.renderMode == PgnShapeRenderMode.SUBTRACT);
            const sourceNotAffected = sourceLayer.forRelevantSubshapesBetween(0, sourceIndex - 1,
                shape => shape.renderMode == PgnShapeRenderMode.SUBTRACT);
            sourceLayer.shapes.splice(sourceIndex, 1);
            this.shapes.splice(targetIndex, 0, shape);
            if(sourceNotAffected != targetNotAffected) { // one is affected the other isn't
                if(!sourceNotAffected) { // only source is affected
                    sourceLayer.subshapesFormChanged();
                } else if(!targetNotAffected) {// only target is affected
                    this.subshapesFormChanged();
                }
            } else if(!sourceNotAffected) {// both target and source are affected
                sourceLayer.combineShapes();
                this.combineShapes();
                this.graphRenderer.shapeDraggedAcrossLayers(sourceLayer, this, false);
            } // else neither target nor source are affected => nothing else to refresh
        } else {//shape.renderMode == PgnShapeRenderMode.ADD
            sourceLayer.shapes.splice(sourceIndex, 1);
            this.shapes.splice(targetIndex, 0, shape);

            sourceLayer.combineShapes();
            this.combineShapes();
            if(shape.opaqueStrokeCvs() != null && sourceLayer.actualStrokeWidth() != this.actualStrokeWidth()) {
                this.graphRenderer.shapeDraggedAcrossLayers(sourceLayer, this, false);
                return true;
            }

            const fullShapeWasRendered = sourceLayer.forRelevantSubshapesBetween(sourceIndex, sourceLayer.shapes.length - 1,
                shape => shape.renderMode == PgnShapeRenderMode.ADD);
            if(!fullShapeWasRendered) {
                this.graphRenderer.shapeDraggedAcrossLayers(sourceLayer, this, false);
                return true;
            }
            // here fullShapeWasRendered = true
            const fullTargetShapeRendered = this.forRelevantSubshapesBetween(targetIndex + 1, this.shapes.length - 1,
                shape => shape.renderMode == PgnShapeRenderMode.ADD);
            // if shape was fully rendered in sourceLayer and is still fully rendered in target layer
            // => the clipping pixels did not change => restrict graph reprocessing to changed layer interval (no need to go deep down
            // all the way to the bottom layer)
            // otherwise there's a non verifiable risk of rendered pixels change across both layers => reprocessing the graph all the way
            // to the bottom layer is necessary
            // B.b: the call below takes into consideration clipping/clippable states of both source/target layers
            this.graphRenderer.shapeDraggedAcrossLayers(sourceLayer, this, fullTargetShapeRendered);
        }
        return true;
    }

    public toNonClippable() {
        this.graphRenderer.toNonClippable(this);
    }

    public toClippable() {
        this.graphRenderer.toClippable(this);
    }

    public toNonClipping() {
        this.graphRenderer.toNonClipping(this);
    }

    public toClipping() {
        this.graphRenderer.toClipping(this);
    }

    public subshapesFormChanged() {
        this.combineShapes();
        this.graphRenderer.layerFormChanged(this);
    }

    public subshapesTextureChanged() {
        this.combineShapes();
        if(this.visible) {
            this.graphRenderer.fireRepaintAction(false);
        }
    }

    public visibilityChange() {
        this.graphRenderer.fireRepaintAction(false);
    }
    // ############################################# End Edit #######################################

    private indexOfShape(shape: PgnShape) {
        if(this.shapes == null) {
            return -1;
        }
        for(let i = 0; i < this.shapes.length; i ++) {
            if(this.shapes[i] == shape) {
                return i;
            }
        }
        return -1;
    }

    public createCanvas(): HTMLCanvasElement {
        return this.graphRenderer.createCanvas();
    }

}
