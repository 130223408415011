import {Component, ElementRef, HostListener} from '@angular/core';
import {PgnCoreCanvasComponent} from '../../core-canvas/pgn-core-canvas.component';
import {PgnLayerRenderer} from '../../core-canvas/layer/pgn-layer-renderer';

import {PgnShape} from '../../core-canvas/shape/pgn-shape';
import {PgnGraphRenderer} from '../../core-canvas/graph/pgn-graph-renderer';
import {BreakpointObserver} from '@angular/cdk/layout';


const SAME_POINT_ERR = 3;
const DASHED_LINE_CONFIG = [10, 5, 2, 10];
const SELECTION_PATH_COLOR = 'blue';
const SELECTION_HOVERED_POINT_COLOR = 'cyan';
const SELECTION_POINT_COLOR = 'blue';
const SELECTION_POINT_CIRCLE_RADIUS = 5;


@Component({
  selector: 'nt-mouse-edit',
  templateUrl: './pgn-mouse-edit.component.html',
  styleUrls: ['./pgn-mouse-edit.component.scss']
})
export class PgnMouseEditComponent extends PgnCoreCanvasComponent {

    editedLayer: PgnLayerRenderer;
    editedShapeIndex = -1;
    editedShape: PgnShape;
    editedPointIndex = -1;

    mouseX = 0;
    mouseY = 0;
    coordinates = '';

    constructor (protected hostEl:ElementRef) {
        super(hostEl);
        this.isEditMode = true;
    }

    public graphRendererInitialized(graphRenderer: PgnGraphRenderer) {
        graphRenderer.setEditCvs(this.canvasRef.nativeElement);
        super.graphRendererInitialized(graphRenderer);
    }

    onMouseOut(e: MouseEvent) {
        //todo
        super.onMouseOut(e);
        this.repaint();
        this.coordinates = '';
    }

    onMousePress(e: PointerEvent) {
        //todo
        super.onMousePress(e);
        console.log("pointer-type = " + e.pointerType);
        if(e.shiftKey || e.ctrlKey || e.altKey || e.metaKey || !this.editedLayer || this.editedShape) {
            return;
        }
        const selectedPt = this.getPointAt(e);
        if(!selectedPt) {
            return;
        }
        // Analytics.logGA(PolygonAnalytics.CATEGORY, PolygonAnalytics.EDIT_ACTION, null, PolygonAnalytics.EDIT_VERTEX_MOVE);
        this.editedShapeIndex = selectedPt.shapeIndex;
        this.editedShape = selectedPt.shapeRenderer;
        this.editedPointIndex = selectedPt.pointIndex;
        this.repaint();
    }

    onMouseRelease(e: MouseEvent) {
        //todo
        super.onMouseRelease(e);
    }

    onMouseMove(e: MouseEvent) {
        this.processHoverEvent(e);
        this.mouseX = this.getNormalizedX(e);
        this.mouseY = this.getNormalizedY(e);
        this.coordinates = this.mouseX + ' , ' + this.mouseY;
        // todo
    }

    @HostListener('document:keydown', ['$event'])
    onKeyDown(event) {
        if (event.target.tagName.toUpperCase() !== 'BODY') {
            return;
        }
        //todo
        // if (KeyEventUtils.isEnterKey(event)) {
        //     this.editingFinished();
        // } else if(KeyEventUtils.isEscapeKey(event)) {
        //     this.cancelCurrentAction();
        // } else if (KeyEventUtils.isAlphaCharIgnoreCase(event, 'e')) {
        //     this.endCurrentShapeEditing();
        // }
    }

    private reusableSelectionPt = new PointInfo();
    private getPointAt(e: MouseEvent): PointInfo {
        if(!this.editedLayer) {
            return null;
        }
        let shape: PgnShape;
        const x = this.getNormalizedX(e);
        const y = this.getNormalizedY(e);
        //todo
        // let ptX, ptY, dx, dy;
        // for(let i = 0; i < this.editedLayer.shapes.length; i ++) {
        //     shape = this.editedLayer.shapes[i];
        //     for(let j = 0; j < shape.config.points.length; j += 2) {
        //         ptX = shape.config.points[j];
        //         ptY = shape.config.points[j + 1];
        //         dx = ptX - x;
        //         dy = ptY - y;
        //         if(Math.sqrt(dx * dx + dy * dy) <= SELECTION_POINT_CIRCLE_RADIUS) {
        //             this.reusableSelectionPt.pointIndex = j;
        //             this.reusableSelectionPt.shapeIndex = i;
        //             this.reusableSelectionPt.shapeRenderer = shape;
        //             return this.reusableSelectionPt;
        //         }
        //     }
        // }
        return null;
    }

    private tweakShapeWhereApplicable(_shape: PgnShape) {
        //todo
        // let isTweaked = false;
        // const polygon = [];//shape.config.points;
        // for(let i = 0; i + 3 < polygon.length; i ++) {
        //     const x1 = polygon[i];
        //     const y1 = polygon[i + 1];
        //     const x2 = polygon[i + 2];
        //     const y2 = polygon[i + 3];
        //     const dx = x2 - x1;
        //     const dy = y2 - y1;
        //     if (Math.sqrt(dx * dx + dy * dy) <= SAME_POINT_ERR) {
        //         polygon.splice(i + 2, 2);
        //         isTweaked = true;
        //     }
        // }
        // if(isTweaked) {
        //     // todo fire event (update text components etc...)
        // }
    }

    repaint() {
        super.repaint();
        this.highlightSelection();
    }

    highlightSelection() {
        //todo
        // if(!this.selectCtx) {
        //     return;
        // }
        // const cvsWidth = this.compImg.width * this.scaleX;
        // const cvsHeight = this.compImg.height * this.scaleY;
        // this.selectCtx.clearRect(0, 0, cvsWidth, cvsHeight);
        if(this.editedLayer) {
            this.drawSelectionDottedLines();
            this.drawSelectionPoints();
        }
    }

    drawSelectionDottedLines() {
        // todo
        // this.selectCtx.strokeStyle = SELECTION_PATH_COLOR;
        // this.selectCtx.setLineDash(DASHED_LINE_CONFIG);
        // for(const shape of this.editedLayer.shapes) {
        //     this.drawShapeDashedPolygon(shape);
        // }
    }

    drawShapeDashedPolygon(_shape: PgnShape) {
        // todo
        // const polygon = shapeRenderer.config.points;
        // if(polygon.length < 4) {
        //     return;
        // }
        // this.selectCtx.beginPath();
        // this.selectCtx.moveTo(polygon[0] * this.scaleX, polygon[1] * this.scaleY);
        // for(let i = 2; i < polygon.length; i += 2) {
        //     this.selectCtx.lineTo(polygon[i] * this.scaleX, polygon[i + 1] * this.scaleY);
        // }
        // this.selectCtx.closePath();
        // this.selectCtx.stroke();
    }

    drawSelectionPoints() {
        // todo
        // this.selectCtx.fillStyle = SELECTION_POINT_COLOR;
        // for(let i = 0; i < this.editedLayer.shapes.length; i ++) {
        //     this.drawShapeSelectionPoints(i, this.editedLayer.shapes[i]);
        // }
    }

    drawShapeSelectionPoints(_shapeIndex: number, _shape: PgnShape) {
        // todo
        // const polygon = shape.config.points;
        // for(let i = 0; i < polygon.length; i += 2) {
        //     this.drawSelectionPoint(shapeIndex, i, polygon[i], polygon[i + 1]);
        // }
    }

    drawSelectionPoint(_shapeIndex: number, _pointIndex: number, _x: number, _y: number) {
        // todo
        // const isHoveredPoint = this.hoveredPt.equals(pointIndex, shapeIndex);
        // if(isHoveredPoint) {
        //     this.selectCtx.fillStyle = SELECTION_HOVERED_POINT_COLOR;
        // }
        // this.selectCtx.beginPath();
        // this.selectCtx.arc(x * this.scaleX, y * this.scaleY, SELECTION_POINT_CIRCLE_RADIUS * Math.min(this.scaleX, this.scaleY),0,2 * Math.PI);
        // this.selectCtx.closePath();
        // this.selectCtx.fill();
        // if(isHoveredPoint) {//restore fill style
        //     this.selectCtx.fillStyle = SELECTION_POINT_COLOR;
        // }
    }
}

class PointInfo {
    pointIndex = -1;
    shapeIndex = -1;
    shapeRenderer: PgnShape = null;

    copy(ptInfo: PointInfo) {
        this.pointIndex = ptInfo.pointIndex;
        this.shapeIndex = ptInfo.shapeIndex;
        this.shapeRenderer = ptInfo.shapeRenderer;
    }

    equals(ptIndex: number, shapeIndex: number) {
        return (this.pointIndex === ptIndex) && (this.shapeIndex === shapeIndex);
    }

    reset() {
        this.pointIndex = -1;
        this.shapeIndex = -1;
        this.shapeRenderer = null;
    }
}
