import {ElementRef} from '@angular/core';
import {NtSizeChangeCompContext} from './nt-size-change-comp-context';

export class NtSizeObservableContext {

    public lastWidth = 0;
    public lastHeight = 0;

    constructor(public compCtx: NtSizeChangeCompContext,
                public el: ElementRef,
                public callback: (newWidth: number, newHeight: number) => void) {
        compCtx.addObervableContext(this);
    }

    updateSizeIfChanged(): boolean {
        let changeDetected = false;
        if(this.lastWidth !== this.el.nativeElement.getBoundingClientRect().width) {
            this.lastWidth = this.el.nativeElement.getBoundingClientRect().width;
            changeDetected = true;
        }

        if(this.lastHeight !== this.el.nativeElement.getBoundingClientRect().height) {
            this.lastHeight = this.el.nativeElement.getBoundingClientRect().height;
            changeDetected = true;
        }
        return changeDetected;
    }
}
