<section id="ansi-highlight-eula-sec" >
    <div class="{{responsiveCls}}" [ngClass]="{'nt-mbl': isHandset, 'nt-scr': !isHandset}">
        <div class="content">
            <h2>Register ANSI Highlighter Premium</h2>
            <time datetime="2022-05-30">Last Modified: May 30th, 2022</time>

            <p>
                You can evaluate ANSI Highlighter Premium for up to 30 days. After installing the plugin from the
                marketplace, restart your IDE and log in to your
                <a target="_blank" rel="noopener noreferrer" href="https://account.jetbrains.com/login">JetBrains Account</a> to start using the trial version.
            </p>

            <blockquote>
                <div>
                    <svg viewBox="0 0 24 24" width="24" height="24" class="bqt-svg">
                        <circle cx="12.042" cy="4" r="2"></circle>
                        <path d="M18.339 7a6.982 6.982 0 0 0-6.3 4 6.982 6.982 0 0 0-6.3-4H3v10h2.739a6.983 6.983 0 0 1 6.3 4 6.582 6.582 0 0 1 6-4.033h2.994L21 7z"></path>
                    </svg>
                    <div class="tip-content">
                        <ul>
                            <li>Your JetBrains IDE will automatically log you into your account if you're using
                                <a target="_blank" rel="noopener noreferrer" href="https://www.jetbrains.com/toolbox-app">ToolBox</a> to install JetBrains products and already logged in there.</li>
                            <li>If you use two-factor authentication for your JetBrains Account, you can specify the
                                generated app password instead of the primary JetBrains Account password.</li>
                        </ul>

                    </div>
                </div>
            </blockquote>

            <div class="steps-ctn">
                <h3 id="start-trial">Start the free trial</h3>
                <ol>
                    <li>
                        In the <b>Licenses</b> dialog that pops up when you start your IDE after installing ANSI
                        Highlighter Premium, select the <b>Start trial</b> option and click <b>Log in to
                        JetBrains Account</b>. You will be redirected to the
                        <a target="_blank" rel="noopener noreferrer" href="https://account.jetbrains.com/login">JetBrains Account</a>
                        website.
                        <div>
                            <img src="https://res.cloudinary.com/nifty-thoughts/image/upload/v1653813297/ah-start-trial_rxs0op.png"/>
                        </div>
                        Note that the <b>Licenses</b> dialog will also appear if you open a log file with no
                            active license.
                    </li>
                    <li>
                        Log in to your JetBrains Account on the website and click the <b>Start Trial</b> button in the
                        <b>Licenses</b> dialog to start your trial period.
                        <div>
                            <img src="https://res.cloudinary.com/nifty-thoughts/image/upload/v1653813297/trial-logged-in_punhht.png"/>
                        </div>
                    </li>
                </ol>
            </div>

            <p>Upon expiration of the trial version, you need to buy and register a license in order to continue
                using ANSI Highlighter Premium.</p>

            <div class="steps-ctn">
                <h3 id="start-trial">Activate a license for ANSI Highlighter Premium</h3>
                <p>
                    A license for ANSI Highlighter Premium costs 5&euro; / year and is perpetual for the
                    purchased version. You need to renew your purchase upon expiration of your subscription <u>only if</u>
                    your perpetual fallback license is no longer compatible with your IDE's latest version. You may
                    also want to renew your subscription for potential extra features that might ship with future
                    versions.
                </p>
                <p>After installing ANSI Highlighter Premium from the marketplace and restarting
                    your IDE, you need to follow the below steps in order to activate a license:</p>
                <ol>
                    <li>
                        Make sure the plugin is enabled and do one of the following to open the <b>Licenses</b> dialog:
                        <ul>
                            <li>Open any log file</li>
                            <li>From the main menu, select <b>Help | Register</b></li>
                            <li>On the <b>Welcome</b> screen, click <b>Help | Manage License</b></li>
                        </ul>
                        <div>
                            <img src="https://res.cloudinary.com/nifty-thoughts/image/upload/v1653813297/ah-login-jb-account_adzab7.png"/>
                        </div>
                    </li>
                    <li>If necessary, log in to your JetBrains Account.</li>
                    <li>
                        Select how you want to register a license:
                        <nt-tabs [selectTabId]="'jb-account'" [pageResizeSubject]="pageResizeSubject">
                            <nt-tab [title]="'JB Account'" [tabId]="'jb-account'">
                                Register using your
                                    <a target="_blank" rel="noopener noreferrer" href="https://account.jetbrains.com/login">JetBrains Account</a>
                                <div>
                                    <img src="https://res.cloudinary.com/nifty-thoughts/image/upload/v1653813297/ah-activate-login_fzkgmn.png"/>
                                </div>
                                The <b>Licenses</b> dialog will automatically show the list of your licenses with
                                their details such as the expiration date and your identifier. Click <b>Activate</b>
                                to start using your license.

                                <p>If your license is not shown on the list, click <b>Refresh license list</b>.</p>
                            </nt-tab>
                            <nt-tab [title]="'Activation Code'" [tabId]="'activation-code'">
                                Register using an activation code.
                                <div>
                                    <img src="https://res.cloudinary.com/nifty-thoughts/image/upload/v1653813297/ah-activate-code_zhgbhe.png"/>
                                </div>
                                You will receive an activation code when you purchase a license for ANSI Highlighter Premium.
                            </nt-tab>
                            <nt-tab [title]="'License Server'" [tabId]="'license-server'">
                                Register using the <a target="_blank" rel="noopener noreferrer" href="https://www.jetbrains.com/license-server/">Floating License Server</a>
                                <div>
                                    <img src="https://res.cloudinary.com/nifty-thoughts/image/upload/v1653813297/ah-activate-server_vebkfq.png"/>
                                </div>
                                When performing silent installation or managing IntelliJ IDEA installations on
                                multiple machines, you can set the <code class="code">JETBRAINS_LICENSE_SERVER</code>
                                environment variable to point the installation to the Floating License Server URL.

                                <p>Alternatively, you can set the Floating License Server URL by adding the
                                    <code class="code">-DJETBRAINS_LICENSE_SERVER</code> JVM option.</p>
                            </nt-tab>
                        </nt-tabs>
                        <blockquote>
                            <div>
                                <svg viewBox="0 0 24 24" width="24" height="24" class="bqt-svg">
                                    <circle cx="12.042" cy="4" r="2"></circle>
                                    <path d="M18.339 7a6.982 6.982 0 0 0-6.3 4 6.982 6.982 0 0 0-6.3-4H3v10h2.739a6.983 6.983 0 0 1 6.3 4 6.582 6.582 0 0 1 6-4.033h2.994L21 7z"></path>
                                </svg>
                                <div class="tip-content">
                                    <p>Your IDE detects the system proxy URL during initial startup and uses
                                        it for connecting to the JetBrains Account and Floating License Server.
                                        To override the URL of the system proxy, add the
                                        <code class="code">-Djba.http.proxy</code> JVM option. Specify the proxy
                                        URL as the host address and optional port number:
                                        <code class="code">proxy-host[:proxy-port]</code>.
                                        For example: <code class="code">-Djba.http.proxy=http://my-proxy.com:4321</code>.</p>
                                    <p>If you want to disable proxy detection entirely and always connect directly,
                                        set the property to <code class="code">-Djba.http.proxy=direct</code>.</p>

                                </div>
                            </div>
                        </blockquote>
                    </li>
                </ol>
            </div>
        </div>
    </div>
</section>
