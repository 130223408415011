import {AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ToolbarButtonDescriptor} from './toolbar-button-descriptor';
import {Subject} from 'rxjs';
import {NtAbstractCompComponent} from '../nt-abstract-comp/nt-abstract-comp.component';
import {KeyEventUtils} from '../key-event-utils';

const POPOUT_TB_BTN_ORIGIN = 'tlbr-btn';

@Component({
    selector: 'nt-toolbar-btn',
    animations: [
        trigger('tbr-tgl', [
            state('selected', style({
                'box-shadow': 'inset 0 0 13px rgb(0, 0, 0, 0.3)'
            })),
            state('hovered-no-bg', style({
                'box-shadow': '0 0 10px rgb(0, 0, 0, 0.4)'
            })),
            state('idle-no-bg', style({
                'box-shadow': '0 0 5px rgb(0, 0, 0, 0.15)'
            })),
            state('hovered-bg1', style({
                'background': '#f4f4f4',
                'box-shadow': '0 0 10px rgb(0, 0, 0, 0.4)'
            })),
            state('idle-bg1', style({
                'background': '#f4f4f4',
                'box-shadow': '0 0 5px rgb(0, 0, 0, 0.15)'
            })),
            transition('* => selected', [
                animate('.1s ease-in-out')
            ]),
            transition('selected => *', [
                animate('.1s 0.1s ease-in-out')
            ])
        ])
    ],
    templateUrl: './toolbar-btn.component.html',
    styleUrls: ['./toolbar-btn.component.scss']
})
export class ToolbarBtnComponent extends NtAbstractCompComponent implements AfterViewInit {

    @Input() btnDescr: ToolbarButtonDescriptor;
    @Output() ntclick: EventEmitter<ToolbarButtonDescriptor> = new EventEmitter<ToolbarButtonDescriptor>();
    @Output() extclick = new EventEmitter<ToolbarButtonDescriptor>();

    currentWidth: number;
    currentHeight: number;

    beforePopoutSubject = new Subject<string>();
    pendingPopoutEvent: EventEmitter<ToolbarButtonDescriptor> = null;

    private static extClickEvents = new Array<ToolbarBtnComponent>();

    constructor(private hostEl: ElementRef) {
        super();
    }

    ngOnInit(): void {
        const self = this;
        this.beforePopoutSubject.asObservable().subscribe((origin) => {
            if(origin === POPOUT_TB_BTN_ORIGIN || self.pendingPopoutEvent === null) {
                return;
            }
            self.pendingPopoutEvent.emit(self.btnDescr);
            if(self.pendingPopoutEvent === self.extclick) {
                self.btnDescr.pressed = false;
            }
            self.pendingPopoutEvent = null;
        });
    }

    ngAfterViewInit(): void {
        this.observeSizeChange(this.hostEl, (w: number, h: number) => {
            this.currentWidth = w;
            this.currentHeight = h;
        });
    }

    // when popups are animating overflow css property should be set to hidden to trim content that overflow the gradual resize animation
    // however when the animation is over overflow should be set to visible so that sub-popups
    // can show up without getting trimmed => to implement this :
    // - overflow should be set to hidden by default
    // - when the pop-in animation ends overflow (animation.done event) is set to visible
    // - 'just before' a pop-out event, overflow should be reset to hidden => @:leave.animation.start cannot be relied upon because once it starts everything is out of the dom/reach
    // => a callback should be fired just before pop-out events => the ingredients to implement this:
    // - popup elements are bound to the dom mainly through the pressed property
    // - manipulations of the pressed property happen outside nt-toolbar-btn but are triggered initially through either ntclick or extclick events
    // => solution is to delay the emission of ntclick and extclick events by one frame and notify the popup before it pops out
    //
    // another problem to consider is when a popout event is triggered simultaneously on nested popups (when processed under same frame angular crashes)
    // solution is to process the impacted hierarchy into separate frames starting from children and then going up the hierarchy
    onBtnClick() {
        if(this.btnDescr.showPopup()) {
            if(this.pendingPopoutEvent) {
                return;
            }
            this.pendingPopoutEvent = this.ntclick;
            this.processPopoutEvent();
        } else {
            this.ntclick.emit(this.btnDescr);
        }
    }

    popupAninationEnd() {
        this.btnDescr.isPopupAnimating = false;
    }

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if(!this.pendingPopoutEvent &&
            this.btnDescr.showPopup() &&
            !this.hostEl.nativeElement.contains(event.target)) {
            this.pendingPopoutEvent = this.extclick;
            this.processPopoutEvent();
        }
    }

    @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {
        if(KeyEventUtils.isEscapeKey(event) &&
            !this.pendingPopoutEvent &&
            this.btnDescr.showPopup()) {
            this.pendingPopoutEvent = this.extclick;
            this.processPopoutEvent();
        }
    }

    private processPopoutEvent() {
        // gather popout events occurring under the same angular rendering frame and process them
        // all at one within the next frame to prevent nested popups from crashing due to removal of parent popups before their children
        ToolbarBtnComponent.extClickEvents.push(this);
        const self = this;
        this.tick_then(() => {
            let extClickHierarchy;
            do {
                extClickHierarchy = self.extractFirstHierarchy(ToolbarBtnComponent.extClickEvents);
                self.processPopoutHierarchy(extClickHierarchy);
            } while(extClickHierarchy !== null);
        });
    }

    private extractFirstHierarchy(source: Array<ToolbarBtnComponent>): Array<ToolbarBtnComponent> {
        if(source.length === 0) {
            return null;
        }
        const hierarchy = new Array<ToolbarBtnComponent>();
        const reference = source[0];
        hierarchy.push(reference);
        source.splice(0, 1);
        for(let i = 0; i < source.length; i ++) {
            const newComp = source[i];
            if(newComp.hostEl.nativeElement.contains(reference.hostEl.nativeElement) ||
                reference.hostEl.nativeElement.contains(newComp.hostEl.nativeElement)) {
                source.splice(i, 1);
                this.insertHierarchyComponent(newComp, hierarchy);
                i --;
            }
        }
        return hierarchy;
    }

    private insertHierarchyComponent(comp: ToolbarBtnComponent, hierarchy: Array<ToolbarBtnComponent>) {
        let i = 0;
        while (i < hierarchy.length) {
            if(hierarchy[i].hostEl.nativeElement.contains(comp.hostEl.nativeElement)) {
                hierarchy.splice(i, 0, comp);
                return;
            }
            i ++;
        }
        hierarchy.push(comp);
    }

    private processPopoutHierarchy(hierarchy: Array<ToolbarBtnComponent>, index: number = 0) {
        if(hierarchy === null || index >= hierarchy.length) {
            return;
        }
        // process child and delay parent processing for next frame to prevent crash
        const comp = hierarchy[index];
        comp.beforePopoutSubject.next(POPOUT_TB_BTN_ORIGIN);
        //process parent on next frame
        const self = this;
        this.tick_then(() => self.processPopoutHierarchy(hierarchy, index + 1));
    }
}
