import {Component, OnInit} from '@angular/core';
import {ProductPage} from './product-page';
import {Subject} from 'rxjs';
import {AnsiHighlighterComponent} from './content-container/ansi-highlighter/ansi-highlighter.component';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {ResponsiveConstants} from './responsive-constants';
import {MathGlideComponent} from './content-container/math-glide/math-glide.component';
import {PolygonComponent} from './content-container/polygon/polygon.component';
import {NtColorPickerComponent} from './utils/nt-color-picker/nt-color-picker.component';
import {PgnPaddingEditComponent} from './content-container/polygon/core/edit/pgn-padding-edit/pgn-padding-edit.component';
import {PgnStrokeEditPopupComponent} from './content-container/polygon/core/edit/pgn-stroke-edit-popup/pgn-stroke-edit-popup.component';
import {NtHomeComponent} from './content-container/nt-home/nt-home.component';
import {AnsiHighlighterEulaComponent} from './content-container/ansi-highlighter/ansi-highlighter-eula/ansi-highlighter-eula.component';
import {AnsiHighlighterLicenseRegisterComponent} from './content-container/ansi-highlighter/ansi-highlighter-license-register/ansi-highlighter-license-register.component';

@Component({
    selector: 'nt-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    productPages: ProductPage[];
    animationEndEventSubject: Subject<ProductPage>;
    isHandset: boolean;

    constructor(public breakpointObserver: BreakpointObserver) {
    }

    isColorPickerSizeCached(): boolean {
        return NtColorPickerComponent.pickerHeightCache !== null;
    }

    isGraphPaddingPanelSizeCached(): boolean {
        return PgnPaddingEditComponent.panelHeightCache !== null;
    }

    isGraphStrokePanelSizeCached(): boolean {
        return PgnStrokeEditPopupComponent.panelHeightCache !== null;
    }

    ngOnInit(): void {
        const self = this;
        this.isHandset = this.breakpointObserver.isMatched(ResponsiveConstants.HANDSET_MEDIA_QUERY);
        this.breakpointObserver
            .observe(ResponsiveConstants.HANDSET_MEDIA_QUERY)
            .subscribe((state: BreakpointState) => {
                self.isHandset = state.matches;
            });
        self.animationEndEventSubject = new Subject<ProductPage>();
        self.productPages = [];
        self.productPages = [
            NtHomeComponent.PRODUCT,
            PolygonComponent.PRODUCT,
            // LifeguardComponent.PRODUCT,
            MathGlideComponent.PRODUCT,
            AnsiHighlighterComponent.PRODUCT,
            AnsiHighlighterEulaComponent.PRODUCT,
            AnsiHighlighterLicenseRegisterComponent.PRODUCT
        ];
    }
}
