<div #initTmpCtn
     class="init-tmp-ctn"
     [style]="{visibility: 'hidden', position: 'fixed'}"
     *ngIf="!isInitialized()">
    <input #inInit class="pck-in" [value]="btnDescr.color"/>
</div>


<nt-toolbar-btn #clrPckBtn
                [btnDescr]="btnDescr"
                (ntclick)="togglePicker()"
                (extclick)="popupExternalClick($event)">
    <div #clrPckCtn
         class="clr-pck-ctn"
         [style.padding]="pickerPadding()">
        <input #in class="pck-in" [value]="btnDescr.color"/>
    </div>
</nt-toolbar-btn>
