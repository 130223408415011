import {PgnShape} from './pgn-shape';

export class PgnSquareShape extends PgnShape {
    x: number;
    y: number;
    width: number;
    height: number;
    cornerRadius = 0;

    public applyDefaults() {
        //todo
    }

    public initGraphics(_document: Document) {
        // todo
    }

    public opaqueFillCvs(): HTMLCanvasElement {
        // todo
        return null;
    }

    public opaqueStrokeCvs(): HTMLCanvasElement {
        // todo
        return null;
    }

    public clearEditModeGraphicsCache() {
        // todo
    }

    public setEditMode(_isEditMode: boolean) {
        // todo
    }

    public clone(): PgnSquareShape {
        const clone = new PgnSquareShape();
        super.cloneInto(clone);
        // todo
        return clone;
    }

}
