import {AfterViewInit, Component, ElementRef, Input, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {NtDragDropHandlerComponent} from '../../../../../utils/nt-drag-drop-handler/nt-drag-drop-handler.component';
import {PgnLayerRenderer} from '../../core-canvas/layer/pgn-layer-renderer';
import {PgnShape} from '../../core-canvas/shape/pgn-shape';
import {NtDndContext} from '../../../../../utils/nt-drag-drop-handler/nt-dnd-context';
import {PgnShapeEditComponent} from '../pgn-shape-edit/pgn-shape-edit.component';
import {NtDraggableComponent} from '../../../../../utils/nt-draggable/nt-draggable.component';
import {Observable} from 'rxjs';
import {first, map} from 'rxjs/operators';
import {PgnLayerManagerPanelComponent} from '../pgn-layer-manager-panel/pgn-layer-manager-panel.component';

@Component({
    selector: 'nt-pgn-layer-edit',
    templateUrl: './pgn-layer-edit.component.html',
    styleUrls: ['./pgn-layer-edit.component.scss']
})
export class PgnLayerEditComponent extends NtDragDropHandlerComponent<PgnLayerRenderer, PgnShape> implements AfterViewInit {

    @ViewChild('shpCtn') shapeCtnEl: ElementRef;
    @ViewChildren(PgnShapeEditComponent) subShapeComps: QueryList<PgnShapeEditComponent>;

    // @ts-ignore
    dropHandlers = new Array<QueryList<NtDragDropHandlerComponent>>()

    constructor(public hostEl: ElementRef) {
        super(hostEl);
    }

    layerRenderer(): PgnLayerRenderer {
        return this.data;
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    ngAfterViewInit(): void {
        const layerManager = <PgnLayerManagerPanelComponent>this.parentDnDHandler;
        this.dropHandlers.push(layerManager.subLayers);
    }

    // ------------------ DnD Setup ----------------------------

    allowDrag(_e: MouseEvent): boolean {
        return true;
    }

    draggableDataArray(): Array<PgnShape> {
        return this.layerRenderer().shapes;
    }

    draggableList(): QueryList<PgnShapeEditComponent> {
        return this.subShapeComps;
    }

    draggablesDirectContainer(): ElementRef {
        return this.shapeCtnEl;
    }

    public activateDnDLayoutAnimation() {
        return true;
    }

    protected draggablesMargin(): number {
        return 0;
    }

    // @ts-ignore
    protected allDropHandlers(): Array<QueryList<NtDragDropHandlerComponent>> {
        return this.dropHandlers;
    }

    // @ts-ignore
    protected createDataFromDuplicatedDroppedEl(draggable: NtDraggableComponent): PgnShape {
        const comp = <PgnShapeEditComponent>draggable;
        return comp.shape().clone();
    }

    // @ts-ignore
    protected adaptMovedDataFromDroppedEl(draggable: NtDraggableComponent): PgnShape {
        const comp = <PgnShapeEditComponent>draggable;
        // todo change parent layer when applicable / check if other actions are needed to adapt to the move from graph implementation perspective
        return comp.shape();
    }

    // @ts-ignore
    resolveHandler(handlerKey: any): NtDragDropHandlerComponent {
        return this.rootHandler().resolveHandler(handlerKey);
    }

    restoreData(compressedShape: any): PgnShape {
        return <PgnShape>compressedShape;
    }

    protected isDropSupportedForDraggable<T, U extends NtDraggableComponent<T>>(draggable: U): boolean {
        return draggable instanceof PgnShapeEditComponent;
    }

    protected requestNewInnerLength(newInnerLength: number): number {
        // no scrolling => apply size shift as is
        return newInnerLength - this.shapeCtnEl.nativeElement.offsetHeight;
    }

    protected shouldDismissIfEmptyAfterDnD(dndCtx: NtDndContext): boolean {
        // todo adjust implementation to desired behavior on insert/remove and their reversal + dnd undo/redo (in which these cases dndCtx.e == null)
        return dndCtx.e ? dndCtx.e.ctrlKey : false;
    }

}
