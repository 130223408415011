import {animate, style, transition, trigger} from '@angular/animations';


export const horizontalPopInAnim = trigger('hpopin', [
    transition(':enter', [
        style({maxHeight: '0'}),
        animate('0.2s 0.1s ease-in',
            style({maxHeight: '{{popupHeight}}'})
        ),
    ], {params: {popupHeight: '40px'}})
]);

export const horizontalPopOutAnim = trigger('hpopout', [
    transition(':leave', [
        style({maxHeight: '{{popupHeight}}', opacity: 1}),
        animate('0.2s ease-out',
            style({maxHeight: '0', opacity: 0.3})
        )
    ], {params: {popupHeight: '40px'}})
]);

export const bottomPopInAnim = trigger('bpopin', [
    transition(':enter', [
        style({maxHeight: '0', 'padding-top': '0', 'padding-bottom': '0'}),
        animate('0.2s 0.1s ease-in',
            //padding-top and padding-bottom must be equal to @shadowHeight under popup-wrapper.component.scss
            style({maxHeight: '{{popupHeight}}', 'padding-top': '10px', 'padding-bottom': '10px'})
        ),
    ], {params: {popupHeight: '40px'}})
]);

export const bottomPopOutAnim = trigger('bpopout', [
    transition(':leave', [
        //padding-top and padding-bottom must be equal to @shadowHeight under popup-wrapper.component.scss
        style({maxHeight: '{{popupHeight}}', 'padding-top': '10px', 'padding-bottom': '10px', opacity: 1}),
        animate('0.2s ease-out',
            style({maxHeight: '0', 'padding-top': '0', 'padding-bottom': '0', opacity: 0.3})
        )
    ], {params: {popupHeight: '40px'}})
]);

export const topPopInAnim = trigger('tpopin', [
    transition(':enter', [
        style({maxHeight: '0', 'padding-top': '0', 'padding-bottom': '0'}),
        animate('0.2s 0.1s ease-in-out',
            //padding-top and padding-bottom must be equal to @shadowHeight under popup-wrapper.component.scss
            style({maxHeight: '{{popupHeight}}', 'padding-top': '10px', 'padding-bottom': '10px'})
        ),
    ], {params: {popupHeight: '40px'}})
]);

export const topPopOutAnim = trigger('tpopout', [
    transition(':leave', [
        //padding-top and padding-bottom must be equal to @shadowHeight under popup-wrapper.component.scss
        style({maxHeight: '{{popupHeight}}', 'padding-top': '10px', 'padding-bottom': '10px', opacity: 1}),
        animate('0.2s ease-in-out',
            style({maxHeight: '0', 'padding-top': '0', 'padding-bottom': '0', opacity: 0.3})
        )
    ], {params: {popupHeight: '40px'}})
]);

