import {PgnShape} from './pgn-shape';

export class PgnTextShape extends PgnShape {
    x: number;
    y: number;
    width: number;
    height: number;

    text: string;
    size: string;
    font: string;

    bold = false;
    italic = false;
    underlined = false;

    constructor() {
        super();
    }

    public applyDefaults() {
        //todo
    }

    public initGraphics(_document: Document) {
        // todo
    }

    public opaqueFillCvs(): HTMLCanvasElement {
        // todo
        return null;
    }

    public opaqueStrokeCvs(): HTMLCanvasElement {
        // todo
        return null;
    }

    public setEditMode(_isEditMode: boolean) {
        // todo
    }

    public clearEditModeGraphicsCache() {
        // todo
    }

    public clone(): PgnTextShape {
        const clone = new PgnTextShape();
        super.cloneInto(clone);
        // todo
        return clone;
    }
}
