<section>
    <div class="{{responsiveCls}}" [ngClass]="{'nt-mbl': isHandset, 'nt-scr': !isHandset}">
        <div class="content">
            <h1>Lifeguard</h1>
            <p class="pre-lst">
                Are you a software developer or a QA engineer? Have you been involved in a software development ecosystem
                backed by a relational database? If so, chances are you've been confronted with at least one of the
                below scenes:
            </p>
            <ul>
                <li>
                    You invested time preparing a functional test-case scenario, you ran your test, you witnessed a bug,
                    you attempted to reproduce it but it wasn't possible due to some state deviation at
                    database level.
                </li>
                <li>
                    You were troubleshooting a bug that causes undesirable data change and you hesitated before
                    playing the steps to reproduce it because recovering the database state costs significant effort.
                </li>
                <li>
                    Your software manifested performance issues, you suspected the persistence layer but had no clear
                    visibility over the SQL operations running behind the scenes.
                </li>
            </ul>
            <p>
                If you experienced any of the above, please keep reading...
            </p>
            <p class="pre-lst">
                Introducing Lifeguard, a database "watchdog" designed to fulfill 2 major needs for software developers
                and QA engineers:
            </p>
            <ol>
                <li>
                    Seamless database state restoration, enabling swift recovery from potential data corruptions
                    throughout development and validation phases and unlocking consistent replay of functional misbehavior
                    when witnessed in pre-production environments.
                </li>
                <li>
                    Insight over bare-bone SQL operations running behind the scenes &gt; invaluably handy when
                    troubleshooting bugs and performance issues at persistence-layer level.
                </li>
            </ol>
            <p>
                Lifeguard is not recommended for production environments as it may slowdown the system due to SQL
                operation tracking, it's rather meant for Dev/QA environments and can plug in and out seamlessly,
                leaving no side-effect behind.
            </p>
            <p>
                The video below is a proof of concept, Lifeguard is still under development.
            </p>
            <div class="vid-wrp">
                <div class="video">
                    <div style="padding:56.25% 0 0 0;position:relative;">
                        <iframe src="https://player.vimeo.com/video/495262119?title=0&byline=0&portrait=0"
                                style="position:absolute;top:0;left:0;width:100%;height:100%;"
                                frameborder="0"
                                allow="autoplay; fullscreen" allowfullscreen>
                        </iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
