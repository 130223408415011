import { Injectable } from '@angular/core';
import {bindCallback, combineLatest, Observable} from 'rxjs';
import {PgnGraph} from './pgn-graph';
import {HttpClient} from '@angular/common/http';
import {PgnLayer} from '../layer/pgn-layer';
import {map} from 'rxjs/operators';
import {PgnRawLayerGroup} from '../layer/pgn-raw-layer-group';
import {NtUtils} from '../../../../../utils/nt-utils';

@Injectable({
  providedIn: 'root'
})
export class PgnGraphDataService {

    constructor(private http: HttpClient) { }

    loadGraphData(polygonId: string): Observable<PgnGraph<PgnLayer<any>, PgnRawLayerGroup<string>>> {
        const img: HTMLImageElement = new Image();
        const imgLoadObs = bindCallback((imgSrc: string, onloadCallback: ((ev: Event) => any)) => {
            img.onload = onloadCallback;
            img.src = imgSrc;
        });
        const graphObs = this.http.get<PgnGraph<PgnLayer<any>, PgnRawLayerGroup<string>>>('/pgn/' + polygonId + '/def');
        // return combineLatest([graphObs, imgLoadObs('/pgn/' + polygonId + '/asset')])
        return combineLatest([graphObs, imgLoadObs('https://res.cloudinary.com/nifty-thoughts/image/upload/v1653801938/' + polygonId + '.jpg')])
            .pipe(map(([rawGraph, _imgEvent]) => {
                const graphClone = new PgnGraph<PgnLayer<any>, PgnRawLayerGroup<string>>();
                NtUtils.assign(graphClone, rawGraph);
                graphClone.imageLoaded(img);
                return graphClone;
            }));
    }
}
