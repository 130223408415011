import {AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {AbstractComponent} from '../../abstract-component';
import {BreakpointObserver} from '@angular/cdk/layout';
import {ProductPage} from '../../product-page';
import {PolygonComponent} from '../polygon/polygon.component';
import {MathGlideComponent} from '../math-glide/math-glide.component';
import {AnsiHighlighterComponent} from '../ansi-highlighter/ansi-highlighter.component';
import {Location} from '@angular/common';

@Component({
    selector: 'nt-home',
    templateUrl: './nt-home.component.html',
    styleUrls: ['./nt-home.component.scss']
})
export class NtHomeComponent extends AbstractComponent implements OnInit, AfterViewInit {
    static readonly PRODUCT = new ProductPage('/home',
        'Home',
        'Nifty Thoughts',
        'Nifty Thoughts',
        'Nifty Thoughts');

    @ViewChild('bnrLt') bannerLightEl: ElementRef;

    isPosFixedRelativeHeight = false;

    // even though light banner has position: fixed, 100% height is inconsistent
    // => see content.container.component.scss/persipective docs for details
    ltBannerHeight = '100%';
    isPageAnimating = false;

    lastWindowHeight = 0;

    constructor(public breakpointObserver: BreakpointObserver, private location: Location) {
        super(breakpointObserver);
        NtHomeComponent.PRODUCT.pageComp = this;
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    ngAfterViewInit(): void {
        const self = this;
        this.tick_then(() => {
            const viewPortH = self.viewPortHeight();
            self.isPosFixedRelativeHeight = Math.abs(viewPortH - self.lightBannerHeight()) > 1;
            if(self.isPosFixedRelativeHeight) {
                self.ltBannerHeight = self.viewPortHeight() + 'px';
            }
        });
    }

    polygonPage(): ProductPage {
        return PolygonComponent.PRODUCT;
    }

    mathGlidePage(): ProductPage {
        return MathGlideComponent.PRODUCT;
    }

    ansiHighlighterPage(): ProductPage {
        return AnsiHighlighterComponent.PRODUCT;
    }

    polygonClick() {
        this.location.go(this.polygonPage().path);
    }

    mathGlideClick() {
        this.location.go(this.mathGlidePage().path);
    }

    ansiHighlightClick() {
        this.location.go(this.ansiHighlighterPage().path);
    }

    pageTransitionStart() {
        this.isPageAnimating = true;
        this.pageResized();
    }

    pageTransitionEnd() {
        this.isPageAnimating = false;
    }

    pageResizeReflected() {
        if(this.isPosFixedRelativeHeight) {
            this.ltBannerHeight = this.viewPortHeight() + 'px';
        }
    }

    isPageResizeReflected(): boolean {
        if(this.lastWindowHeight !== this.viewPortHeight()) {
            this.lastWindowHeight = this.viewPortHeight();
            return true;
        }
        return false;
    }

    private lightBannerHeight(): number {
        return this.bannerLightEl.nativeElement.offsetHeight;
    }

}
