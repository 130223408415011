<div class="grp-edt-rt">
    <nt-toolbar-btn [btnDescr]="undoBtn"
                    (ntclick)="undo()">
    </nt-toolbar-btn>

    <nt-toolbar-btn [btnDescr]="redoBtn"
                    (ntclick)="redo()">
    </nt-toolbar-btn>

    <nt-toolbar-btn *ngIf="checkPaddingPanelCache()"
                    [btnDescr]="paddingBtn"
                    (ntclick)="togglePressed(paddingBtn)">
        <nt-pgn-padding-edit [graphRenderer]="graphRenderer"></nt-pgn-padding-edit>
    </nt-toolbar-btn>

    <nt-toolbar-btn *ngIf="checkStrokePanelCache()"
                    [btnDescr]="strokeBtn"
                    (ntclick)="togglePressed(strokeBtn)">
        <nt-pgn-stroke-edit-popup [graphRenderer]="graphRenderer"></nt-pgn-stroke-edit-popup>
    </nt-toolbar-btn>

    <nt-toolbar-btn [btnDescr]="uploadBtn"
                    (ntclick)="uploadImg()">
    </nt-toolbar-btn>

    <nt-toolbar-btn [btnDescr]="zoomInBtn"
                    class="zm-in"
                    (ntclick)="zoomIn()">
    </nt-toolbar-btn>

    <nt-input [(ngModel)]="scale"
              [type]="'number'"
              [prefix]="'%'"
              [width]="'45px'"
              (ntapplychange)="applyZoomChange($event)"></nt-input>

    <nt-toolbar-btn [btnDescr]="zoomOutBtn"
                    class="zm-out"
                    (ntclick)="zoomOut()">
    </nt-toolbar-btn>
</div>
