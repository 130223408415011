import { Component, OnInit } from '@angular/core';
import {AbstractComponent} from '../../abstract-component';
import {ProductPage} from '../../product-page';
import {BreakpointObserver} from '@angular/cdk/layout';

@Component({
  selector: 'nt-math-glide',
  templateUrl: './math-glide.component.html',
  styleUrls: ['./math-glide.component.scss', '../content-container.component.scss']
})
export class MathGlideComponent extends AbstractComponent implements OnInit {
    static readonly PRODUCT = new ProductPage('/mathglide', 'Math-Glide');

    constructor(public breakpointObserver: BreakpointObserver) {
        super(breakpointObserver);
        MathGlideComponent.PRODUCT.pageComp = this;
    }

    ngOnInit() {
        super.ngOnInit();
    }

}
