import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {NtTabComponent} from '../nt-tab/nt-tab.component';
import {animate, style, transition, trigger} from '@angular/animations';
import {Subject, Subscription} from 'rxjs';

@Component({
    selector: 'nt-tabs',
    animations: [
        trigger('tab-chg', [
            transition('swtch => idle', [
                style({transform: 'translate({{dx}}, 0) scale({{scaleX}}, 1)'}),
                animate('0.2s',
                    style({transform: 'translate(0, 0) scale(1, 1)'})
                )
            ], {params: {dx: '0px', scaleX: '1'}})
        ])
    ],
    templateUrl: './nt-tabs.component.html',
    styleUrls: ['./nt-tabs.component.scss']
})
export class NtTabsComponent implements OnInit , AfterViewInit, OnDestroy {

    tabs: NtTabComponent[] = [];
    @Input() selectTabId: string;
    @Input() pageResizeSubject: Subject<void>;
    selectedTabLineWidth = '0';
    selectedTabLineHeight = '0';
    selectedTabLineLeft = '0';

    tabState='idle';
    lineTransitionDx='0px';
    lineTransitionScaleX='1';

    private pageResizeSubscription: Subscription;

    showLeftArrow = false;
    showRightArrow = false;


    constructor(private hostEl:ElementRef) {}

    ngOnInit(): void {
        if(this.pageResizeSubject) {
            this.pageResizeSubscription = this.pageResizeSubject.asObservable().subscribe(() => {
                this.updateHeaderResponsiveScrollArrows(null);
            });
        }
    }


    ngAfterViewInit(): void {
        this.updateSelectLine(null);
        const self = this;
        this.tick_then(() => {
            self.updateHeaderResponsiveScrollArrows(null);
        });
    }

    addTab(tab: NtTabComponent) {
        this.tabs.push(tab);
    }

    selectTab(tab: NtTabComponent) {
        let previousTabHeader: HTMLElement = this.hostEl.nativeElement.querySelector('.tab-slct');
        this.selectTabId = tab.tabId;
        this.updateSelectLine(previousTabHeader);
    }

    private updateSelectLine(previousTabHeader: HTMLElement) {
        const self = this;
        this.tick_then(() => {
            let selectedHeader: HTMLElement = self.hostEl.nativeElement.querySelector('.tab-slct');
            self.scrollToSelectedHeaderIfNeeded(selectedHeader);
            self.selectedTabLineWidth = selectedHeader.offsetWidth + 'px';
            self.selectedTabLineHeight = selectedHeader.offsetHeight + 'px';
            // let marginLeft = NtDomUtils.getHtmlElComputedDimensionInPixels(selectedHeader, 'margin-left');
            self.selectedTabLineLeft = selectedHeader.offsetLeft + 'px';
            if(previousTabHeader) {
                self.tabState = 'swtch';
                self.lineTransitionDx = (previousTabHeader.offsetLeft - selectedHeader.offsetLeft) + 'px';
                self.lineTransitionScaleX = (previousTabHeader.offsetWidth / selectedHeader.offsetWidth) + '';
                self.tick_then(() => {
                   self.tabState = 'idle';
                });
            }
        });
    }

    updateHeaderResponsiveScrollArrows(_e) {
        let header: HTMLElement = this.hostEl.nativeElement.querySelector('.tabs-header');
        let headerCtn: HTMLElement = this.hostEl.nativeElement.querySelector('.tabs-header-ctn');
        let headerX = header.getBoundingClientRect().x - headerCtn.getBoundingClientRect().x;
        let headerRightEdge = header.offsetWidth + headerX;
        this.showLeftArrow = headerX < 0;
        this.showRightArrow = headerRightEdge > headerCtn.offsetWidth;
    }

    leftArrowClicked(_e) {
        let headerCtn: HTMLElement = this.hostEl.nativeElement.querySelector('.tabs-header-ctn');
        headerCtn.scrollTo(0, 0);
    }

    rightArrowClicked(_e) {
        let headerCtn: HTMLElement = this.hostEl.nativeElement.querySelector('.tabs-header-ctn');
        let header: HTMLElement = this.hostEl.nativeElement.querySelector('.tabs-header');
        headerCtn.scrollTo(header.offsetWidth - headerCtn.offsetWidth, 0);
    }

    scrollToSelectedHeaderIfNeeded(selectedHeader: HTMLElement) {
        if(!this.showRightArrow && !this.showLeftArrow) {
            return;
        }
        let arrow: HTMLElement = this.hostEl.nativeElement.querySelector('.lft-arr-dv');
        if(arrow == null) {
            arrow = this.hostEl.nativeElement.querySelector('.rgt-arr-dv');
            if(arrow == null)
                return;
        }
        let header: HTMLElement = this.hostEl.nativeElement.querySelector('.tabs-header');
        let headerCtn: HTMLElement = this.hostEl.nativeElement.querySelector('.tabs-header-ctn');
        let selectedElClientX = selectedHeader.getBoundingClientRect().x;
        let startXRelativeToViewPort = selectedElClientX - headerCtn.getBoundingClientRect().x;
        let startXRelativeToParent = selectedElClientX - header.getBoundingClientRect().x;
        let isFirstTab = startXRelativeToParent === 0;
        if((startXRelativeToViewPort < arrow.offsetWidth && !isFirstTab) ||
            // first tab is guaranteed to have startXRelativeToViewPort <= 0 => if 0 there's no left arrow
            (isFirstTab && startXRelativeToViewPort < 0)) {
            let targetScrollX = startXRelativeToParent - arrow.offsetWidth;
            if(targetScrollX < 0) {
                targetScrollX = 0;
            }
            headerCtn.scrollTo(targetScrollX, 0);
        } else {
            let selectedRightEdgeRelativeToViewPort = startXRelativeToViewPort + selectedHeader.offsetWidth;
            let selectedRightEdgeRelativeToParent = startXRelativeToParent + selectedHeader.offsetWidth;
            let isLastTab = selectedRightEdgeRelativeToParent == header.offsetWidth;
            if((!isLastTab && selectedRightEdgeRelativeToViewPort > (headerCtn.offsetWidth - arrow.offsetWidth) ||
                (isLastTab && selectedRightEdgeRelativeToViewPort > headerCtn.offsetWidth))
            ) {
                // scrolling is needed
                let targetScrollRightEdge = startXRelativeToParent + selectedHeader.offsetWidth;
                if(!isLastTab) {
                    targetScrollRightEdge = targetScrollRightEdge - arrow.offsetWidth;
                }
                headerCtn.scrollTo(targetScrollRightEdge - selectedHeader.offsetWidth, 0);
            }
        }
    }

    private tick_then(fn: () => any) {
        setTimeout(fn, 0);
    }

    ngOnDestroy() {
        if(this.pageResizeSubscription) {
            this.pageResizeSubscription.unsubscribe();
        }
    }

}
