import {Component, Input, OnInit} from '@angular/core';
import {NtTabsComponent} from '../nt-tabs/nt-tabs.component';

@Component({
    selector: 'nt-tab',
    templateUrl: './nt-tab.component.html',
    styleUrls: ['./nt-tab.component.scss']
})
export class NtTabComponent implements OnInit {

    @Input() title: string;
    @Input() tabId: string;

    constructor(private parentTabContainer: NtTabsComponent) {
        this.parentTabContainer.addTab(this);
    }

    ngOnInit(): void {
    }

    public isSelected(): boolean {
        return this.tabId === this.parentTabContainer.selectTabId
    }

}
