

<nt-home *ngIf="isHomePage()"
         [productPage]="productPage"
         class="subpage"></nt-home>

<nt-ansi-highlighter *ngIf="isANSIHighlighterPage()"
                     [productPage]="productPage"
                     class="subpage"></nt-ansi-highlighter>

<nt-polygon *ngIf="isPolygonPage()"
            [productPage]="productPage"
            class="subpage"></nt-polygon>

<nt-lifeguard *ngIf="isLifeguardPage()"
              [productPage]="productPage"
              class="subpage"></nt-lifeguard>

<nt-math-glide *ngIf="isMathGlidePage()"
                  [productPage]="productPage"
                  class="subpage"></nt-math-glide>

<nt-ansi-highlighter-eula *ngIf="isANSIHighlighterEula()"
                          [productPage]="productPage"
                          class="subpage"></nt-ansi-highlighter-eula>

<nt-ansi-highlighter-license-register *ngIf="isANSIHighlighterLicenseRegister()"
                          [productPage]="productPage"
                          class="subpage"></nt-ansi-highlighter-license-register>

