import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ColorUtils} from '../../../../../utils/color-utils';
import {PgnMouseEditComponent} from '../mouse-edit/pgn-mouse-edit.component';
import {PgnGraphRenderer} from '../../core-canvas/graph/pgn-graph-renderer';
import {ToolbarButtonDescriptor} from '../../../../../utils/toolbar-btn/toolbar-button-descriptor';
import {bottomToolbarToggle, CVS_ROT_IN_TS, CVS_ROT_OUT_TS} from '../../../pgn-cvs-animations';


@Component({
    selector: 'nt-pgn-edit-container',
    animations: [
        bottomToolbarToggle
    ],
    templateUrl: './pgn-edit-container.component.html',
    styleUrls: ['./pgn-edit-container.component.scss']
})
export class PgnEditContainerComponent implements AfterViewInit, OnInit {

    @ViewChild(PgnMouseEditComponent) mouseEditComp: PgnMouseEditComponent;

    //todo cleanup unnecessary fields
    @Input() polygonId: string;
    @Input() graphRenderer: PgnGraphRenderer;
    selectionStrokeColor: string;
    hoverStrokeColor: string;
    editButtons = new Array<ToolbarButtonDescriptor>();
    selectedEditMode: ToolbarButtonDescriptor;

    public displayEditPanels = false;

    constructor() {
        this.editButtons.push(new ToolbarButtonDescriptor("bg-undo-32", "Undo", false));
        this.editButtons.push(new ToolbarButtonDescriptor("bg-redo-32", "Redo", false));

        this.editButtons.push(this.selectedEditMode = new ToolbarButtonDescriptor("bg-select-32", "Select"));
        this.editButtons.push(new ToolbarButtonDescriptor("bg-text-32", "Text"));

        this.editButtons.push(new ToolbarButtonDescriptor("bg-smooth-path-32", "Line"));
        this.editButtons.push(new ToolbarButtonDescriptor("bg-smooth-polygon-32", "Polygon"));

        this.editButtons.push(new ToolbarButtonDescriptor("bg-circle-32", "Circle"));
        this.editButtons.push(new ToolbarButtonDescriptor("bg-square-32", "Square"));

        this.editButtons.push(new ToolbarButtonDescriptor("bg-stroke-32", "Stroke"));
        this.editButtons.push(new ToolbarButtonDescriptor("bg-margins-32", "Margins"));

        this.editButtons.push(new ToolbarButtonDescriptor("bg-zoom-in-32", "Zoom-in", false));
        this.editButtons.push(new ToolbarButtonDescriptor("bg-zoom-out-32", "Zoom-out", false));

        this.editButtons.push(new ToolbarButtonDescriptor("bg-upload-32", "Upload Image", false));

        // this.editButtons.push(new PgnEditButton("bg-straight-path-32", "Straight Path"));

        // this.editButtons.push(new PgnEditButton("bg-delete-32", "Delete"));
        // this.editButtons.push(new PgnEditButton("bg-vertex-insert-32", "Insert Vertex"));
        // this.editButtons.push(new PgnEditButton("bg-vertex-delete-32", "Delete Vertex"));
        // this.editButtons.push(new PgnEditButton("bg-vertex-move-32", "Move Vertex"));
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(){
        if(this.graphRenderer) {
            this.graphRendererInitialized(this.graphRenderer);
        }
        const self = this;
        setTimeout(() => {
            self.displayEditPanels = true;
        }, (CVS_ROT_IN_TS + CVS_ROT_OUT_TS) * 1000);
    }

    onToolbarBtnPress(editBtn: ToolbarButtonDescriptor) {
        if(editBtn.isToggle) {
            if(this.selectedEditMode === editBtn) {
                return;
            }
            this.selectedEditMode.pressed = false;
            editBtn.pressed = true;
            this.selectedEditMode = editBtn;
        } else {

        }
        //todo
    }

    selectionStrokeColorChange(_newColor: string) {
        // todo
        // const graphRenderer = this.mouseEditComp.graphRenderer;
        // graphRenderer.strokeColor = ColorUtils.extractOpaqueColor(newColor);
        // graphRenderer.strokeOpacity = ColorUtils.extractOpacity(newColor);
    }

    hoverStrokeColorChange(_newColor: string) {
        // todo
        // const graphRenderer = this.mouseEditComp.graphRenderer;
        // graphRenderer.hStrokeColor = ColorUtils.extractOpaqueColor(newColor);
        // graphRenderer.hStrokeOpacity = ColorUtils.extractOpacity(newColor);
    }

    public graphRendererInitialized(graphRenderer: PgnGraphRenderer) {
        this.graphRenderer = graphRenderer;
        this.mouseEditComp.graphRendererInitialized(graphRenderer);
        this.selectionStrokeColor = ColorUtils.toColorWithAlpha(graphRenderer.strokeColor, graphRenderer.strokeOpacity);
        this.hoverStrokeColor = ColorUtils.toColorWithAlpha(graphRenderer.hStrokeColor, graphRenderer.hStrokeOpacity);
    }

    pageResizeReflected() {
        if(this.mouseEditComp) {
            this.mouseEditComp.pageResizeReflected();
        }
    }

    isPageResizeReflected(): boolean {
        return this.mouseEditComp && this.mouseEditComp.isPageResizeReflected();
    }

    pageTransitionStart() {
        if(this.mouseEditComp) {
            this.mouseEditComp.pageTransitionStart();
        }
    }

    pageTransitionEnd() {
        if(this.mouseEditComp) {
            this.mouseEditComp.pageTransitionEnd();
        }
    }

}


