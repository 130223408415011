import {QueryList} from '@angular/core';

export class NtUtils {

    static assign(target: any, source: any, ...exceptKeys: string[]) {
        let sourceCopy = {};
        Object.assign(sourceCopy, source);
        for(let key of exceptKeys) {
            delete sourceCopy[key];
        }
        Object.assign(target, sourceCopy);
    }

    static binarySearch<A, V>(arr: QueryList<A>, v: V, cmp: (v1: A, v2:V) => number): number {
        if(!arr || arr.length == 0) {
            return -1;
        }
        let start=0, end = arr.length - 1;

        while (true) {
            let mid = Math.floor((start + end)/2);

            if (cmp(arr.get(mid), v) === 0) {
                return mid;
            } else if (cmp(arr.get(mid), v) < 0) {
                start = mid + 1;
                if(start > end) {
                    // same as java implementation => when not found return a negative value pointing to where value can be inserted shifted by one position
                    return -start - 1;
                }
            } else {
                end = mid - 1;
                if(start > end) {
                    // same as java implementation => when not found return a negative value pointing to where value can be inserted shifted by one position
                    return -mid - 1;
                }
            }
        }

    }

    static in<V>(value: V, ...values: V[]): boolean {
        for(let v of values) {
            if(v === value) {
                return true;
            }
        }
        return false;
    }
}
