import {AfterViewInit, Component, ElementRef, Input, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {PgnLayerRenderer} from '../../core-canvas/layer/pgn-layer-renderer';
import {NtDragDropHandlerComponent} from '../../../../../utils/nt-drag-drop-handler/nt-drag-drop-handler.component';
import {EmptyClass} from '../../../../../utils/empty-class';
import {PgnGraphRenderer} from '../../core-canvas/graph/pgn-graph-renderer';
import {PgnLayerEditComponent} from '../pgn-layer-edit/pgn-layer-edit.component';
import {NtDraggableComponent} from '../../../../../utils/nt-draggable/nt-draggable.component';
import {Observable} from 'rxjs';
import {first, map} from 'rxjs/operators';
import {NtDndContext} from '../../../../../utils/nt-drag-drop-handler/nt-dnd-context';

@Component({
    selector: 'nt-pgn-layer-manager-panel',
    templateUrl: './pgn-layer-manager-panel.component.html',
    styleUrls: ['./pgn-layer-manager-panel.component.scss']
})
export class PgnLayerManagerPanelComponent extends NtDragDropHandlerComponent<EmptyClass, PgnLayerRenderer> implements AfterViewInit {

    @Input() graphRenderer: PgnGraphRenderer;

    @ViewChildren(PgnLayerEditComponent) subLayers: QueryList<PgnLayerEditComponent>;

    @ViewChild('lyrCtn') layerCtnEl: ElementRef;

    // @ts-ignore
    dropHandlers = new Array<QueryList<NtDragDropHandlerComponent>>()

    constructor(public hostEl: ElementRef) {
        super(hostEl);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    ngAfterViewInit(): void {
        // todo this.dropHandlers.push(interaction panel);
    }

    // ------------------ DnD Setup ----------------------------

    allowDrag(_e: MouseEvent): boolean {
        return false;
    }

    draggableDataArray(): Array<PgnLayerRenderer> {
        return this.graphRenderer.layers;
    }

    draggableList(): QueryList<PgnLayerEditComponent> {
        return this.subLayers;
    }

    draggablesDirectContainer(): ElementRef {
        return this.layerCtnEl;
    }

    protected draggablesMargin(): number {
        return 4;
    }

    // @ts-ignore
    protected allDropHandlers(): Array<QueryList<NtDragDropHandlerComponent>> {
        return this.dropHandlers;
    }

    public activateDnDLayoutAnimation() {
        return true;
    }

    // @ts-ignore
    protected createDataFromDuplicatedDroppedEl(draggable: NtDraggableComponent): PgnLayerRenderer {
        const comp = <PgnLayerEditComponent>draggable;
        return comp.layerRenderer().clone();
    }

    // @ts-ignore
    protected adaptMovedDataFromDroppedEl(draggable: NtDraggableComponent): PgnLayerRenderer {
        const comp = <PgnLayerEditComponent>draggable;
        return comp.layerRenderer();
    }

    // @ts-ignore
    resolveHandler(handlerKey: any): NtDragDropHandlerComponent {
        // todo
        return null;
    }

    restoreData(compressedLayer: any): PgnLayerRenderer {
        return <PgnLayerRenderer>compressedLayer;
    }

    protected isDropSupportedForDraggable<T, U extends NtDraggableComponent<T>>(draggable: U): boolean {
        return draggable instanceof PgnLayerEditComponent;
    }

    protected requestNewInnerLength(newInnerLength: number): number {
        return newInnerLength - this.layerCtnEl.nativeElement.offsetHeight;
    }

    protected shouldDismissIfEmptyAfterDnD(_dndCtx: NtDndContext): boolean {
        return false;
    }

}
