import {PgnShape} from './pgn-shape';

export class PgnCircleShape extends PgnShape {
    x: number;
    y: number;
    width: number;
    height: number;

    public applyDefaults() {
        // todo
    }

    public initGraphics(_document: Document) {
        // todo
    }

    public opaqueFillCvs(): HTMLCanvasElement {
        // todo
        return null;
    }

    public opaqueStrokeCvs(): HTMLCanvasElement {
        // todo
        return null;
    }

    public setEditMode(_isEditMode: boolean) {
        // todo
    }

    public clearEditModeGraphicsCache() {
        // todo
    }

    public clone(): PgnCircleShape {
        const clone = new PgnCircleShape();
        super.cloneInto(clone);
        // todo
        return clone;
    }
}
