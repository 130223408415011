import {NtDraggableComponent} from '../nt-draggable/nt-draggable.component';
import {NtDragHoverType} from './nt-drag-hover-type.enum';

export class NtDragHoverEvent {
    // @ts-ignore
    public constructor(public draggedEl: NtDraggableComponent,
                       // @ts-ignore
                       public lastHoveredDraggable: NtDraggableComponent,
                       // @ts-ignore
                       public hoveredDraggable: NtDraggableComponent,
                       public dragHoverType: NtDragHoverType,
                       public mouseEvent: MouseEvent) {
    }
}
