// Tags are a convenience feature meant to allow the injection of custom additional context
// when DnD utility functions are triggered. Injected tags are then made accessible
// through DnD callbacks for user custom implementation
// => if no custom tags are supplied by user, the below default enum tags will apply
// => custom tags supplied by users can be of any type, they do not have to match any of the below enum constants
// => tags may be ignored if not needed
export enum NtDndTriggerTag {

    // auto triggered by DnD abstract implementation in response to mouse interaction => automated / cannot be altered by user
    MOUSE_USER_INTERACTION,

    // default tag when trigger is NtDragDropHandlerComponent.moveDraggableToIndex() => may be altered/customized by user when they trigger the call
    REORDER_FN,

    // default tag when trigger is NtDragDropHandlerComponent.reverseReorder() => may be altered/customized by user when they trigger the call
    REORDER_REVERSAL_FN,

    // default tag when trigger is NtDragDropHandlerComponent.dndDuplicate() => may be altered/customized by user when they trigger the call
    DUPLICATE_FN,

    // default tag when trigger is NtDragDropHandlerComponent.reverseDnDDuplicate() => may be altered/customized by user when they trigger the call
    DUPLICATE_REVERSAL_FN,

    // default tag when trigger is NtDragDropHandlerComponent.dndMoveAcrossHandlers() => may be altered/customized by user when they trigger the call
    DND_ACROSS_HANDLERS_FN,

    // default tag when trigger is NtDragDropHandlerComponent.reverseDnDMoveAcrossHandlers() => may be altered/customized by user when they trigger the call
    DND_ACROSS_HANDLERS_REVERSAL_FN,

    // default tag when trigger is NtDragDropHandlerComponent.insertNewData() => may be altered/customized by user when they trigger the call
    INSERT_FN,

    // default tag when trigger is NtDragDropHandlerComponent.reverseInsert() => may be altered/customized by user when they trigger the call
    INSERT_REVERSAL_FN,

    // default tag when trigger is NtDragDropHandlerComponent.removeDraggable() or NtDragDropHandlerComponent.removeDraggableAt() => may be altered/customized by user when they trigger the call
    REMOVE_FN,

    // default tag when trigger is NtDragDropHandlerComponent.reverseDraggableRemoval() => may be altered/customized by user when they trigger the call
    REMOVE_REVERSAL_FN
}
