import {PgnShape} from './pgn-shape';

export class PgnLineShape extends PgnShape {
    polygon: number[];
    tension: number;

    constructor() {
        super();
    }

    public applyDefaults() {
        if(this.tension !== 0 && !this.tension) {
            this.tension = 0.3;
        }
        //todo
    }

    public initGraphics(_document: Document) {
        // todo
    }

    public opaqueFillCvs(): HTMLCanvasElement {
        // todo
        return null;
    }

    public opaqueStrokeCvs(): HTMLCanvasElement {
        // todo
        return null;
    }

    public setEditMode(_isEditMode: boolean) {
        // todo
    }

    public clearEditModeGraphicsCache() {
        // todo
    }

    public clone(): PgnLineShape {
        const clone = new PgnLineShape();
        super.cloneInto(clone);
        // todo
        return clone;
    }

}
