export class ResponsiveConstants {

    public static readonly HANDSET_MAX_W = 1023;
    public static readonly HANDSET_MEDIA_QUERY = '(max-width: ' + ResponsiveConstants.HANDSET_MAX_W + 'px)';

    public static readonly SM_MAX_W = 499;
    public static readonly SM_MEDIA_QUERY = '(max-width: ' + ResponsiveConstants.SM_MAX_W + 'px)';

    public static readonly MD_MIN_W = 500;
    public static readonly MD_MAX_W = 799;
    public static readonly MD_MEDIA_QUERY = '(min-width: ' + ResponsiveConstants.MD_MIN_W + 'px) and (max-width: ' + ResponsiveConstants.MD_MAX_W + 'px)';

    public static readonly LG_MIN_W = 800;
    public static readonly LG_MAX_W = 1023;
    public static readonly LG_MEDIA_QUERY = '(min-width: ' + ResponsiveConstants.LG_MIN_W + 'px) and (max-width: ' + ResponsiveConstants.LG_MAX_W + 'px)';

    public static readonly XL_MIN_W = 1024;
    public static readonly XL_MEDIA_QUERY = '(min-width: ' + ResponsiveConstants.XL_MIN_W + 'px)';
}
