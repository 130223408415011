import {PgnLayerRenderer} from './pgn-layer-renderer';
import {PgnLayerGroup} from './pgn-layer-group';
import {PgnRawLayerGroup} from './pgn-raw-layer-group';

export class PgnLayerGroupFactory {

    public static toLayerGroup(rawGroup: PgnRawLayerGroup<string>,
                               layerMap: { [layerName: string] : PgnLayerRenderer; }): PgnLayerGroup {
        const group = new PgnLayerGroup();
        group.name = rawGroup.name;
        group.layers = new Array<PgnLayerRenderer>();
        for(const layerName of rawGroup.layers) {
            const layer = layerMap[layerName];
            group.layers.push(layer);
            layer.group = group;
        }
        return group;
    }
}
