import {Component, ElementRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {ProductPage} from '../product-page';
import {Subject} from 'rxjs';
import {trigger, state, style, animate, transition, keyframes} from '@angular/animations';
import {AbstractComponent} from '../abstract-component';
import {BreakpointObserver} from '@angular/cdk/layout';


@Component({
    selector: 'nt-content-container',
    animations: [
        trigger('currentTab', [
            state('open', style({
                visibility: 'visible',
                opacity: 1,
                zIndex: 90
            })),
            state('closed', style({
                visibility: 'hidden',
                opacity: 0,
                zIndex: 0
            })),
            transition('open => closed', [
                animate('0.3s', keyframes([
                    style({opacity: 1.0, zIndex:90, offset: 0.0}),
                    style({opacity: 0.0, transform: 'rotateX(90deg)', transformOrigin: '0% 100%', offset: 1.0})
                ]))
            ]),
            transition('closed => open', [
                 animate('0.3s 0.2s', keyframes([
                     style({visibility: 'visible', transform: 'rotateX(-90deg)', transformOrigin: '0% 0%', offset: 0.0}),
                     style({opacity: 1.0,  transform: 'rotateX(0deg)',  offset: 1.0})
                 ]))
            ]),
        ])
    ],
    templateUrl: './content-container.component.html',
    styleUrls: ['./content-container.component.scss']
})
export class ContentContainerComponent extends AbstractComponent implements OnInit {

    @Input() productPages: Array<ProductPage>;
    @Input() animationEndEventSubject: Subject<ProductPage>;

    constructor(public breakpointObserver: BreakpointObserver) {
        super(breakpointObserver);
    }

    ngOnInit() {
        super.ngOnInit();
    }


    transitionStart(page: ProductPage) {
        page.pageTransitionStart();
        this.tick_then(() => {
            page.afterPageTransitionStart();
        });
    }


    transitionEnd(page: ProductPage) {
        page.pageTransitionEnd();
        if(page.pageSelected) {
            this.animationEndEventSubject.next(page);
        } else {
            page.pageElement.scrollTop = 0;
        }
    }

    responsiveBreakPointChange() {
        this.resetDocDimensions();
    }

    @HostListener('window:resize', ['$event'])
    onWindowResize(_event) {
        this.resetDocDimensions();
        for(let page of this.productPages) {
            page.pageResized();
        }
    }
}
