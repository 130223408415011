import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {PgnGraphRenderer} from '../../core-canvas/graph/pgn-graph-renderer';
import {PgnEditPanelComponent} from '../pgn-edit-panel/pgn-edit-panel.component';
import {ToolbarButtonDescriptor} from '../../../../../utils/toolbar-btn/toolbar-button-descriptor';
import {PopupType} from '../../../../../utils/popup-wrapper/popup-type.enum';

@Component({
    selector: 'nt-pgn-stroke-edit-popup',
    templateUrl: './pgn-stroke-edit-popup.component.html',
    styleUrls: ['./pgn-stroke-edit-popup.component.scss',
        '../pgn-edit-panel/pgn-edit-panel.component.scss']
})
export class PgnStrokeEditPopupComponent extends PgnEditPanelComponent {

    public static panelWidthCache:number = null;
    public static panelHeightCache:number = null;

    strokeColorBtn = new ToolbarButtonDescriptor("",
        "Color",
        true,
        'small',
        'bg1',
        PopupType.RIGHT,
        30, 30,
        'black');

    hStrokeColorBtn = new ToolbarButtonDescriptor("",
        "Highlight Color",
        true,
        'small',
        'bg1',
        PopupType.RIGHT,
        30, 30,
        'black');

    constructor(protected hostEl:ElementRef) {
        super(hostEl);
    }

    ngOnInit(): void {
        this.strokeColorBtn.color = this.graphRenderer.strokeColor ?
            this.graphRenderer.strokeColor : '#000000';
        this.hStrokeColorBtn.color = this.graphRenderer.hStrokeColor ?
            this.graphRenderer.hStrokeColor : '#000000';
    }

    // the root add component adds a fake instance briefly to figure out to figure out the size dynamically and cache it
    // cache is necessary to embed the panel under a popup component
    protected sizeInitialized(width: number, height: number) {
        if(!PgnStrokeEditPopupComponent.panelHeightCache) {
            PgnStrokeEditPopupComponent.panelWidthCache = width;
            PgnStrokeEditPopupComponent.panelHeightCache = height;
        }
    }

    public applyGraphChange(_potentialSizeChange: boolean) {
        // super.applyGraphChange(potentialSizeChange);
        //todo
    }

}
