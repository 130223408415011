import {PopupType} from '../popup-wrapper/popup-type.enum';
import {PopupWrapperComponent} from '../popup-wrapper/popup-wrapper.component';
import {Subject} from 'rxjs';

export class ToolbarButtonDescriptor {
    public hovered = false;
    private _pressed = false;
    public isPopupAnimating = false;
    constructor(public imgBgCls: string,
                public tooltip: string,
                public isToggle: boolean = true,
                public paddingCls: string = 'small',
                public bgAnimationState: string = 'bg1',
                public popupType: PopupType = PopupType.NONE,
                public popupHeight: number = 0,
                public popupWidth: number = 0,
                public color: string = null) {
    }

    public fullPopupHeight(): string {
        return PopupWrapperComponent.fullPopupHeight(this.popupHeight,
            this.popupType === PopupType.BOTTOM || this.popupType === PopupType.TOP);
    }

    isTopPopup(): boolean {
        return this.popupType === PopupType.TOP;
    }

    showTopPopup(): boolean {
        return this.popupType === PopupType.TOP && this.showPopup();
    }

    isBottomPopup(): boolean {
        return this.popupType === PopupType.BOTTOM;
    }

    showBottomPopup(): boolean {
        return this.popupType === PopupType.BOTTOM && this.showPopup();
    }

    showLeftPopup(): boolean {
        return this.popupType === PopupType.LEFT && this.showPopup();
    }

    showRightPopup(): boolean {
        return this.popupType === PopupType.RIGHT && this.showPopup();
    }

    showPopup(): boolean {
        return this.popupType !== PopupType.NONE && this.isToggle &&
            (this._pressed || this.isPopupAnimating);
    }

    public set pressed(pressed: boolean) {
        if(this._pressed === pressed) {
            return;
        }
        this._pressed = pressed;
        if(this.hasPopup()) {
            this.isPopupAnimating = true;
        }
    }

    public get pressed(): boolean {
        return this._pressed;
    }

    public animationState(): string {
        return this.pressed ? 'selected' :
            ((this.hovered ? 'hovered-' : 'idle-') + this.bgAnimationState);
    }

    public cssClass(): string {
        return this.pressed ? 'btn-selected' :
            ((this.hovered ? 'btn-hovered-' : 'btn-idle-') + this.bgAnimationState);
    }

    hasPopup(): boolean {
        return this.isToggle && this.popupType !== PopupType.NONE;
    }
}
