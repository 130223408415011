import {Input, OnInit, Component} from '@angular/core';
import {ProductPage} from './product-page';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {ResponsiveConstants} from './responsive-constants';

@Component({
    template: ''
})
export abstract class AbstractComponent implements OnInit {

    @Input() productPage: ProductPage;
    public responsiveCls: string;
    public isHandset: boolean;

    private pageResizeEventId = 0;
    protected constructor(public breakpointObserver: BreakpointObserver) { }

    ngOnInit(): void {
        this.responsiveCls = this.breakpointObserver.isMatched(ResponsiveConstants.SM_MEDIA_QUERY) ? 'nt-sm' :
            this.breakpointObserver.isMatched(ResponsiveConstants.MD_MEDIA_QUERY) ? 'nt-md' :
                this.breakpointObserver.isMatched(ResponsiveConstants.LG_MEDIA_QUERY) ? 'nt-lg' : 'nt-xl';
        this.observeMediaQuery(ResponsiveConstants.SM_MEDIA_QUERY, 'nt-sm');
        this.observeMediaQuery(ResponsiveConstants.MD_MEDIA_QUERY, 'nt-md');
        this.observeMediaQuery(ResponsiveConstants.LG_MEDIA_QUERY, 'nt-lg');
        this.observeMediaQuery(ResponsiveConstants.XL_MEDIA_QUERY, 'nt-xl');

        this.isHandset = this.breakpointObserver.isMatched(ResponsiveConstants.HANDSET_MEDIA_QUERY);
        const self = this;
        this.breakpointObserver
            .observe(ResponsiveConstants.HANDSET_MEDIA_QUERY)
            .subscribe((st: BreakpointState) => {
                self.isHandset = st.matches;
            });
    }

    private observeMediaQuery(query: string, cls: string) {
        const self = this;
        this.breakpointObserver
            .observe(query)
            .subscribe((st: BreakpointState) => {
                if(st.matches) {
                    self.responsiveCls = cls;
                    self.responsiveBreakPointChange();
                }
            });
    }

    pageTransitionStart() {
        // to implement by page subclasses if needed
    }

    pageTransitionEnd() {
        // to implement by page subclasses if needed
    }

    afterPageTransitionStart() {
        // to implement by page subclasses if needed
    }

    responsiveBreakPointChange() {
        this.pageResized();
    }

    pageResized() {
        const self = this;
        const eventId = ++ this.pageResizeEventId;
        this.waitForThen(() => self.isPageResizeReflected(),
            () => eventId !== self.pageResizeEventId,
            () => self.pageResizeReflected(),
            5000);
    }

    isPageResizeReflected(): boolean {
        // to implement by subclasses where needed
        return false;
    }

    pageResizeReflected() {
        // to implement by subclasses where needed
    }

    protected waitForThen(condition: () => boolean,
                          cancelCondition: () => boolean,
                          callback: () => void,
                          timeout: number) {
        if(cancelCondition()) {
            return;
        }
        if(condition()) {
            callback();
            // don't end wait here, keep checking until size stabilizes or timeout is reached
        }
        if(timeout <= 0) {
            return;
        }
        const self = this;
        setTimeout(() => self.waitForThen(condition, cancelCondition, callback, timeout - 20), 20);
    }

    protected tick_then(fn: () => any) {
        setTimeout(fn, 0);
    }

    viewPortHeight(): number {
        return Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    }

    docDimensionsToPixels() {
        document.documentElement.style.height = this.viewPortHeight() + 'px';
        document.documentElement.style.width = window.innerWidth + 'px';
        document.body.style.height = this.viewPortHeight() + 'px';
        document.body.style.width = window.innerWidth + 'px';
    }

    resetDocDimensions() {
        document.documentElement.style.height = '100vh';
        document.documentElement.style.width = '100vw';
        document.body.style.height = '100vh';
        document.body.style.width = '100vw';
    }
}
