import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ToolbarButtonDescriptor} from '../toolbar-btn/toolbar-button-descriptor';
import {bottomPopInAnim, bottomPopOutAnim, horizontalPopInAnim, horizontalPopOutAnim, topPopInAnim, topPopOutAnim} from './popup-animation';
import {AnimationEvent} from '@angular/animations';
import {Subject} from 'rxjs';
import {NtAbstractCompComponent} from '../nt-abstract-comp/nt-abstract-comp.component';

export const POPUP_TRGL_HEIGHT = 10;

const POPOUT_POP_WRP_ORIGIN = 'pop-wrp';

@Component({
    selector: 'nt-popup-wrapper',
    templateUrl: './popup-wrapper.component.html',
    styleUrls: ['./popup-wrapper.component.scss'],
    animations: [
        horizontalPopInAnim,
        horizontalPopOutAnim,
        bottomPopInAnim,
        bottomPopOutAnim,
        topPopInAnim,
        topPopOutAnim
    ]
})
export class PopupWrapperComponent extends NtAbstractCompComponent {

    @Input() triggerWidth: number;
    @Input() triggerHeight: number;

    @Input() btnDescr: ToolbarButtonDescriptor;

    @Output() ntpopinend = new EventEmitter<boolean>();
    @Output() ntpopoutend = new EventEmitter<boolean>();
    @Input() beforePopoutSubject: Subject<string>;

    overflowCls = 'ovf-hidden';


    constructor() {
        super();
    }

    ngOnInit(): void {
        const self = this;
        this.beforePopoutSubject.asObservable().subscribe((origin) => {
            if(origin === POPOUT_POP_WRP_ORIGIN) {
                return;
            }
            self.overflowCls = 'ovf-hidden';
            self.tick_then(() => {
                self.beforePopoutSubject.next(POPOUT_POP_WRP_ORIGIN);
            });
        });
    }

    public static fullPopupHeight(height: number, isVertical: boolean): string {
        return (isVertical ? (height + POPUP_TRGL_HEIGHT) : (height)) + 'px';
    }

    popInAnimationEnd(e: AnimationEvent) {
        if(e.totalTime > 0) {
            this.overflowCls = 'ovf-visible';
            this.ntpopinend.emit(true);
        }
    }

    popOutAnimationEnd(e: AnimationEvent) {
        if(e.totalTime > 0) {
            this.ntpopoutend.emit(true);
        }
    }

}
