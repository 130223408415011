import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {PgnEditPanelComponent} from '../pgn-edit-panel/pgn-edit-panel.component';

@Component({
    selector: 'nt-pgn-padding-edit',
    templateUrl: './pgn-padding-edit.component.html',
    styleUrls: ['./pgn-padding-edit.component.scss',
        '../pgn-edit-panel/pgn-edit-panel.component.scss']
})
export class PgnPaddingEditComponent extends PgnEditPanelComponent {

    public static panelWidthCache:number = null;
    public static panelHeightCache:number = null;

    constructor(protected hostEl:ElementRef) {
        super(hostEl);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    // the root add component adds a fake instance briefly to figure out to figure out the size dynamically and cache it
    // cache is necessary to embed the panel under a popup component
    protected sizeInitialized(width: number, height: number) {
        if(!PgnPaddingEditComponent.panelHeightCache) {
            PgnPaddingEditComponent.panelWidthCache = width;
            PgnPaddingEditComponent.panelHeightCache = height;
        }
    }

    public applyGraphChange(_potentialSizeChange: boolean) {
        // super.applyGraphChange(potentialSizeChange);
        // todo
    }

}
