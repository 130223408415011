import {AfterViewInit, Component, ElementRef, Input, OnInit} from '@angular/core';
import {ProductPage} from '../../product-page';
import {LifeguardComponent} from '../lifeguard/lifeguard.component';
import {AnsiHighlighterComponent} from '../ansi-highlighter/ansi-highlighter.component';
import {MathGlideComponent} from '../math-glide/math-glide.component';
import {PolygonComponent} from '../polygon/polygon.component';
import {NtHomeComponent} from '../nt-home/nt-home.component';
import {AnsiHighlighterEulaComponent} from '../ansi-highlighter/ansi-highlighter-eula/ansi-highlighter-eula.component';
import {AnsiHighlighterLicenseRegisterComponent} from '../ansi-highlighter/ansi-highlighter-license-register/ansi-highlighter-license-register.component';

@Component({
  selector: 'nt-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit, AfterViewInit {

    @Input() productPage: ProductPage;
    constructor(private elRef: ElementRef) {}

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.productPage.pageElement = this.elRef.nativeElement;
    }

    isHomePage() {
        return NtHomeComponent.PRODUCT.path === this.productPage.path;
    }

    isANSIHighlighterPage() {
        return AnsiHighlighterComponent.PRODUCT.path === this.productPage.path;
    }

    isPolygonPage() {
        return PolygonComponent.PRODUCT.path === this.productPage.path;
    }

    isLifeguardPage() {
        return LifeguardComponent.PRODUCT.path === this.productPage.path;
    }

    isMathGlidePage() {
        return MathGlideComponent.PRODUCT.path === this.productPage.path;
    }

    isANSIHighlighterEula() {
        return AnsiHighlighterEulaComponent.PRODUCT.path === this.productPage.path;
    }

    isANSIHighlighterLicenseRegister() {
        return AnsiHighlighterLicenseRegisterComponent.PRODUCT.path === this.productPage.path;
    }

}
