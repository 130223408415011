import {NtSizeObservableContext} from './nt-size-observable-context';

export class NtSizeChangeCompContext {

    private static auto_id = 0;

    public ctxs = new Array<NtSizeObservableContext>();
    public id: number;

    constructor() {
        this.id = ++NtSizeChangeCompContext.auto_id;
    }

    public addObervableContext(ctx: NtSizeObservableContext) {
        this.ctxs.push(ctx);
    }

    public notify() {
        for(const ctx of this.ctxs) {
            if(ctx.updateSizeIfChanged()) {
                ctx.callback(ctx.lastWidth, ctx.lastHeight);
            }
        }
    }

    public unobserve(sizeObserver: any) {
        for(const ctx of this.ctxs) {
            sizeObserver.unobserve(ctx.el.nativeElement);
        }
        this.ctxs = null;
    }
}
