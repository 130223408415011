import {Component, ElementRef} from '@angular/core';
import {NtDraggableComponent} from '../../../../../utils/nt-draggable/nt-draggable.component';
import {PgnShape} from '../../core-canvas/shape/pgn-shape';

@Component({
    selector: 'nt-pgn-shape-edit',
    templateUrl: './pgn-shape-edit.component.html',
    styleUrls: ['./pgn-shape-edit.component.scss']
})
export class PgnShapeEditComponent extends NtDraggableComponent<PgnShape> {

    constructor(public hostEl: ElementRef) {
        super(hostEl);
    }

    shape(): PgnShape {
        return this.data;
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    // -------------------------- DnD Setup ---------------------------

    allowDrag(_e: MouseEvent): boolean {
        return true;
    }

}
