import {Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {NtSizeObservableContext} from './nt-size-observable-context';
import {NtPoint} from '../nt-point';
import {NtSizeChangeCompContext} from './nt-size-change-comp-context';
import {NtDomUtils} from '../nt-dom-utils';

@Component({
    selector: 'nt-abstract-comp',
    templateUrl: './nt-abstract-comp.component.html',
    styleUrls: ['./nt-abstract-comp.component.scss']
})
export class NtAbstractCompComponent implements OnInit, OnDestroy {

    private sizeObservableCtx: NtSizeChangeCompContext;

    constructor() { }

    ngOnInit(): void {
    }

    protected observeSizeChange(el : ElementRef,
                                callback: (newWidth: number, newHeight: number) => void) {
        if(!this.sizeObservableCtx) {
            this.sizeObservableCtx = new NtSizeChangeCompContext();
        }
        const ctx = new NtSizeObservableContext(this.sizeObservableCtx, el, callback);
        NtDomUtils.observeSizeChange(ctx);
    }

    ngOnDestroy(): void {
        if(!this.sizeObservableCtx) {
            return;
        }
        NtDomUtils.unregisterSizeChangeObservers(this.sizeObservableCtx);
    }

    protected tick_then(fn: () => any) {
        setTimeout(fn, 0);
    }

}
