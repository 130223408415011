
<svg style="display: none">
    <defs>
        <path id="check" class="chk" d="M800,510a30,30,0,1,1,30-30A30,30,0,0,1,800,510Zm-16.986-23.235a3.484,3.484,0,0,1,0-4.9l1.766-1.756a3.185,3.185,0,0,1,4.574.051l3.12,3.237a1.592,1.592,0,0,0,2.311,0l15.9-16.39a3.187,3.187,0,0,1,4.6-.027L817,468.714a3.482,3.482,0,0,1,0,4.846l-21.109,21.451a3.185,3.185,0,0,1-4.552.03Z" transform="scale(0.25) translate(-770 -450)"/>
    </defs>
</svg>

<div class="ml-rt {{responsiveCls}}">
    <div class="ml-ctn">
        <div class="ml-ctn2">
            <div class="ml-wrp">
                <input placeholder="Email"
                       type="text"
                       class="in"
                       autocapitalize="off"
                       autocomplete="off"
                       autocorrect="off"
                       spellcheck="false"
                       #ipt
                       (focus)="fixMobileScrollOutOfViewGlitch($event)"
                       (focusout)="reverseScrollOutOfViewGlitchFix($event)"
                       [(ngModel)]="email"/>
                <svg *ngIf="submitSuccess" class="ok-svg" height="15" width="15" viewBox="0 0 15 15">
                    <use xlink:href = "#check"/>
                </svg>
            </div>
            <button class="sbmt-btn"
                    #submt
                    (click)="onSubmit($event)"
            >Submit</button>
        </div>
        <span *ngIf="errMsg" class="err-lbl">{{errMsg}}</span>
        <span *ngIf="submitSuccess" class="ok-lbl">All good, thank you for your interest! we will keep you in the loop.</span>
    </div>
</div>

