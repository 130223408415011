import { Component, OnInit } from '@angular/core';
import {ProductPage} from '../../../product-page';
import {BreakpointObserver} from '@angular/cdk/layout';
import {AbstractComponent} from '../../../abstract-component';
import {Subject} from 'rxjs';

@Component({
    selector: 'nt-ansi-highlighter-license-register',
    templateUrl: './ansi-highlighter-license-register.component.html',
    styleUrls: ['./ansi-highlighter-license-register.component.scss', '../../content-container.component.scss']
})
export class AnsiHighlighterLicenseRegisterComponent extends AbstractComponent implements OnInit {
    static readonly PRODUCT = new ProductPage('/ansihighlighter/registerlicense',
        '',// navTitle doesn't apply
        'ANSI Highlighter Premium Registration - Nifty Thoughts',
        'ANSI Highlighter Premium License Registration',
        'ANSI Highlighter',
        false);
    // ,'/ansihighlighter/registerlicense/\\d+');

    private lastWindowWidth = 0;

    pageResizeSubject = new Subject<void>();

    constructor(public breakpointObserver: BreakpointObserver) {
        super(breakpointObserver);
        AnsiHighlighterLicenseRegisterComponent.PRODUCT.pageComp = this;
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    isPageResizeReflected(): boolean {
        if(this.lastWindowWidth !== window.innerWidth) {
            this.lastWindowWidth = window.innerWidth;
            return true;
        }
        return false;
    }

    pageResizeReflected() {
        this.pageResizeSubject.next();

    }

}
