import {Component, ElementRef} from '@angular/core';
import {ToolbarButtonDescriptor} from '../../../../../utils/toolbar-btn/toolbar-button-descriptor';
import {PopupType} from '../../../../../utils/popup-wrapper/popup-type.enum';
import {NtColor} from '../../../../../utils/nt-color-picker/nt-color';
import {PgnEditPanelComponent} from '../pgn-edit-panel/pgn-edit-panel.component';
import {PgnPaddingEditComponent} from '../pgn-padding-edit/pgn-padding-edit.component';
import {PgnStrokeEditPopupComponent} from '../pgn-stroke-edit-popup/pgn-stroke-edit-popup.component';

@Component({
    selector: 'nt-pgn-graph-edit-toolbar',
    templateUrl: './pgn-graph-edit-toolbar.component.html',
    styleUrls: ['./pgn-graph-edit-toolbar.component.scss']
})
export class PgnGraphEditToolbarComponent extends PgnEditPanelComponent {

    undoBtn = new ToolbarButtonDescriptor("bg-undo-32", "Undo", false);
    redoBtn = new ToolbarButtonDescriptor("bg-redo-32", "Redo", false);
    paddingBtn = new ToolbarButtonDescriptor("bg-padding-32",
        "Padding",
        true,
        'small',
        'bg1',
        PopupType.BOTTOM,
        220);
    strokeBtn = new ToolbarButtonDescriptor("bg-stroke-32",
        "Default Stroke",
        true,
        'small',
        'bg1',
        PopupType.BOTTOM,
        120);
    uploadBtn = new ToolbarButtonDescriptor("bg-upload-32", "Upload Image", false);
    zoomInBtn = new ToolbarButtonDescriptor("bg-zoom-in-32", "Zoom In", false);
    zoomOutBtn = new ToolbarButtonDescriptor("bg-zoom-out-32", "Zoom Out", false);

    selectedTgl: ToolbarButtonDescriptor;

    private _scale = 50;

    constructor(protected hostEl:ElementRef) {
        super(hostEl);
    }

    ngOnInit(): void {
    }

    public get scale() {
        return this._scale;
    }

    public set scale(scale: number) {
        this._scale = scale;
        this.graphRenderer.scaleX = this.graphRenderer.scaleY = this._scale / 100;
        //todo
    }

    checkPaddingPanelCache(): boolean {
        if(!PgnPaddingEditComponent.panelHeightCache) {
            return false;
        }
        this.paddingBtn.popupWidth = PgnPaddingEditComponent.panelWidthCache;
        this.paddingBtn.popupHeight = PgnPaddingEditComponent.panelHeightCache;
        return true;
    }

    checkStrokePanelCache(): boolean {
        if(!PgnStrokeEditPopupComponent.panelHeightCache) {
            return false;
        }
        this.strokeBtn.popupWidth = PgnStrokeEditPopupComponent.panelWidthCache;
        this.strokeBtn.popupHeight = PgnStrokeEditPopupComponent.panelHeightCache;
        return true;
    }

    togglePressed(tgl: ToolbarButtonDescriptor) {
        if(tgl === this.selectedTgl) {
            tgl.pressed = !tgl.pressed;
            return;
        }
        this.selectedTgl = tgl;
        tgl.pressed = true;
    }

    undo() {
        //todo
    }

    redo() {
        // todo
    }

    applyPadding() {
        // todo
    }

    applyStroke() {
        // todo
    }

    uploadImg() {
        // todo
    }

    zoomIn() {
        // todo
    }

    zoomOut() {
        // todo
    }

    applyZoomChange(newVal: string) {
        // todo
       console.log('new zoom value: ' + newVal + '   ' + this.scale);
    }

    clrChange(clr: NtColor) {
        console.log('change ' + clr.toHex8String());
        // todo
    }

    clrMove(clr: NtColor) {
        console.log('move ' + clr.toHex8String());
        //todo
    }
}
