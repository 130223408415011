import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {NtColor} from './nt-color';
import {ToolbarButtonDescriptor} from '../toolbar-btn/toolbar-button-descriptor';
import {PopupType} from '../popup-wrapper/popup-type.enum';
import {NtAbstractCompComponent} from '../nt-abstract-comp/nt-abstract-comp.component';
import {NTCacheInitPhase} from './nt-cache-init-phase.enum';

declare const $: any;

@Component({
  selector: 'nt-color-picker',
  templateUrl: './nt-color-picker.component.html',
  styleUrls: ['./nt-color-picker.component.scss']
})
export class NtColorPickerComponent extends NtAbstractCompComponent implements AfterViewInit {

    public static pickerWidthCache: number = null;
    public static pickerHeightCache: number = null;
    public static initPhase = NTCacheInitPhase.NOT_INITIALIZED;

    @ViewChild('in') input: ElementRef;

    @ViewChild('clrPckBtn', {read: ElementRef}) clrPckBtn: ElementRef;

    @ViewChild('inInit') initTmpInput: ElementRef;

    @ViewChild('initTmpCtn') initTmpCtn: ElementRef;

    @ViewChild('clrPckCtn') pickerContainer: ElementRef;

    @Input() btnDescr: ToolbarButtonDescriptor;

    @Output() ntcolorChange: EventEmitter<NtColor> = new EventEmitter<NtColor>();

    @Output() ntcolorMove: EventEmitter<NtColor> = new EventEmitter<NtColor>();

    changecolor: string;

    paddingTop = 10;
    paddingBottom = 9;
    paddingLeft = 14;
    paddingRight = 12;

    constructor() {
        super();
    }

    ngOnInit(): void {
        if(!this.btnDescr) {
            this.btnDescr = new ToolbarButtonDescriptor("",
                "Color",
                true,
                'small',
                'bg1',
                PopupType.RIGHT,
                30, 30,
                'black');
        }
        this.changecolor = this.btnDescr.color;
    }

    ngAfterViewInit(): void {
        if(this.isInitialized()) {
            // necessary to cache picker size in order to prevent parent/ancestor animations altering the dimensions
            const self = this;
            this.tick_then(() => {
                self.loadPickerDimensionFromCache();
            });
            return;
        } else if (NtColorPickerComponent.initPhase === NTCacheInitPhase.NOT_INITIALIZED) {
            NtColorPickerComponent.initPhase = NTCacheInitPhase.PENDING;
            const self = this;
            $(this.initTmpInput.nativeElement).spectrum({
                type: "flat",
                showInput: true,
                allowEmpty: false,
                chooseText: "Apply"
            });
            this.tick_then(() => {
                NtColorPickerComponent.pickerWidthCache = self.initTmpCtn.nativeElement.getBoundingClientRect().width
                    + self.paddingLeft + self.paddingRight;
                NtColorPickerComponent.pickerHeightCache = self.initTmpCtn.nativeElement.getBoundingClientRect().height +
                    self.paddingTop + self.paddingBottom;
                self.loadPickerDimensionFromCache();
                NtColorPickerComponent.initPhase = NTCacheInitPhase.INITIALIZED;
            });
        }
    }

    isInitialized(): boolean {
        return NtColorPickerComponent.initPhase === NTCacheInitPhase.INITIALIZED;
    }

    private loadPickerDimensionFromCache() {
        this.btnDescr.popupWidth = NtColorPickerComponent.pickerWidthCache;
        this.btnDescr.popupHeight = NtColorPickerComponent.pickerHeightCache;
    }

    togglePicker() {
        this.btnDescr.pressed = !this.btnDescr.pressed;
        if(this.btnDescr.pressed) {
            const self = this;
            this.tick_then(() => {
                $(this.input.nativeElement).spectrum({
                    type: "flat",
                    showInput: true,
                    allowEmpty: false,
                    chooseText: "Apply",
                    change: (newColor) => self.colorChange(newColor),
                    move: (newColor) => self.colorMove(newColor)
                });
                self.tick_then(() => {
                    $(self.pickerContainer.nativeElement)
                        .find('.sp-cancel').click(() => {
                        const newColor = $(this.input.nativeElement).spectrum('get');
                        self.btnDescr.color = newColor.toString();
                        self.colorChange(newColor);
                        self.btnDescr.pressed = false;
                    });
                    $(self.pickerContainer.nativeElement)
                        .find('.sp-container').css({
                        'background-color': 'transparent',
                        'box-shadow': 'none'
                    });
                    $(self.pickerContainer.nativeElement)
                        .find('.sp-input').css({
                        'background-color': 'white'
                    });
                });
            });
        }

    }

    pickerPadding() {
        return this.paddingTop + 'px ' +
            this.paddingLeft + 'px ' +
            this.paddingBottom + 'px ' +
            this.paddingRight + 'px '

    }

    colorChange(newColor: any) {
        const newVal = newColor.toString();
        if(newVal === this.changecolor || newVal !== this.btnDescr.color) {
            return;
        }
        this.changecolor = newColor.toString();
        const self = this;
        this.tick_then(() => {
            self.ntcolorChange.emit(new NtColor(newColor));
        });
    }

    colorMove(newColor: any) {
        const newVal = newColor.toString();
        if(newVal === this.btnDescr.color) {
            return;
        }
        const self = this;
        this.tick_then(() => {
            self.btnDescr.color = newColor.toString();
            self.ntcolorMove.emit(new NtColor(newColor));
        });
    }

    popupExternalClick(_e) {
        const newColor = $(this.input.nativeElement).spectrum('get');
        this.colorChange(newColor);
    }

}
