
export enum NtDndRemovalStrategy {

    // The draggable is kept regardless of keepAfterDnDUnder() custom implementation (no call to draggable.keepAfterDnDUnder() will be emitted)
    // => as a result no removal bubbling will occur across ancestor handlers (shouldDismissIfEmptyAfterDnD() will not be triggered on any ancestor)
    KEEP,

    // The draggable will be removed only if keepAfterDnDUnder() custom implementation returns false
    // no ancestor removal bubbling will occur across ancestor handlers (shouldDismissIfEmptyAfterDnD() will not be triggered on any ancestor)
    KEEP_CUSTOM_NO_BUBBLE_REMOVAL,

    // The draggable will be removed only if keepAfterDnDUnder() custom implementation returns false, if removed, a removal request will be
    // bubbled to 'emptied' ancestors (ancestor.draggableList().length == 0) through shouldDismissIfEmptyAfterDnD() calls
    // => bubble requests are submitted per ancestor from bottom (draggableHandler) to top (rootHandler)
    // => user may approve or deny a removal request on emptied handlers through shouldDismissIfEmptyAfterDnD() custom implementation per ancestor handler
    // => the first non empty ancestor (carries more items than what was removed) stops the bubble (no further ancestor.shouldDismissIfEmptyAfterDnD() calls)
    // => the first emptied ancestor whose shouldDismissIfEmptyAfterDnD() custom implementation denies removal blocks the bubble from going
    // further (no further ancestor.shouldDismissIfEmptyAfterDnD() calls)
    // => if keepAfterDnDUnder() returns true in the first place, no ancestor removal bubbling will occur (shouldDismissIfEmptyAfterDnD() will not be triggered on any ancestor)
    KEEP_CUSTOM_AND_BUBBLE_REMOVAL_CUSTOM,

    // The draggable will be removed only if keepAfterDnDUnder() custom implementation returns false, if removed the removal will be bubbled
    // to all ancestors whose depth >= removal_bubble_preset_depth (user specified depth value)
    // => no calls to shouldDismissIfEmptyAfterDnD() will be triggered to any ancestor
    // => removal bubbling will apply even to non 'emptied' ancestors as long as their depth >= removal_bubble_preset_depth
    // => if keepAfterDnDUnder() returns true in the first place, no ancestor removal bubbling will occur
    KEEP_CUSTOM_AND_BUBBLE_REMOVAL_TILL_PRESET_DEPTH,


    // The draggable is removed regardless of keepAfterDnDUnder() custom implementation (no call to draggable.keepAfterDnDUnder() will be emitted)
    // => no removal bubbling will occur across ancestor handlers (shouldDismissIfEmptyAfterDnD() will not be triggered on any ancestor)
    REMOVE_AND_NO_BUBBLE_REMOVAL, // shouldDismissIfEmptyAfterDnD() will not be invoked on any ancestor even it gets emptied


    // The draggable will be removed regardless of keepAfterDnDUnder() custom implementation (no call to draggable.keepAfterDnDUnder() will be emitted)
    // => subsequently a removal request will be bubbled to 'emptied' ancestors (ancestor.draggableList().length == 0) through shouldDismissIfEmptyAfterDnD() calls
    // => bubble requests are submitted per ancestor from bottom (draggableHandler) to top (rootHandler)
    // => user may approve or deny a removal request on emptied handlers through shouldDismissIfEmptyAfterDnD() custom implementation per ancestor handler
    // => the first non empty ancestor (carries more items than what was removed) stops the bubble (no further ancestor.shouldDismissIfEmptyAfterDnD() calls)
    // => the first emptied ancestor whose shouldDismissIfEmptyAfterDnD() custom implementation denies removal blocks the bubble from going
    // further (no further ancestor.shouldDismissIfEmptyAfterDnD() calls)
    REMOVE_AND_BUBBLE_REMOVAL_CUSTOM, // shouldDismissIfEmptyAfterDnD() will not be invoked on any ancestor even it gets emptied


    // The draggable will be removed regardless of keepAfterDnDUnder() custom implementation (no call to draggable.keepAfterDnDUnder() will be emitted)
    // => subsequently the removal will be bubbled to all ancestors whose depth >= removal_bubble_preset_depth (user specified depth value)
    // => no calls to shouldDismissIfEmptyAfterDnD() will be triggered to any ancestor
    // => removal bubbling will apply even to non 'emptied' ancestors as long as their depth >= removal_bubble_preset_depth
    REMOVE_AND_BUBBLE_REMOVAL_TILL_PRESET_DEPTH // Customizable per ancestor through shouldDismissIfEmptyAfterDnD() user implementation
}
