<div class="tabs-header-rt">
    <div *ngIf="showLeftArrow"
         (click)="leftArrowClicked($event)"
         class="lft-arr-dv arr-dv" >
        <svg viewBox="0 0 24 24" width="24" height="24">
            <path d="M15.004 19V5l-8 7 8 7z"></path>
        </svg>
    </div>
    <div class="tabs-header-ctn"
         (resize)="updateHeaderResponsiveScrollArrows($event)"
         (scroll)="updateHeaderResponsiveScrollArrows($event)">
        <div class="tabs-header">
            <div *ngFor="let tab of tabs"
                 class="tab-header"
                 [ngClass]="{'tab-slct': tab.isSelected(), 'tab-nn-slct': !tab.isSelected()}"
                 (click)="selectTab(tab)">
                <span>{{ tab.title }}</span>
            </div>

            <div class="tab-slct-ln" [style]="{
                width: selectedTabLineWidth,
                height: selectedTabLineHeight,
                left: selectedTabLineLeft}"
                 [@tab-chg]="{value: tabState, params: {dx: lineTransitionDx, scaleX: lineTransitionScaleX}}">
                <span>&nbsp;</span>
            </div>
        </div>
    </div>
    <div *ngIf="showRightArrow"
         (click)="rightArrowClicked($event)"
         class="rgt-arr-dv arr-dv">
        <svg viewBox="0 0 24 24" width="24" height="24">
            <path d="M9.004 19l8-7-8-7v14z"></path>
        </svg>
    </div>
</div>

<ng-content></ng-content>
