import {NtDraggableComponent} from '../nt-draggable/nt-draggable.component';
import {NtDndRemovalContext} from './nt-dnd-removal-context';
import {NtDndActionType} from './nt-dnd-action-type';
import {NtDndRelayoutContext} from './nt-dnd-relayout-context';
import {NtDndRemovalStrategy} from './nt-dnd-removal-strategy';

export class NtDndContext {
    private static auto_id = 1;

    // to use in DnD callbacks if needed
    public id: number;

    // not meant for external use => => not to be relied upon by DnD custom end implementations
    public dndRemovalCtxs = new Array<NtDndRemovalContext>();

    // not meant for external use => => not to be relied upon by DnD custom end implementations
    // @ts-ignore
    public dndRelayoutCtxs = new Array<NtDndRelayoutContext>();

    // @ts-ignore
    constructor(public draggable: NtDraggableComponent,

                // @ts-ignore
                public droppedEl: NtDraggableComponent,

                // dragIndex under dragHandler
                public dragIndex: number,

                // dropIndex under dropHandler
                public dropIndex: number,

                // whether the dragged element is a direct child of dropHandler (in contrast with dragHandler)
                public isDraggableDirectChild: boolean,

                // see NtDndTriggerTag docs for details
                public dndTriggerTag: any,

                // if needed, dndType is only relevant externally (to custom DnD implementation) when dndTriggerTag == NtDndTriggerTag.MOUSE_USER_INTERACTION
                // => if a dnd event is function triggered, dndTriggerTag should be used for any needed context externally
                // => always necessary for internal handling, but should not be relied upon externally when dndTriggerTag != NtDnDTriggerTag.MOUSE_USER_INTERACTION (function triggered)
                public dndType: NtDndActionType,

                // null if the dnd event is code triggered (as opposed to mouse interaction triggered)
                public e: MouseEvent,

                // used to provide context on how removalBubbleEndDepth was calculated
                // => see NtDndRemovalStrategy docs for details
                public draggableRemovalStrategy: NtDndRemovalStrategy,

                // relevant only when draggableRemovalStrategy is != KEEP (see NtDndRemovalStrategy docs)
                // => remains relevant even when draggableRemovalStrategy blocks the bubbling of removal to ancestors
                // as long as the triggering draggable itself is removed => in which case removalBubbleEndDepth = draggable.draggableDepth
                // => a negative value indicates that no draggable removal has occurred (thus no bubbling neither)
                public removalBubbleEndDepth = -1) {
        this.id = NtDndContext.auto_id ++;
    }

    public resetDndAnimations() {
        if(this.draggable) { // draggable can be null in case of DnD reversal (see NtDragDropHandler.reverseDnDMoveAcrossHandlers()) where the original droppedEl, which becomes draggable
            // in the reversal process, was null (meaning original dropHandler uses custom drop handling)
            this.draggable.dndEndAnimPositionDelta = 0;
        }
        if(this.droppedEl) {
            this.droppedEl.activateDropPopInAnim = false;
        }
        for(let relayoutCtx of this.dndRelayoutCtxs) {
            relayoutCtx.resetDndAnimations();
        }
    }
}
