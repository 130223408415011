<ng-template #btnPopupContent><ng-content></ng-content></ng-template>

<nt-popup-wrapper class="pop-wrp-top"
                  [btnDescr]="btnDescr"
                  *ngIf="btnDescr.showTopPopup()"
                  (ntpopinend)="popupAninationEnd()"
                  (ntpopoutend)="popupAninationEnd()"
                  [beforePopoutSubject]="beforePopoutSubject">
    <ng-container *ngTemplateOutlet="btnPopupContent"></ng-container>
</nt-popup-wrapper>

<nt-popup-wrapper class="pop-wrp-left"
                  [triggerHeight]="currentHeight"
                  [btnDescr]="btnDescr"
                  *ngIf="btnDescr.showLeftPopup()"
                  (ntpopinend)="popupAninationEnd()"
                  (ntpopoutend)="popupAninationEnd()"
                  [beforePopoutSubject]="beforePopoutSubject">
    <ng-container *ngTemplateOutlet="btnPopupContent"></ng-container>
</nt-popup-wrapper>


<span class="btn-wrp"
      *ngIf="btnDescr.isToggle"
      [@tbr-tgl]="btnDescr.animationState()"
      (mouseenter)="btnDescr.hovered = true"
      (mouseleave)="btnDescr.hovered = false"
      title="{{btnDescr.tooltip}}"
      [ngClass]="[btnDescr.paddingCls, btnDescr.cssClass()]"
      (click)="onBtnClick()">
    <div class="icn-wrp">
        <div *ngIf="btnDescr.color !== null"
             [style.backgroundColor]="btnDescr.color"
             class="clr-bkg-btn"></div>
        <div *ngIf="btnDescr.color == null" class="icn sprite {{btnDescr.imgBgCls}}"></div>
    </div>
</span>

<span class="btn-wrp"
      *ngIf="!btnDescr.isToggle"
      [@tbr-tgl]="btnDescr.animationState()"
      [ngClass]="[btnDescr.paddingCls, btnDescr.cssClass()]"
      (mouseenter)="btnDescr.hovered = true"
      (mouseleave)="btnDescr.hovered = false"
      title="{{btnDescr.tooltip}}"
      (mousedown)="btnDescr.pressed = true; onBtnClick()"
      (mouseup)="btnDescr.pressed = false"
      (dragend)="btnDescr.pressed = false">
    <div class="icn-wrp">
        <div class="icn sprite {{btnDescr.imgBgCls}}"></div>
    </div>
</span>

<nt-popup-wrapper class="pop-wrp-btm"
                  [btnDescr]="btnDescr"
                  *ngIf="btnDescr.showBottomPopup()"
                  (ntpopinend)="popupAninationEnd()"
                  (ntpopoutend)="popupAninationEnd()"
                  [beforePopoutSubject]="beforePopoutSubject">
    <ng-container *ngTemplateOutlet="btnPopupContent"></ng-container>
</nt-popup-wrapper>


<nt-popup-wrapper class="pop-wrp-rgt"
                  [triggerHeight]="currentHeight"
                  [btnDescr]="btnDescr"
                  *ngIf="btnDescr.showRightPopup()"
                  (ntpopinend)="popupAninationEnd()"
                  (ntpopoutend)="popupAninationEnd()"
                  [beforePopoutSubject]="beforePopoutSubject">
    <ng-container *ngTemplateOutlet="btnPopupContent"></ng-container>
</nt-popup-wrapper>

